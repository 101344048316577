<template>
  <PopWidget :isShow="isShowPopWidget" :isFull="1" style="z-index:4;">
    <template v-slot:title>
      <img class="l-t-title"
        src="../../assets/yuebao/pwd-setting-title.png" />
    </template>
    <template v-slot:content>
      <form class="yeb-io-wrap" autocomplete="off">
        <div class="flex">
          <div class="label-title">请输交易密码：</div>
          <input type="password" placeholder="由6数字组成" 
            autocomplete="new-password" v-model="password" />
        </div>
        <div class="flex">
          <div class="label-title">确认交易密码：</div>
          <input type="password" placeholder="请再输入一遍" 
            autocomplete="new-password" v-model="confirmPwd" />
        </div>
        <img class="confirm-btn ease-btn-effe" 
          src="../../assets/confirm-btn.png" @click="confirmClick" />
      </form>
    </template>
  </PopWidget>
</template>

<script>
import { mapMutations } from 'vuex';
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'

export default{
  props:{
    show:Number,
    isOut:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      password:'',
      confirmPwd:'',
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    confirmClick(){
      if(!this.password || !this.password.trim()){
        this.popMsg(`请输入交易密码`);return;
      }
      if(!this.confirmPwd || !this.confirmPwd.trim()){
        this.popMsg(`请在输入一遍交易密码`);return;
      }
      if(this.confirmPwd != this.confirmPwd){
        this.popMsg(`两次密码输入不一致`);return;
      }
      const pa = {
        coinPassword:this.password,
        confirmcoinPassword:this.confirmPwd
      }
      Request.post('/user/save_coinpassword',pa).then(res => {
        if(res.data.code == 0){
          this.popMsg('交易密码设置成功')
          this.isShowPopWidget = 0;
          this.password = '';
          this.confirmPwd = '';
          this.$emit('setted')
        }
      })
      
    },
    forgotClick(){
      this.$emit('forgotClick')
      this.isShowPopWidget = 0;
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget,
  }
}
</script>

<style scoped lang="stylus">
.yeb-io-wrap
  width 100%
  height 2.08rem
  padding-top .2rem
  .flex
    margin .20rem 0
    justify-content center
  input[type="password"]
    width 2.38rem
    height .32rem
    padding-left .1rem
    background #B39269
    border-radius .08rem
  .label-title
    width 1.2rem
    font-weight bold
    text-align right
    line-height .35rem
    color #AD773B

  .confirm-btn
    width 1.2rem
</style>