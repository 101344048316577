<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/geren/binding-nickname.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <input placeholder="请输入昵称" maxlength="16"
          v-model="editNickName" />
        <img class="edit-nick-clear" @click="editNickName = ''"
          v-if="editNickName"
          src="../../assets/geren/edit-nick-clear.png" />
      </div>
      <img @click="confirmClick()"
        class="l-confirm-btn" src="../../assets/login/confirm-btn.png" />
    </template>
  </PopWidget>
</template>
<script>
import { mapMutations } from 'vuex'
import Tool from '../../common/Tool.js'
import Request from '../../common/Request.js'
import PopWidget from '../common/PopWidget.vue'

export default {
  props:{
    isShow:Number,
    nickname:String,
  },
  data(){
    return {
      isShowPopWidget:0,
      editNickName:'',
    }
  },
  methods:{
    ...mapMutations(['popMsg','showLoading',
      'closeLoading']),
    confirmClick(){
      let userInfo = Tool.getLocalUserinfo();
      userInfo.nickname = this.editNickName;
      userInfo.device_id = Tool.getDeviceId();
      this.showLoading();
      Request.post('/user/info',userInfo).then(res => {
        if(res.data.code == 0){
          this.popMsg(res.data.msg);
          this.isShowPopWidget = 0;
          this.$emit('nicknameEdited')
        }else{
          this.closeLoading();
        }
      }).catch(() => this.closeLoading())
    },
  },
  watch:{
    isShow(n,o){
      this.isShowPopWidget = n;
      this.editNickName = this.nickname;
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style scoped lang="stylus">
.l-t-title
  height .3rem
.l-confirm-btn
  width .94rem
  margin-top .16rem
  transition transform .3s
  &:active
    transform scale(.8)
.l-c-w-i
  display flex
  justify-content center
  width 100%
  border-top 1px solid transparent
  padding .38rem .2rem .1rem .2rem
  flex-wrap wrap
  align-items center
  input
    width 2.94rem
    height .4rem
    text-align center
    color #cccac5
    background-image url(../../assets/geren/editor-nick-bg.png)
    background-color transparent
    background-size 100% 100%
    padding-left .05rem
  .edit-nick-clear
    width .3rem
    height .3rem
    margin-left -.37rem
</style>