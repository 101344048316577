<template>
  <div>
    <img class="decorate" src="../../assets/geren/ctitle-zjin.png" />
    <div class="tool flex">
      <div class="selector-wrap flex">
        <div class="selector-label">
          时间：
        </div>
        <Selector defaultTxt="选择时间" style="width:.88rem"
          :dataList="dateList" @change="dateSelecChange"
          :defaultIndex="0" menuHeight=".77rem" />
        <div class="selector-label">
          类型：
        </div>
        <Selector defaultTxt="请选择类型" :defaultIndex="0"
          :dataList="typeList" @change="typeSelectChange"
          menuHeight="1.9rem" />
      </div>
      <img @click="queryClick" class="query-btn ease-btn-effe" 
        src="../../assets/geren/query-btn.png" />
      
    </div>
    <div class="table-zjmx">
      <div class="t-row h flex">
        <div class="t-ceil">时间</div>
        <div class="t-ceil">流水金额</div>
        <div class="t-ceil">之后金额</div>
        <div class="t-ceil">收支类型</div>
        <div class="t-ceil">资金类型</div>
        <div class="t-ceil">备注</div>
      </div>
      <div class="data-t-scroller" :style="{'height':`${tableDivHeight}px`}">
        <div class="t-row b flex" v-for="item of dataList">
          <div class="t-ceil">
            {{ item.created_at }}
          </div>
          <div class="t-ceil">
            {{ item.amount }}
          </div>
          <div class="t-ceil">
            {{ item.t_balance }}
          </div>
          <div class="t-ceil">
            {{item.way_name}}
          </div>
          <div class="t-ceil">
            {{ item.type_name }}
          </div>
          <div class="t-ceil">
            {{ item.remark }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Selector from '../common/Selector.vue'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import { mapMutations } from 'vuex'

export default {
  data(){
    return {
      tableDivHeight:0,
      dateList:[
        {id:'today',name:'今天'},
        {id:'yesterday',name:'昨天'},
      ],
      condition:{
        dateid:'',
        typeid:'',
      },
      typeList:[],
      dataList:[],
      //数据结构 current_page per_page total
      dataListPager:{},
      currentPage:1,
    }
  },
  mounted(){
    this.getData();
    this.getTableHeight();
    window.addEventListener('resize',() => {
      this.getTableHeight();
    })
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading','popMsg']),
    getTableHeight(){
      let m = innerHeight > innerWidth ? innerWidth : innerHeight
      let topPx = Tool.remToPx(1.55)
      this.tableDivHeight = m - topPx;
    },
    queryClick(){
      this.getData();
    },
    getData(){
      const _this = this;
      let pList = [
        `date=${this.condition.dateid ? this.condition.dateid:'today'}`,
        `type=${this.condition.typeid ? this.condition.typeid:''}`,
        `page=${_this.currentPage}`
      ];

      let p = pList.join('&');
      this.makeZijinType(Tool.getZijinTypes())
      _this.dataListPager = [];
      _this.dataList = [];
      _this.showLoading();
      Request.get(`/user/money_record?${p}`).then(res => {
        _this.closeLoading();
        _this.dataListPager = res.data.data.data
        _this.dataList = _this.dataListPager.data;

        //假数据
        (() => {
          let single = {
            created_at:'2023-05-12 11:55:21',
            amount:23.12,
            t_balance:32.232,
            way_name:'疯狂利润',
            type_name:'的时间里副科级',
            remark:'圣诞快乐福建省考虑到'
          }
          for(let i = 0; i < 30; i++){
            let f  = JSON.parse(JSON.stringify(single));
            _this.dataList.push(f)
          }
        })

        Tool.setZijinTypes(res.data.data.types)
        _this.makeZijinType(res.data.data.types)
      }).catch(error =>  {
        _this.closeLoading();
        if(error.response.data.code == 1000006){
          Request.userOutted(_this)
        }
      })
    },
    makeZijinType(data){
      this.typeList = [
        {id:'',name:'全部类型'}
      ]
      if(!data) return;
      data.forEach(typeItem => {
        this.typeList.push({
          id:typeItem[0],
          name:typeItem[1],
        })
      })
    },
    dateSelecChange(e){
      this.condition.dateid = this.dateList[e].id
    },
    typeSelectChange(e){
      this.condition.typeid = this.typeList[e].id
    }
  },
  components:{
    Selector
  }
}
</script>

<style scoped lang="stylus">
.decorate
  width 70%
  margin-left .5rem
.tool
  height .4rem
  justify-content space-between
  align-items center
  .selector-wrap
    align-items center
  .selector-label
    margin-left .4rem
  .query-btn
    margin-right .2rem
    width .75rem
.table-zjmx
  width 95%
  overflow hidden
  border .01rem solid #483E3F
  border-radius .05rem
  margin 0 auto
  position relative
  .data-t-scroller
    width 100%
    overflow auto
  .t-row
    width 100%
    font-size .12rem
    &.h
      font-size .12rem
      height .2rem
      background-size 100% 100%
      background-image url(../../assets/geren/zj-detail-t-bg.png)
    &.b
      &:nth-child(even)
        background-color #232323
      .t-ceil
        &:nth-child(6)
          padding-left .04rem
    .t-ceil
      width .92rem
      height .2rem
      text-align center
      display flex
      justify-content center
      align-items center
      overflow hidden
      border-right .01rem solid #483E3F
      &:nth-child(1)
        width 1.3rem
      &:nth-child(2)
        width .65rem
      &:nth-child(3)
        width .65rem
      &:nth-child(6)
        width 1.34rem
        border none
        text-overflow ellipsis
        white-space nowrap
        display block
</style>
