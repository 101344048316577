<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <!-- <img class="l-t-title" src="../assets/login/line-choice.png" /> -->
      <div class="l-t-title">线路选择</div>
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <div class="line-btn" v-for="(line,i) of lines"
          :class="{selected:i == select_i}" @click="lineChoice(i)">
          {{line.name}}
        </div>
      </div>
      <img @click="selectedLine()"
        class="l-confirm-btn" src="../assets/login/confirm-btn.png" />
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from './common/PopWidget.vue'
import Request from '../common/Request.js';

export default {
  props:{
    isShow:Number
  },
  data(){
    return{
      isShowPopWidget:0,
      select_i:0,
      lines:[]
    }
  },
  mounted(){
    this.selectLineByLocal();
  },
  methods:{
    getLines(){
      Request.getLinesLocal().then(list => {
        if(list && list.length){
          this.lines = list;
          this.selectLineByLocal();
        }
      })
    },
    selectLineByLocal(){
      let apiHost = Request.getLocalApiHost();
      let select_i = 0;
      this.lines.forEach((l,i) => {
        if(apiHost == l.host){
          select_i = i;
        }
      });
      this.select_i = select_i;
    },
    lineChoice(i){
      this.select_i = i;
    },
    selectedLine(){
      Request.setApiHost(this.lines[this.select_i].host)
      this.isShowPopWidget = 0
    }
  },
  watch:{
    isShow(n,o){
      if(n){
        this.getLines();
      }
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style scoped lang="stylus">
.l-t-title
  margin auto
  line-height .27rem
  height .27rem
  width .65rem
.l-confirm-btn
  width .94rem
  margin-top .22rem
  transition transform .3s
  &:active
    transform scale(.8)
.l-c-w-i
  display flex
  flex-wrap wrap
  justify-content flex-start
  align-content center
  width 100%
  border-top 1px solid transparent
  padding .2rem .2rem 0 .2rem
  .line-btn
    width .86rem
    height .27rem
    margin-right .2rem
    margin-bottom .1rem
    border-radius .12rem
    display flex
    justify-content center
    align-items center
    background-color #BEA37C
    color #fff
    &.selected
      background #EFB56C
      color #000
</style>