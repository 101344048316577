<template>
  <Confirm :show="show" style="z-index:4;" width='3.5rem' @confirm="confirm">
    <template v-slot:confirm-content>
      <div class="confirm-wrapper">
        <div class="confirm-msg flex">
          {{msg}}
        </div>
      </div>
    </template>
  </Confirm>
</template>
<script>
import { mapMutations } from 'vuex'
import Confirm from '../common/Confirm.vue'
export default {
  props:{
    show:Number,
    msg:String,
  },
  data(){
    return{}
  },
  methods:{
    ...mapMutations(['popMsg']),
    confirm(){
      this.$emit('e_confirm')
    },
  },
  watch:{
    show(n){
      if(n){
      }
    },
  },
  components:{
    Confirm
  }
}
</script>
<style lang="stylus" scoped>
.confirm-wrapper
  height .6rem
  .confirm-msg
    width 100%
    height .5rem
    font-size .14rem
    justify-content center
    align-items center
</style>