<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" src="../assets/login/login-tool-title.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <div class="l-c-w-ir">
          <div class="l-label">手机号码：</div>
          <input v-model="mobile" type="text" placeholder="请输入手机号" />
        </div>
        <div class="l-c-w-ir">
          <div class="l-label">账号密码：</div>
          <input v-model="password" type="password" placeholder="请输入密码" />
        </div>
        <div class="l-c-w-ir">
          <img @click="loginClick"
            class="confirm-btn" src="../assets/login/confirm-btn.png" />
        </div>
        <div class="l-c-w-ir reg">
          <div @click="goSignUp">注册</div>
          <div @click="forgetPwd" class="forget-link">忘记密码?</div>
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import Tool from '../common/Tool.js'
import Request from '../common/Request.js'
import PopWidget from './common/PopWidget.vue'
export default {
  props:{
    isShow:Number,
    isAnonymousLogin:Number,
  },
  data(){
    return{
      isShowPopWidget:0,
      mobile:'',
      password:'',
      // 是否为其他组件内登录
      isInnerLogin:0,
    }
  },
  mounted(){
    let lUsername = Tool.getLoginUserName();
    if(lUsername && lUsername.trim()){
      this.mobile = lUsername;
    }
  },
  methods:{
    ...mapMutations([
      'popMsg',
      'innerAnnoLogined',
      'showLoading',
      'closeLoading',
      'userLoginSuccess',
    ]),
    //游客登录
    anonymousLogin(){
      let params = {
        device_id:Tool.getDeviceId(),
        username:''
      }
      let agentCode = localStorage.getItem('agent_code')
      if(agentCode){
        params.agent_code = agentCode;
      }
      this.loginSubmit(params);
    },
    loginClick(){
      if(!this.mobile || !this.mobile.trim()){
        this.popMsg('请输入手机号');
        return;
      }
      if(!this.password || !this.password.trim()){
        this.popMsg('请输入密码')
        return;
      }
      let params = {
        username:this.mobile,
        password:this.password,
      }
      let agentCode = localStorage.getItem('agent_code')
      if(agentCode){
        params.agent_code = agentCode
      }
      this.loginSubmit(params);
    },
    loginSubmit(params){
      const _this = this;
      _this.showLoading()
      Request.post(`/pub/login`,params).then(res => {
        _this.closeLoading()
        if(res.data.code == 0){
          Tool.setLoginUserName(params.username);
          Tool.setApiToken(res.data.data);
          if(_this.isInnerLogin){
            //通知内部匿名登录成功
            _this.innerAnnoLogined();
          }else{
            _this.popMsg('登录成功 !');
            Tool.removeLocalUserInfo();
            _this.loginSuccess()
          }
        }else{
          if(res.data.msg){
            _this.popMsg(res.data.msg);
          }
        }
      })
      .catch(error => {
        _this.closeLoading()
        if(error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg);
        }else{
          this.popMsg('登录失败,请联系管理员!');
        }
      });
    },
    getDeviceId(){
      Tool.genDeviceId()
    },
    goSignUp(){
      this.isShowPopWidget = 0
      this.$emit('goSignUp')
    },
    forgetPwd(){
      this.isShowPopWidget = 0
      this.$emit('forgetPwd')
    },
    loginSuccess(){
      this.showLoading();
      Request.get(`/user/info`).then(res => {
        this.closeLoading();
        this.userLoginSuccess()
        if(res.data && res.data.data){
          setTimeout(() => {
            this.$router.push({name:"main"})
          },200)
          Tool.setLocalUserinfo(res.data.data);
        }
      }).catch(() => {
        this.closeLoading();
      });
      this.getActivityStatus();
    },
    getActivityStatus(){
      Request.post('/user/apply_activity').then(res => {
        if(res.data.code == 0){
          Tool.setActivityApply(res.data.data.apply_activity)
        }
      })
    }
  },
  watch:{
    isShow(n,o){
      this.isShowPopWidget = n;
    },
    isAnonymousLogin(n,o){
      if(n){
        this.anonymousLogin();
      }
    },
    //其他组件请求游客登录
    ccAnonymousLogin(n,o){
      if(n){
        this.isInnerLogin = 1;
        this.anonymousLogin();
      }
    }
  },
  computed:{
    ...mapGetters({
      ccAnonymousLogin:'getAnonymousLogin'
    })
  },
  components:{
    PopWidget
  }
}
</script>
<style scoped lang="stylus">
.login-c-w
  width 4.84rem
  margin-top .01rem
  position relative
  .l-c-bg1
    width 100%
    height 2.14rem
  .l-c-bg
    width 4.66rem
    height 1.94rem
    position absolute
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
  .l-c-w-i
    width 100%
    border-top 1px solid transparent
    .l-c-w-ir
      width 100%
      display flex
      margin-top .28rem
      justify-content center
      align-items center
      color #DDDCBD
      &.reg
        font-size .12rem
        margin-top .1rem
        justify-content flex-end
        & > div
          margin-right .18rem
          &.forget-link
            margin-right .4rem
      .confirm-btn
        width 1.12rem
        transition transform .3s
        &:active
          transform scale(.9)
      input
        background-color transparent
        background-image url(../assets/login/input-bg.png)
        background-size 100% 100%
        border none
        border-radius .12rem
        width 2rem
        height .3rem
        color #dddcbd
        padding-left .05rem
</style>