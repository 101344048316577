<template>
  <div class="home-footer" :class="{shuping:isShuping}">
    <img class="bg-img" src="../assets/home/footer/footer_bg.png" />
    <div class="f-menu-container" ref="menuContainer">
      <div class="activity-ico ease-btn-effe"
        @click="duihuanClick">
        <img src="../assets/home/footer/duihuan.png" />
      </div>
      <div class="activity-ico ease-btn-effe"
        @click="activityClick">
        <div class="pop-sign" v-show="localActivity"></div>
        <img src="../assets/home/footer/activity.png" />
      </div>
      <div class="activity-ico ease-btn-effe"
        @click="yuebaoClick">
        <img src="../assets/home/footer/yuebao.png" />
      </div>
      <div class="activity-ico ease-btn-effe"
        @click="ranListClick">
        <img src="../assets/home/footer/range.png" />
      </div>
      <div class="activity-ico ease-btn-effe"
        @click="emailClick">
        <div class="pop-sign" v-show="localNewMail"></div>
        <img src="../assets/home/footer/email.png" />
      </div>
      <div class="activity-ico ease-btn-effe" @click="customerClick">
        <img src="../assets/home/footer/kefu.png" />
      </div>
      <div class="cz-l-wrapper" ref="czLottieWrap"
        @click="rechargeClick"></div>
    </div>
  </div>
</template>

<script>
import Tool from '../common/Tool.js'
import LottieZipShow from '../common/LottieZipShow.js'
import UserInfo from '../common/UserInfo.js'
import lottie from 'lottie-web'
import { mapMutations } from 'vuex'

export default {
  props:{
    newMail:Number|String,
  },
  data(){
    return {
      localNewMail:0,
      localActivity:0,
      isShuping:false,
    }
  },
  mounted(){
    this.getActivityTips();
    this.checkEmailTips();

    this.$refs.menuContainer.addEventListener('click',(e) => {
      UserInfo.getUserInfoData(data => {
        if(data.is_tourist){
          e.stopPropagation();
          e.preventDefault();
          this.$emit('touristShow')
          return;
        }
      })
    },true);
    
    const czAniEle = this.$refs.czLottieWrap
    let iconData = '/lottie-web/cz-caishen.zip';
    LottieZipShow.showIco(
      iconData,
      'localChongzhi.zip',
      czAniEle);
    
    this.checkPingmuType();
  },
  methods:{
    ...mapMutations(['popRecharge']),
    checkPingmuType(){
      let check = () => {
        this.isShuping = innerHeight > innerWidth;
        // let res = `h:${innerHeight},w:${innerWidth},${_this.isShuping}`;
        // console.log(res);
      }
      window.addEventListener('orientationchange',() => {
        check();
      });
      window.addEventListener('resize',() => {
        check();
      });
      check();
    },
    checkEmailTips(){
      const userInfo = Tool.getLocalUserinfo();
      if(userInfo && userInfo.hashid && this.$socket && this.$socket.private){
        this.$socket.private('user.'+userInfo.hashid).listen('.mail', (e)=>{
          this.localNewMail = 1;
        })
      }
    },
    getActivityTips(){
      let actAppCount = Tool.getActivityApply();
      this.localActivity = actAppCount ? 1 * actAppCount:0;
    },
    ranListClick(){
      this.$emit('ranListClick')
    },
    yuebaoClick(){
      this.$emit('yuebaoClick');
    },
    activityClick(){
      this.localActivity = 0;
      Tool.setActivityApply(0);
      this.$emit('activityClick');
    },
    duihuanClick(){
      this.$emit('duihuanClick')
    },
    tuiguangClick(){
      this.$emit('tuiguangClick')
    },
    rechargeClick(){
      this.popRecharge();
    },
    customerClick(){
      this.$emit('customer')
    },
    emailClick(){
      this.localNewMail = 0;
      this.$emit('emailClick')
    }
  },
  watch:{
    newMail(n){
      this.localNewMail = n;
    },
  },
}

</script>
<style scoped lang="stylus">
@supports(bottom:env(safe-area-inset-bottom)){
  .home-footer{
    padding-bottom:env(safe-area-inset-bottom);
    padding-bottom:constant(safe-area-inset-bottom);
  }
}
.home-footer
  width 100%
  height .45rem
  user-select none
  position fixed
  bottom 0
  left 0
  z-index 1
  // &.shuping
  //   bottom .24rem
  .f-menu-container
    width 96%
    height 100%
    padding-left .84rem
    position absolute
    z-index 2
    left 0
    display flex
    justify-content space-between
    align-items flex-end
    .tg-l-wrapper
      width .82rem
      height .6rem
      margin-bottom .05rem
    .cz-l-wrapper
      width 1.38rem
      height .68rem
    .activity-ico
      width .35rem
      height .45rem
      position relative
      display flex
      align-items center
      img
        width 100%
      .pop-sign
        width .12rem
        height .12rem
        border-radius 50%
        background-image url(../assets/activity/active-tip-poi.png)
        background-size 100% 100%
        position absolute
        top .01rem
        right 0
        border 1px solid #fff
  .bg-img
    width 100%
    position absolute
    bottom 0
    left 50%
    transform translateX(-50%)
</style>