<template>
  <div class="qiandao-wrapper" @touchmove.stop=""
    :class="{show:localShow}">
    <div class="qd-container" :class="{show:containerShow}">
      <img class="qd-background" src="../../assets/qiandao/qiandao-bg.png" />
      <img class="qiandao-close ease-btn-effe" @click="closeClick"
        src="../../assets/qiandao/qiandao-close.png" />
      <div class="qd-content-wrap">
        <div class="qd-item-wrap p-relative">
          <div class="qd-item-c flex">
            <div class="qd-item-6" v-for="(item,i) of dayList"
              :data-at="item.isAfterToday"
              :style="{'background-image':`url(${getWeekItemBg(i,!item.isAfterToday && !item.is_today)})`}"
              :class="{
                after4:i > 2,
                status0:!item.isAfterToday,
                status1:item.is_today == 1 && item.status!=1,
                status2:item.isAfterToday}">
              <div class="sign-status" 
                v-show="item.status != 1 && item.is_today != 1 && !item.isAfterToday">
                <img class="status-w-img"
                  src="../../assets/qiandao/weiqiandao-t.png" />
              </div>
              <div class="sign-status" 
                v-show="item.status == 1">
                <img class="status-w-img" style="width:100%;height:100%;"
                  src="../../assets/qiandao/yiqiandao-t.png" />
              </div>
              <div class="sign-status" 
                v-show="item.status != 1 && item.is_today == 1">
                <img class="status-w-img" 
                  src="../../assets/qiandao/daiqiandao-t.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="des p-relative">
          <MarqueeList styleW="2.35rem" styleH=".48rem"
            :startRun="marqueeStartRun">
            <template v-slot:itemWrapper>
              <div class="sign-data-item" v-for="item of signupDataList">
                恭喜【{{item.nickname}}】在连续签到中心开出{{item.money}}元大奖
              </div>
            </template>
          </MarqueeList>
          <div @click="actSignin" class="get-jiangli-btn" 
            :class="{'today-signed':todayIsSign,'ease-btn-effe':!todayIsSign}">
          </div>
        </div>
      </div>
      <img class="des-img"
        v-if="signupDescriptImgUrl" :src="signupDescriptImgUrl" />
    </div>
  </div>
</template>
<script>
import Tool from '@/common/Tool'
import { mapMutations } from 'vuex';
import Request from '../../common/Request.js'
import MarqueeList from '../../components/common/MarqueeWrapper.vue'

export default {
  props:{
    show:Number,
  },
  data(){
    return {
      localShow:0,
      containerShow:0,
      dayList:[],
      signupDescriptImgUrl:'',
      signupDataList:[],
      marqueeStartRun:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    actSignin(){
      let today = this.dayList.filter(item => item.is_today == 1)[0];
      if(!today || today.status == 1){
        return;
      }
      let p = {
        id:today.Activity_id
      }
      Request.post('/activity/sign_in',p).then(res => {
        if(res.data.code != 0){
          this.popMsg(res.data.msg)
        }else{
          this.popMsg(res.data.msg)
          this.updateLocalStatus();
        }
      })
    },
    updateLocalStatus(){
      Request.post('/activity/get_status_sign').then(res => {
        Tool.setStatusSign(res.data.data);
        this.dayList = this.statusListHandle(res.data.data);
      })
    },
    getSignupList(callback){
      let cache = Tool.getSignupDataList();
      if(cache){
        this.signupDataList = cache;
        callback()
        return;
      }
      Request.get('/activity/activity_apply_sign').then(res => {
        if(res.data && res.data.data){
          this.signupDataList = res.data.data;
          if(this.signupDataList.length){
            Tool.setSignupDataList(this.signupDataList);
            callback()
          }
        }
      }).catch(error => {
        if(error.response && 
          error.response.data && 
          error.response.data.code == 1000006){
          Request.userOutted(this);
        }
      })
    },
    statusListHandle(list){
      let todayIndex = 0,todayData;
      list.forEach((item,i) => {
        if(item.is_today == 1){
          todayIndex = i;
          todayData = item;
        }
      })
      list.forEach((item,i) => {
        if(i > todayIndex){
          item.isAfterToday = 1;
        }
      })

      if(todayData){
        this.signupDescriptImgUrl = todayData.mb_icon;
      }
      
      return list;
    },
    getData(){
      let dataList = Tool.getStatusSign();
      if(dataList){
        this.dayList = this.statusListHandle(dataList);
        return;
      }
      Request.post('/activity/get_status_sign').then(res => {
        Tool.setStatusSign(res.data.data);
        this.dayList = this.statusListHandle(res.data.data);
      })
    },
    closeClick(){
      this.marqueeStartRun = 0;
      this.containerShow = 0;
      this.localShow = 0
    },
    getWeekItemBg(i,disable){
      let after = '';
      if(disable){
        after = '-f'
      }
      return require(`../../assets/qiandao/signup${i+1}${after}.png`)
    }
  },
  watch:{
    show(n){
      if(n){
        this.localShow = n;
        setTimeout(() => {
          this.containerShow = n;
        });
        this.getData();
        this.getSignupList(() => {
          setTimeout(() => {
            this.marqueeStartRun = Math.random()
          },200)
        });
      }
    }
  },
  computed:{
    todayIsSign(){
      let today = this.dayList.filter(item => item.is_today == 1)[0];
      if(today && today.status == 1){
        return 1
      }
      return 0
    }
  },
  components:{
    MarqueeList,
  }
}
</script>
<style lang="stylus" scoped>
.qiandao-wrapper
  width 100%
  height 100%
  font-size .1rem
  position absolute
  align-items center
  background-color rgba(0,0,0,.25)
  z-index 3
  top 0
  left 0
  display none
  &.show
    display flex
  .qd-container
    margin 0 auto
    position relative
    &.show
      transform scale(1)
    .des-img
      position absolute
      display block
      bottom .41rem
      left .16rem
      width 1.02rem
    .qd-background
      width 4.73rem
      display block
    .qiandao-close
      position absolute
      right -.09rem
      top .19rem
      width .27rem
    .qd-content-wrap
      width 3.2rem
      height auto
      position absolute
      padding-top .02rem
      top .65rem
      left .92rem
      .qd-item-wrap
        width 100%
        .sign-status
          width 100%
          height 100%
          top: -.01rem
          left -.03rem
          position absolute
          .status-w-img
            width .35rem
            position absolute
            top 0
            left 0
        .qd-item-c
          width 100%
          height 1.29rem
          flex-wrap wrap
          justify-content center
      .qd-item-6
        width .58rem
        height .51rem
        flex-shrink 0
        position relative
        margin-left .26rem
        background-size 100% 100%
        border-radius .04rem
        &.after4
          margin-left .14rem
      .des
        font-size .12rem
        width 100%
        text-align center
        .sign-data-item
          text-align left
          margin-left .35rem
          color #ffffff
          font-size .1rem
      .get-jiangli-btn
        top .12rem
        right -.13rem
        position absolute
        width .98rem
        height .32rem
        background-image url(../../assets/qiandao/liji-qiandao-btn.png)
        background-size 100% 100%
        &.today-signed
          background-image url(../../assets/qiandao/liji-qiandao-disable.png)
</style>