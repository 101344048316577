<template>
  <PopWidget :width="'4.55rem'" :isShow="isShowPopWidget" :isFull="1" style="z-index:4">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/yuebao/yuebao-detail-title.png" />
    </template>
    <template v-slot:content>
      <div class="main-content flex">
        <div class="menu-wrap">
          <div class="inner-back">
            <div class="menu-wrap-scroll">
              <div :key="menu.id" class="menu-btn" v-for="(menu,i) of menuItems"
                @click="menuClick(i)" :class="{select:menuSelectIndex==i}"
                 :data-id="menu.id">
                <div class="select-bg"></div>
                <div class="menu-text">
                  {{ menu.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-w-page" ref="scrollWrapPage" @scroll="pageScroll">
          <div class="record-item flex" v-for='item of recordList'>
            <div>
              <div class="flex sy-icon-wrap">
                <div class="shouyi-icon" :class="item.type">
                </div>
                <div>{{ item.type_name }}</div>
              </div>
              <div class="item-time-w">
                {{ item.created_at }}
              </div>
            </div>
            <div style="text-align:right;">
              <div class="amount-p" :class="{sub:!['interest_in','interest'].includes(item.type)}">
                {{getSignChar(item)}}{{item.amount}}
              </div>
              <div class="amount-v">
                {{ item.t_yeb }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PopWidget>
</template>

<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import { mapMutations } from 'vuex';

export default{
  props:{
    show:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      menuSelectIndex:0,
      menuItems:[
        {
          name:'全部',
          id:0,
          key:'',
        },
        {
          name:'收入',
          id:1,
          key:'interest',
        },
        {
          name:'转入',
          id:2,
          key:'interest_in',
        },
        {
          name:'转出',
          id:3,
          key:'interest_out',
        }
      ],
      currentPage:1,
      recordList:[],
      nextPageGetting:0,
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading']),
    pageScroll(e){
      let sTop = e.target.scrollTop;
      let scrollSpace = sTop + e.target.clientHeight
      let sub = e.target.scrollHeight - scrollSpace;
      if(sub < 5){
        this.getNextPageData();
      }
    },
    getNextPageData(){
      if(!this.recordPager)return;
      const {per_page,total} = this.recordPager;
      const totalPage = Math.ceil(total/per_page);
      if(this.currentPage >= totalPage) return;
      if(this.nextPageGetting == 0){
        this.nextPageGetting = 1;
        this.currentPage++;
        this.getRecordList(this.currentPage);
      }
    },
    getSignChar(item){
      if(['interest_in','interest'].includes(item.type)){
        return '+'
      }else{
        return '-'
      }
    },
    menuClick(i){
      this.menuSelectIndex = i;
      this.recordList = [];
      this.currentPage = 1;
      this.getRecordList();
    },
    getRecordList(page){
      /*type
      收入 interest
      转入 interest_in
      转出 interest_out
      */
      let cPage = page ? page : 1;
      let k = this.menuItems[this.menuSelectIndex].key;
      let query = `?page=${cPage}&type=${k}`;
      this.showLoading();
      Request.get('/yeb/record/'+query).then(res => {
        this.closeLoading();
        //假数据
        (() => {
          let single = {
            type_name:'',
            created_at:'2023-08-66 11:55:12',
            amount:22.88,
            t_yeb:22.55,
            type:''
          };
          for(let i = 0;i < 30; i++){
            let dataI = JSON.parse(JSON.stringify(single));
            dataI.type = i % 2 ? '':'interest_in';
            if(!(i % 3)){
              dataI.type = 'interest_out';
            }
            if(!(i % 5)){
              dataI.type = 'interest';
            }
            dataI.type_name = '收益';
            if(dataI.type == 'interest')dataI.type_name = '收入';
            if(dataI.type == 'interest_in')dataI.type_name = '转入';
            if(dataI.type == 'interest_out')dataI.type_name = '转出';
            this.recordList.push(dataI);
          }
        })

        if(res.data.data && res.data.data.data){
          this.recordPager = res.data.data;
          this.recordPager.data.forEach(item => {
            this.recordList.push(item);
          })
        }
        this.nextPageGetting = 0;
      }).catch(error => {
        if(error.response && 
          error.response.data && 
          error.response.data.code == 1000006){
          Request.userOutted(this);
        }
        this.closeLoading();
        this.nextPageGetting = 0;
      });
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      this.recordList = [];
      this.currentPage = 1;
      this.getRecordList();
    }
  },
  components:{
    PopWidget
  }
}
</script>

<style scoped lang="stylus">
.l-t-title
  display block
  margin auto
.main-content
  width 100%
  height auto
  background-image url(../../assets/yuebao/guize-bg.png)
  background-size 100% 100%
  .menu-wrap
    width .94rem
    height 2.28rem
    position relative
    z-index 1
    overflow auto
    &::-webkit-scrollbar
      width 0
    .inner-back
      width 100%
      min-height 100%
      height auto
      background-image url(../../assets/yuebao/yuebao-details-bg.png)
      background-size contain
      background-repeat no-repeat
      padding-bottom .3rem
    .menu-wrap-scroll
      width 100%
      height auto
      padding-top .12rem
      .menu-btn
        width .81rem
        height .38rem
        display block
        margin 0 0 0 -.04rem
        position relative
        overflow hidden
        &:active
          appearance none
          -moz-appearance none
          -webkit-appearance none
        .select-bg
          display none
        &.select
          .select-bg
            display block
          .menu-text
            color #842404
            &:after
              display none !important
        .select-bg
          width 100%
          height 141%
          background-image url(../../assets/yuebao/menu1.png)
          background-repeat no-repeat
          background-size 100% 100%
        .menu-text
          width 100%
          height 100%
          text-align left
          letter-spacing .07rem
          padding .09rem 0 0 .17rem
          font-weight bold
          font-size .14rem
          position absolute
          z-index 1
          top 0
          left 0
          &:after
            width .54rem
            height .02rem
            background-image url(../../assets/yuebao/menu-border.png)
            background-size 100% 100%
            content ' '
            display block
            background-size 100% 100%
            position absolute
            bottom 0
            left .1rem
  .m-w-page
    width 4.68rem      
    height 2.28rem
    padding-top .14rem
    padding-bottom .14rem
    margin-left -.05rem
    overflow auto
    .record-item
      width 92%
      height .6rem
      padding 0 .12rem
      font-size .12rem
      justify-content space-between
      align-items center
      margin .02rem auto .06rem auto
      border-radius .04rem
      border 1px solid #694B3E
      background-color #4C2E23
      .sy-icon-wrap
        margin-bottom .08rem
      .shouyi-icon
        width .24rem
        height .2rem
        margin-right .06rem
        background-image url(../../assets/yuebao/detail-shouyi-ico.png)
        background-size 100% 100%
        &.interest_in //转入
          width .2rem
          background-image url(../../assets/yuebao/i-in.png)
        &.interest_out //转出
          width .2rem
          background-image url(../../assets/yuebao/i-out.png)
      .item-time-w
        line-height 1
        background-color #392119
        color #927061
        border-radius .08rem
        padding .03rem .08rem
      .amount-p
        font-size .14rem
        margin-bottom .12rem
        color #1E9C5C
        &.sub
          color red
      .amount-v
        color #AC712B
</style>