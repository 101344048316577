import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import GerenZhongxin from '../views/GerenZhongxin.vue'
import UserInfo from '../components/GerenZhongxin/UserInfo.vue'
import RankPrivilege from '../components/GerenZhongxin/RankPrivilege.vue'
import VipDetails from '../components/GerenZhongxin/VipDetails.vue'
import ZijinDetails from '../components/GerenZhongxin/ZijinDetails.vue'
import ZdanQuery from '../components/GerenZhongxin/ZdanQuery.vue'
import UserReport from '../components/GerenZhongxin/UserReport.vue'
import DzyyView from '../views/DzyyView.vue'
import FavoriteView from '../views/FavoriteView.vue'
import versionTood from '@/utils/versionUpdate';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path:'/home',
    name:'main',
    component:HomeView,
  },
  {
    path:'/geren',
    name:'geren',
    component:GerenZhongxin,
    children:[
      {
        path:'info',
        name:'userInfo',
        component:UserInfo
      },
      {
        path:'level',
        name:'userLevel',
        component:RankPrivilege
      },
      {
        path:'vdetails',
        name:'vipDetails',
        component:VipDetails
      },
      {
        path:'zjdetails',
        name:'zijinDetails',
        component:ZijinDetails
      },
      {
        path:'zdquery',
        name:'zdanQuery',
        component:ZdanQuery
      },
      {
        path:'report',
        name:'report',
        component:UserReport
      }
    ]
  },
  {
    path:'/dzyy',
    name:'dzyy',
    component:DzyyView,
  },
  {
    path:'/favorite',
    name:'favorite',
    component:FavoriteView,
  },
  {
    path:'/play',
    name:'play',
    component:() => import('../components/PlayGame.vue')
  },
  {
    path:'/customer',
    name:'customer',
    component:() => import('../components/Customer.vue')
  },
  {
    path:'/maintenance',
    name:'maintenance',
    component:() => import('../views/QuanzWeihu.vue')
  },
  {
    path:'/chat',
    name:'chat',
    component:() => import('../views/ChatMoney.vue')
  },
  {
    path:'/test',
    name:'test',
    component:() => import('../views/Test.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

let prevRname = '';
router.beforeEach((to, from, next) => {
  if(prevRname != to.name){
    versionTood.isNewVersion();
  }
  prevRname = to.name;
  if(window.localRouteEach && window.localRouteEach[0]){
    window.localRouteEach.forEach(fun => {
      fun(to,from);
    })
  }
  next()
})

export default router
