<template>
  <div class="home">
    <div class="home-top-bg">
      <div class="user-wrap p-relative">
        <div class="avatar" @click="avatarClick">
          <img v-if="userInfo.avatar" :src="httpsPreHandle(userInfo.avatar)" alt="">
          <img v-if="!userInfo.avatar"
            src="../assets/home/top/user_avatar.jpeg" alt="">
        </div>
        <div>
          <div class="username">
            {{ userInfo.nickname }}
          </div>
          <div class="userid">
            ID:{{ userInfo.uid }}
          </div>
        </div>
        <div class="flex vip-bar">
          <div v-show="gradeInfo.grade" class="vip-ico-wrapper">
            <img :src="getNextLevelIco(1)" />
          </div>
        </div>
      </div>
      
      <AmountFunds :showRecharge="showRecharge" style="margin-left:-.34rem"
        :amount="userInfo.coin" @touristShow="showBindMobile"
        :isRefresAmount="isPlayGameBacked" />
      <AmountFunds :amount="userInfo.coin" :frozenValue="userInfo.frozen_prize" :isVip="gradeInfo.grade"
        :isYeb="1" @yebClick="yuebaoClick" @touristShow="showBindMobile" @goDetails="goToCallbackDetails" />

      <Task :show="taskShow" @excharge="exchargeClick"
        @goGame="goGameClick" @recomment="tuiguangClick" />
      <Duobao :show="duobaoShow" @tourntableHelp="turnHelpClick" 
        @showBindMobile="showBindMobile" />
      <Qiandao :show="qiandaoShow" />
      <Tiyanjin :show="tiyjinShow" :tiyanjin="tiyanjin" />
      
      <div class="m-func-btn flex" 
        :class="[browserBrand,`${isHp?'horizontal':''}`]">
        <div class="p-relative" 
          v-show="getFuncItemShow(funcIco)"
          v-for="funcIco of funcIcoList" @click="funcIcoClick(funcIco)">
          <div class="counting-down" 
            v-if="funcIco.key == 'envelop' && 
              redpackCountingDownTime != '00:00:00'">
            {{ redpackCountingDownTime }}
          </div>
          <div class="ico-c" :class="[`${funcIco.key}`]">
            <img class="ico-c recharge-act" v-if="funcIco.key == 'recharge-act'" 
              src="../assets/activity/recharge-act-icon.gif" />
            <div class="ico-c" v-if="funcIco.key != 'recharge-act'" :class="[`${funcIco.key}`]"
              :ref="`${funcIco.key}-ele`"></div>
          </div>
        </div>
      </div>
    </div>
    <PopWidget :isShow="turnHelpShow">
      <template v-slot:title>
        <div class="zhuanpan-help-title">帮助</div>
      </template>
      <template v-slot:content>
        <div class="des-content">
          1、明日抽奖机会根据今日有效投注统计<br>
          2、如果今日未进行有效投注，则明日将无抽奖机会<br>
          3、有效投注每日0点进行刷新，前日未使用有效投注不会统计进去<br>
          4、1有效投注等于1积分
        </div>
      </template>
    </PopWidget>
    <Ljb :show="coinLqshow" />
    <Customer :show="customerShow" />
    <Reporting :show="showReporting" @reporting="rechargeRportEvent" />
    <Tuiguang :show="tuiguangShow" :tabcode="tuiguanTabCode" />
    <Duihuan :show="duihuanShow" @duihuanSuccess="duihuanSuccessHandle" />
    <Activities :show="activityShow" @linkChange="activeLinkChange" @bonusGotted="actBonusGotHandle" />
    <div class="gott-bonus-mask" v-show="showGottBonus">
      <div class="content-wrapper-con">
        <div class="res-msg">{{actBonusGottedMsg}}</div>
        <img class="bonus-mas-clos-btn" src="../assets/activity/btn_confirm_bg.png" @click="gottBonusMaskClick" />
      </div>
    </div>
    <Yuebao :show="yuebaoShow" 
      @helpClick="reportingEvent" @forgetButtonClick="forgetButtonClick" />
    <RankList :show="ranListShow" />
    <EmailBox :show="emailShow" />
    <Regist @registClose="registClose" 
      :invokSource="1" :isShow="isShowBindMobile" />
    <YuebaoHelp :show="yuebaoHelpShow" />
    <RechargeAct :show="rechargeActShow" @goToRecharge="popRecharge()" />
    <!-- 重要弹窗 -->
    <PopWidget v-for="(widget,i) of importantWidgetList"
      :style="{'z-index':i+4}" :isShow="widget.isShow" :isFull="1"
      @closed="tcCloseClick(i)">
      <template v-slot:title>
        <div class="i-t-b-w flex">
          <div class="important-title-bg">
            {{widget.title}}
          </div>
        </div>
        
      </template>
      <template v-slot:content>
        <div class="tc-content-contaienr" v-html="widget.mobile_content">
        </div>
      </template>
    </PopWidget>
    <!-- 游客登录  -->
    <Login />
    <div class="main-content p-relative" ref="homeMainContent"
      :style="{'padding-top':`${panelPaddingTop}px`}">
      <HomeGameMenu @gameChange="gameMenuChange" 
        :wrapHeight="gameContainerHeight" :preMenuId="routeMenuId" />
      <HomeGameList
        :wrapHeight="gameContainerHeight"
        :gameid="selectGameid"
        invoke="home"
        :isTaskGame="isTaskGame"
        :isScrollerScroll="isScrollerScroll" />
      <div class="favorite-wrapper" 
        :class="[browserBrand,`${isHp?'horizontal':''}`]">
        <img class="favorit-ico" @click="gotoFavorite"
          src="../assets/home/game/favorite-ico.png" alt="">
        <img v-if="[-1,5].includes(selectGameid)"
          class="favorite-scrib" src="../assets/home/game/favorite-scrib.png" />
        <img v-if="[-1,5].includes(selectGameid)" @click="gotoNextPage"
          class="slid-direction"
          src="../assets/home/game/slid-direction.png" />
      </div>
    </div>
    <HomeFooter
      :newMail="userInfo.new_mail" @touristShow="showBindMobile"
      @tuiguangClick="tuiguangClick" @customer="customerClick" 
      @duihuanClick="duihuanClick" @activityClick="activityClick"
      @yuebaoClick="yuebaoClick" @ranListClick="ranListClick"
      @emailClick="emailClick" />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import lottie from 'lottie-web'
import Request from '../common/Request.js'
import Tool from '../common/Tool.js'
import LottieZipShow from '../common/LottieZipShow.js'
import HomeGameMenu from '../components/HomeGameMenu.vue';
import HomeGameList from '../components/HomeGameList.vue';
import HomeFooter from '../components/HomeFooter.vue';
import Login from '../components/Login.vue';
import Regist from '../components/Regist.vue';
import AmountFunds from '../components/gameList/AmountFunds.vue'
import Task from '../components/task/Task.vue'
import Duobao from '../components/duobao/Duobao.vue'
import Reporting from '../components/recharge/Reporting.vue'
import Customer from '../components/common/Customer.vue'
import PopWidget from '../components/common/PopWidget.vue'
import Qiandao from '../components/qiandao/Qiandao.vue'
import Tiyanjin from '../components/tiyanjin/Tiyanjin.vue'
import Tuiguang from '../components/tuiguang/Tuiguang.vue'
import Duihuan from '../components/duihuan/Duihuan.vue'
import Yuebao from '../components/yuebao/Yuebao.vue'
import YuebaoHelp from '../components/yuebao/YuebaoHelp.vue'
import RechargeAct from '../components/activity/RechargeAct.vue'
import Activities from '../components/activity/Activities.vue'
import RankList from '../components/rank-list/RankList.vue'
import EmailBox from '../components/email-box/EmailBox.vue'
import Ljb from '../components/ljb/Ljb.vue'

export default {
  name: 'HomeView',
  data(){
    return{
      selectGameid:'',
      userInfo:{},
      userInfoRefreshing:false,
      updateApiToken:false,
      taskShow:0,
      duobaoShow:0,
      customerShow:0,
      showRecharge:0,
      isTaskGame:'',
      turnHelpShow:0,
      qiandaoShow:0,
      tiyjinShow:0,
      tiyanjin:{},
      gradeInfo:{},
      tuiguangShow:0,
      tuiguanTabCode:'',
      duihuanShow:0,
      activityShow:0,
      yuebaoShow:0,
      ranListShow:0,
      emailShow:0,
      isShowBindMobile:0,
      yuebaoHelpShow:0,
      areadyAutoShowActivity:0,
      coinLqshow:0,
      //重要弹窗列表
      importantWidgetList:[],
      funcIcoList:[
        {
          key:'recharge-act'
        },
        {
          key:'envelop',
        },
        {
          key:'renwu'
        },
        {
          key:'coin-ico'
        },
        {
          key:'xydb'
        },
        {
          key:'qiandao'
        },
        {
          key:'tiyanjin'
        },
        {
          key:'tuiguang'
        }
      ],
      redpackCountingDownTime:'00:00:00',
      isScrollerScroll:0,
      browserBrand:'',
      isPlayGameBacked:0,
      isHp:0,//横屏
      gameContainerHeight:0,
      panelPaddingTop:0,
      routeMenuId:'',

      lingjiangPopShow:0,
      showGottBonus:0,
      actBonusGottedMsg:'',
      rechargeActShow:0,
    }
  },
  mounted(){
    const _this = this;
    this.browserBrand = Tool.getMobileBrand();
    setTimeout(this.setFuncBtnAnimation)
    this.getUserGrateInfo();
    
    let autoShow = Tool.getAutoTanchuang()
    if(!autoShow){
      Tool.setAutoTanchuang()
      this.getNoticeTanc(() => {
        setTimeout(() => {
          if(this.importantWidgetList[0]){
            this.importantWidgetList[0].isShow = Math.random();
          }
        },200);
      });
    }
    window.redpackCountingDownTick = function(v){
      _this.redpackCountingDownTime = v;
    };

    this.getLocalUserInfo();
    if(!this.userInfo || !this.userInfo.uid){
      this.getUserInfo();
    }

    window.addEventListener('orientationchange',() => {
      this.hvLayout();
    });
    window.addEventListener('resize',() => {
      this.hvLayout();
    });
    this.hvLayout();
    
    this.gameContainerHeight =
      this.$refs.homeMainContent.clientHeight;
    this.layoutByContainerHeight();
    
  },
  methods:{
    ...mapMutations([
      'popMsg',
      'alert',
      'anonymousLogin',
      'showLoading',
      'closeLoading',
      'setEnvelopShow',
      'IsupdateUserCoin',
      'popRecharge',
    ]),
    httpsPreHandle(url){
      return Tool.urlProtocalHandle(url)
    },
    goToCallbackDetails(type){
      if(type == 0){
        this.rechargeActShow = Math.random()
      }else if(type == 1){
        this.tuiguangClick(3)
      }
    },
    layoutByContainerHeight(){
      this.panelPaddingTop = this.gameContainerHeight * 10 / 289;
    },
    hvLayout(){
      this.isHp = innerWidth > innerHeight;
    },
    actBonusGotHandle(msg){
      this.actBonusGottedMsg = msg;
      this.showGottBonus = Math.random();
    },
    gottBonusMaskClick(){
      setTimeout(() => {
        this.showGottBonus = 0;
      },400)
    },
    goCustomerUrl(){
      this.getCustomerUrl(() => {
        this.$router.push({name:'customer'});
      });
    },
    getCustomerUrl(callback){
      let customer = Tool.getCusLink();
      if(customer){
        callback(customer);
      }else{
        Request.get('/pub/callCustomer').then(res => {
          if(res.data.code == 0){
            Tool.setCusLink(res.data.data);
            callback(res.data.data);
          }
        })
      }
    },
    duihuanSuccessHandle(){
      this.IsupdateUserCoin();
    },
    activeLinkChange(code1){
      let code = code1 * 1;
      let popCode = ''
      if([1,2,3,4,5,15,16,18,20].includes(code)){
        if([1,2,3,4].includes(code)){//幸运转盘
          popCode = 'xydb'
        }else if(5 == code){ //签到
          popCode = 'qiandao'
        }else if(15 == code){ //体验金
          popCode = 'tiyanjin'
        }else if(16 == code){ //领金币
          popCode = 'coin-ico'
        }else if(18 == code){
          popCode = 'renwu'
        }else if(20 == code){
          popCode = 'envelop'
        }
        this.funcIcoClick({key:popCode});
      }else if([6,7,8,9].includes(code)){//代理推广
        this.tuiguangClick(code);
      }else if([10,11].includes(code)){ //热门活动
        this.activityClick();        
      }else if(12 == code){ //充值
        this.exchargeClick();
      }else if(13 == code){ //游戏排行榜
        this.ranListClick();
      }else if(17 == code){
        this.goCustomerUrl();
      }else if([14,19].includes(code)){
        if(19 == code){
          this.$router.push({name:'userLevel'})
        }else{
          this.$router.push({name:'userInfo'})
        }
      }
    },
    gotoNextPage(){
      this.isScrollerScroll = Math.random();
    },
    showRedpackQiang(){
      this.setEnvelopShow(Math.random())
    },
    getFuncItemShow(fItem){
      let flag = 0;
      const uInfo = this.userInfo;
      switch(fItem.key){
        case 'envelop':
          flag = uInfo.is_redpack*1;
          break;
        case 'renwu':
          flag = uInfo.is_mission*1;
          break;
        case 'coin-ico':
          flag = uInfo.is_share*1;
          break;
        case 'xydb':
          flag = uInfo.is_lucky*1;
          break;
        case 'qiandao':
          flag = uInfo.is_sign*1;
          break;
        case 'tiyanjin':
          flag = uInfo.is_gold*1;
          break;
        case 'tuiguang':
          flag = uInfo.is_agent*1;
          break;
        case 'recharge-act':
          flag = uInfo.recharge_promo * 1;
          // flag = 1;
          break;
      }
      return flag;      
    },
    //绑定手机，关闭后弹出活动
    registClose(){
      if(!this.userInfo || !this.userInfo.is_tourist){
        this.autoShowActiviy();
      }
    },
    autoShowActiviy(){
      if(!this.areadyAutoShowActivity){
        this.areadyAutoShowActivity = 1;
        this.activityClick();
      }
    },
    showBindMobile(){
      this.isShowBindMobile = Math.random();
      this.popMsg({
        msg:'请先绑定手机',
        time:1200
      });
    },
    tcCloseClick(closeIndex){
      if(this.importantWidgetList[closeIndex+1]){
        this.importantWidgetList[closeIndex+1].isShow = Math.random();
      }else{
        if(!this.userInfo || !this.userInfo.mobile){
          this.showBindMobile();
        }else{
          this.autoShowActiviy();
        }
      }
    },
    getNoticeTanc(callback){
      Request.post('/pub/notice_tc').then(res => {
        if(res.data && res.data.data){
          let tcList = [];
          let weihu = [];
          let tanc = [];
          res.data.data.forEach((item,i) => {
            item.isShow = 0;
            if(item.show_type == 4){
              weihu.push(item);
            }else{
              tanc.push(item);
            }
          });
          tcList = tcList.concat(weihu);
          tcList = tcList.concat(tanc);
          this.importantWidgetList = tcList;
        }
        if(callback)callback()
      })
    },
    emailClick(){
      this.emailShow = Math.random();
    },
    ranListClick(){
      this.ranListShow = Math.random();
    },
    getNextLevelIco(isnow){
      let grade = 1;
      if(this.gradeInfo){
        let key = isnow ? 'grade' : 'nextGrade';
        let grade1 = this.gradeInfo[key];
        if(grade1 != '0' && grade1){
          grade = grade1;
        }
      }
      return require(`../assets/home/top/vip-level/vip-level-ico${grade}.png`)
    },
    getUserGrateInfo(){
      const _this = this;
      let localGradeInfo = Tool.getGradeInfo();
      if(localGradeInfo){
        this.gradeInfo = localGradeInfo;
        return;
      }
      Request.post(`/user/grade_info`).then(res => {
        if(res.data && res.data.data){
          Tool.setGradeInfo(res.data.data)
          this.gradeInfo = res.data.data;
        }
      }).catch(e => {
        if(e.response && e.response.data){
          if(e.response.data.code == 1000002){
            Request.userOutted(_this);
          }
        }
        console.log(e)
      });
    },
    gotoFavorite(){
      if(this.userInfo && this.userInfo.is_tourist){
        this.showBindMobile();
        return;
      }
      this.$router.push('/favorite')
    },
    turnHelpClick(){
      this.turnHelpShow = Math.random();
    },
    goGameClick(gameInfo){
      let infoStr = JSON.stringify(gameInfo);
      this.isTaskGame = (infoStr + '^'+Math.random())
    },
    exchargeClick(){
      this.taskShow = 0;
      this.showRecharge = Math.random()
    },
    rechargeRportEvent(){
      this.customerShow = Math.random()
    },
    reportingEvent(){
      this.yuebaoHelpShow = Math.random();
    },
    duihuanClick(){
      this.duihuanShow = Math.random();
    },
    activityClick(){
      this.activityShow = Math.random();
    },
    yuebaoClick(){
      this.yuebaoShow = Math.random();
    },
    tuiguangClick(code){
      this.tuiguanTabCode = code;
      this.tuiguangShow = Math.random();
    },
    funcIcoClick(fItem){
      if(this.userInfo && this.userInfo.is_tourist){
        this.showBindMobile();
        return;
      }
      switch(fItem.key){
        case 'envelop':
          this.showRedpackQiang();
          break;
        case 'renwu':
          this.taskShow = Math.random()
          break;
        case 'coin-ico':
          this.coinLqshow = Math.random()
          break;
        case 'xydb':
          this.duobaoShow = Math.random()
          break;
        case 'qiandao':
          this.qiandaoShow = Math.random();
          break;
        case 'tiyanjin':
          this.tiyjinClick();
          break;
        case 'tuiguang':
          this.tuiguangClick();
          break;
        case 'recharge-act':
          this.rechargeActShow = Math.random();
          break;
      }
    },
    getTyjData(callback){
      let getTyj = (id) => {
        Request.get(`/activity/gold?id=${id}`).then(res => {
          if(res.data.code != 0){
            this.alert(res.data.msg)
          }else if(res.data.data){
            this.tiyanjin = res.data.data;
          }
          callback('',res.data.code)
        }).catch(() => {
          callback('出错!',1)
        })
      }
      Request.get('/activity/activity_gold_id').then(res => {
        if(res.data && res.data.data && res.data.data.id){
          getTyj(res.data.data.id)
        }else{
          callback('出错!',1)
        }
      })
    },
    tiyjinClick(){
      this.showLoading();
      this.getTyjData((msg,code) => {
        this.closeLoading();
        if(msg){
          this.alert(msg)
        }
        if(code == 0 && this.tiyanjin.money){
          this.tiyjinShow = Math.random();
          this.getUserInfo(null,'update');
        }
      });
    },
    getLocalUserInfo(){
      let localUserInfo = Tool.getLocalUserinfo();
      if(localUserInfo){
        this.userInfo = localUserInfo;
      }
    },
    getUserInfo(callback,isUpdate){
      let token = Tool.getApiToken();
      if(!token){
        //游客登录
        this.anonymousLogin();
        if(callback) callback();
        return;
      }
      let localUserInfo = Tool.getLocalUserinfo();
      if(localUserInfo && !isUpdate){
        this.userInfo = localUserInfo;
        if(callback) callback();
        return;
      }
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          this.userInfo = res.data.data;
          Tool.setLocalUserinfo(res.data.data);
          if(callback) callback();
        }
      }).catch(erro => {
        if(!this.updateApiToken){
          this.updateApiToken = true;
          Tool.clearApiToken();
          this.getUserInfo();
        }else{
          Request.userOutted(this)
        }
      });
    },
    avatarClick(){
      if(this.userInfo && this.userInfo.is_tourist){
        Tool.quit();
        this.$router.push({ name: 'login'})
        return;
      }
      this.$router.push({ name: 'userInfo'})
    },
    setFuncBtnAnimation(){
      const setAnimate = (ele,json) => {
        lottie.loadAnimation({
          container: ele,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: json
        });
      }
      this.funcIcoList.forEach(item => {
        let refE = this.$refs[`${item.key}-ele`];
        if(refE && refE[0]){
          if(['renwu','xydb','qiandao','coin-ico',
            'tiyanjin','envelop','tuiguang'
            ].includes(item.key)){
            LottieZipShow.showIco(`/lottie-web/${item.key}.zip`,
            item.key+'-animate',refE[0])
            return;
          }
          const animateEle = refE[0];
          setAnimate(animateEle,`/lottie-web/${item.key}/data.json`)
        }
      });

      
      
    },
    gameMenuChange(id){
      this.selectGameid = id;
    },
    forgetButtonClick(){
      this.customerShow = Math.random()
    },
    customerClick(){
      this.customerShow = Math.random()
    }
  },
  beforeRouteEnter(to,from,next){
    next(_this => {
      if(from.name == 'play'){
        _this.isPlayGameBacked = Math.random()
      }
      _this.routeMenuId = to.query.menu_id?to.query.menu_id:-1;
    })
  },
  watch:{
    '$route.query.menu_id'(n){
      this.routeMenuId = n;
    },
    // 匿名登录成功
    innerAnonymousLogined(n,o){
      if(n){
        this.getUserInfo();
      }
    },
    userInfoLoaded(){
      this.getUserInfo();
    }
  },
  computed:{
    ...mapGetters({
      innerAnonymousLogined:'getInnerAnonymousLogined',
      showReporting:'getShowReporting',
      userInfoLoaded:'getUserInfoLoaded'
    }),
  },
  components:{
    HomeGameMenu,
    HomeGameList,
    HomeFooter,
    Login,
    AmountFunds,
    Task,
    Duobao,
    Reporting,
    Customer,
    PopWidget,
    Qiandao,
    Tiyanjin,
    Tuiguang,
    Duihuan,
    Activities,
    Yuebao,
    RankList,
    EmailBox,
    Regist,
    Ljb,
    YuebaoHelp,
    RechargeAct,
  }
}
</script>
<style lang="stylus">
.tc-content-contaienr
  & > p
    width 100%
  img
    display block
    width 100%
</style>
<style scoped lang="stylus">
.zhuanpan-help-title
  margin auto
  padding-top .02rem
  width .31rem
.i-t-b-w
  height 100%
  justify-content center
.important-title-bg
  color #e7e7e7
  padding .05rem .38rem 0 .38rem
  height .32rem
  background-image url(../assets/home/important-title-bg.png)
  background-size 100% 100%
.tc-content-contaienr
  max-height 2.38rem
  min-height 1.82rem
  overflow auto
  line-height .24rem
  padding .1rem .1rem .1rem .1rem
  text-align left
  font-size .16rem
  color #bcbcbc
.home
  width 100%
  height 100%
  position relative
  background-size cover
  .home-top-bg
    width 100%
    height .52rem
    display flex
    background-image url('../assets/home/top/home-top-bg.png')
    background-size 100% 100%
    background-repeat no-repeat
    background-position right
    .vip-bar
      position absolute
      right .52rem
      bottom .13rem
      border-top 1px solid transparent
      align-items start
      .normal-vip
        width .6rem
      .vip-ico-wrapper
        width .32rem
        background-size 100% 100%
        img
          display block
          width 100%
    .user-wrap
      min-width 2.55rem
      height .57rem
      display flex
      align-items center
      font-size .14rem
      text-align left
      background-image url(../assets/home/user-info-bg1.png)
      background-size 100% 100%
      background-repeat no-repeat

      .avatar
        width .38rem
        height .38rem
        padding .01rem .02rem .03rem .02rem
        border-radius .04rem
        margin-left .19rem
        margin-right .08rem
        background-image url(../assets/home/top/avatar-border.png)
        background-size 100% 100%
        img
          display block
          width 100%
          height 100%
      .username
        max-width 1.3rem
        overflow hidden
        font-size .12rem
        text-overflow ellipsis
        white-space nowrap
        color #FFFFFF
      .userid
        color #FFFD9C
        font-size .12rem
    .m-func-btn
      width 2.80rem
      height .52rem
      justify-content flex-end
      position relative
      user-select none
      & > .p-relative
        float right
      &.mb2345browser,&.vivo
        padding-right .37rem
      .counting-down
        font-size .12rem
        padding .02rem .14rem
        position absolute
        color #FFBF54
        z-index 1
        bottom 0
        left 50%
        transform translateX(-50%)
        background-image linear-gradient(to right, rgba(105,24,0,0) 0%,rgba(105,24,0,1) 15%,rgba(105,24,0,1) 85%,rgba(105,24,0,0) 100%)
      .ico-c
        width .4rem
        height 100%
        flex-shrink 0
        overflow hidden
        display flex
        justify-content center
        &.renwu
          .renwu
            transform scale(1)
        &.envelop
          overflow visible
          .envelop
            transform scale(1)
        &.coin-ico
          .coin-ico
            transform scale(1.4)
        &.xydb
          .xydb
            transform scale(1.5)
        &.qiandao 
          .qiandao
            transform scale(1.5)
        &.tiyanjin
          .tiyanjin
            transform scale(1.4)
        &.tuiguang
          .tuiguang
            transform scale(1.4)
        &.recharge-act
          .recharge-act
            transform scale(.9)
  .main-content
    width 100%
    height calc(100% - 1.03rem)
    padding-top .06rem
    padding constant(safe-area-inset-top) constant(safe-area-inset-right)constant(safe-area-insert-bottom)constant(safe-area-inset-left);
    display flex
    .favorite-wrapper
      width .6rem
      position absolute
      right .16rem
      top .05rem
      z-index 2
      &.horizontal
        width .46rem
        right 0
      &.mb2345browser,&.vivo
        width .6rem
        right .3rem
      .favorit-ico
        width .44rem
      .favorite-scrib
        width .42rem
      .slid-direction
        width .3rem
        margin-left .06rem
.des-content
  width 77%
  text-align left
  margin auto
  line-height .25rem
.gott-bonus-mask
  width 100%
  height 100%
  position absolute
  top 0
  left 0
  background-color #00000066
  z-index 500
  display flex
  .content-wrapper-con
    width 3.3rem
    height 3.03rem
    background-image url(../assets/activity/frame_congratulations_bg.png)
    background-size 100% 100%
    margin auto
    position: relative;
    .res-msg
      position absolute
      bottom .98rem
      left 50%
      transform translateX(-50%)
      color white
    .bonus-mas-clos-btn
      position absolute
      bottom .48rem
      width .89rem
      left 50%
      transform translateX(-50%)
</style>