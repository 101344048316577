<template>
  <PopWidget :isFull="1" :isShow="isShowPopWidget"
    style="z-index:4">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/yuebao/sfyz-title.png" />
    </template>
    <template v-slot:content>
      <div class="main-content" v-if="userInfo">
        <div class="flex fill-row">
          <div class="label-f flex">手机号码：</div>
          <div>{{userInfo.mobile}}</div>
        </div>
        <div class="flex fill-row">
          <SmsCode :mobile="userInfo.mobile" @smsCoding="smsCodeingHandle" />
        </div>
        <div class="remark">
          备注：为了您的账户安全请输入手机短信验证码
        </div>
        <img @click="subClick"
            class="confirm-btn" src="../../assets/login/confirm-btn.png" />
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import SmsCode from '../common/SmsCode.vue'
import UserInfo from '@/common/UserInfo';

export default{
  props:{
    show:Number,
  },
  data(){
    return{
      smsCode:'',
      isShowPopWidget:0,
      userInfo:{},
    }
  },
  mounted(){
    UserInfo.getUserInfoData(data => this.userInfo = data);
  },
  methods:{
    subClick(){
      this.$emit('smsConfirm',this.smsCode)
    },
    smsCodeingHandle(smsCode){
      this.smsCode = smsCode;
    }
  },
  watch:{
    show(n){
      if(n){
        this.isShowPopWidget = n;
      }
    }
  },
  components:{
    PopWidget,
    SmsCode
  }
}
</script>

<style scoped lang="stylus">
.l-t-title
  display block
  margin auto
  height .3rem !important
.main-content
  width 100%
  padding-top .2rem
  .fill-row
    margin-bottom .1rem
  .label-f
    width 1.8rem
    justify-content flex-end
  .remark
    width 100%
    padding-top .3rem
    line-height .3rem
    color #C91411
  .confirm-btn
    width 1.12rem
    transition transform .3s
    &:active
      transform scale(.9)
</style>