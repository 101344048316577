import Tool from './Tool.js'
import Request from './Request.js'

export default {
  config(){
    let echo = {};
    let userInfo = Tool.getLocalUserinfo();
    let api_token = Tool.getApiToken();

    let wsUrl = Request.getWsHost();

    // const wsUrl = 'http://test.bytechcdn002.com:39012';
    // const wsUrl = `http://43.198.23.134:39012?api_token=${api_token}`;
    // const wsUrl = '/api/:39012';
    
    if(api_token && userInfo && userInfo.hashid){
      const authDic = {
        'Authorization': 'Bearer ' + api_token,
      }
      echo = new window.Echo({
        namespace: 'app.events',
        broadcaster: 'socket.io',
        host:wsUrl,
          auth: {
            headers: authDic,
          },
      });
    }
    return echo;
  },
  async configSocket(app){
    const socket = this.config();
    if(process.env.NODE_ENV != 'development'){
      app.config.globalProperties.$socket = socket;
    }
  }
}