<template>
  <PopWidget :isShow="isShowPopWidget" @closed="closed" :bgClickClose="1">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/binding-title-a.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <slot name="confirm-content">
        </slot>
        <div class="func-wrap flex">
          <div class="confimr-btn ease-btn-effe" @click="cancel"></div>
          <slot name="confirm-btn-w">
            <div class="confimr-btn ease-btn-effe" @click="confirm"></div>
          </slot>
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from './PopWidget.vue'
export default{
  props:{
    show:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
    }
  },
  methods:{
    cancel(){
      setTimeout(() => {
        this.isShowPopWidget = 0;
      },100)
    },
    confirm(){
      this.$emit('confirm')
    },
    closed(){
      this.isShowPopWidget = 0;
    }
  },
  components:{
    PopWidget
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
    }
  }
}
</script>

<style scoped lang="stylus">
.l-t-title
  display block
  height .32rem !important
  margin auto
.l-c-w-i
  width 100%
  font-size .14rem
  padding .2rem 0 0 0
  .func-wrap
    width 100%
    justify-content center
    margin-top .2rem
    .confimr-btn
      width .8rem
      height .26rem
      font-size .12rem
      line-height .26rem
      border-radius .16rem
      text-align center
      background-image url(../../assets/cancel-btn.png)
      background-size 100% 100%
      &:nth-child(2)
        margin-left .1rem
        background-image url(../../assets/confirm-btn.png)
</style>