<template>
  <div class="dzyy-container">
    <div class="top flex">
      <div class="nav flex">
        <div class="go-back" @click="goBackClick"></div>
        <div class="decorate1" v-if="localType == 1"></div>
        <div class="decorate1 decorate-qp" v-if="localType == 3"></div>
      </div>
      <div class="flex">
        <AmountFunds style="margin-top:.03rem" :amount="userInfo.coin" 
        @touristShow="showBindMobile" :isRefresAmount="isPlayGameBacked" />
        <form class="search-wrap flex" autocomplete="off">
          <input autocomplete="false" name="hidden" 
            type="text" style="display:none;">
          <input placeholder="请输入游戏名"
            v-model="searchTxt" class="search" type="text" />
          <div class="search-btn" @click="searchBtnClick"></div>
        </form>
      </div>
    </div>

    <div class="main-content flex">
      <HomeGameMenu :invoke="localType" 
        @dzyyChange="gameMenuChange" 
        :preMenuId="queryId" />
      <HomeGameList :invoke="localType" 
        :isSearch="isSearch" 
        :platformCode="platformCode"
        :mtitle="gameMenuTitle"
        :isScrollerScroll="isScrollerScroll" />
      <div class="favorite-wrapper">
        <img class="favorit-ico" @click="$router.push('/favorite')"
          src="../assets/home/game/favorite-ico.png">
        <img class="favorite-scrib" src="../assets/home/game/favorite-scrib.png" />
        <img class="slid-direction" @click="gotoNextPage"
          src="../assets/home/game/slid-direction.png" />
      </div>
    </div>

    <Regist :invokSource="1" :isShow="isShowBindMobile" />
  </div>
</template>
<script>
import Tool from '../common/Tool.js'
import HomeGameMenu from '../components/HomeGameMenu.vue'
import HomeGameList from '../components/HomeGameList.vue'
import AmountFunds from '../components/gameList/AmountFunds.vue'
import Regist from '../components/Regist.vue'
import { mapMutations } from 'vuex'

export default {
  data(){
    return {
      platformCode:'',
      gameMenuTitle:'',
      queryId:'',
      //:1电子游艺，0棋牌
      localType:'1',
      userInfo:{},
      searchTxt:'',
      isSearch:'',
      isScrollerScroll:0,
      isShowBindMobile:0,
      isPlayGameBacked:0,
      prevRoute:null,
    }
  },
  mounted(){
    this.userInfo = Tool.getLocalUserinfo();
  },
  methods:{
    ...mapMutations(['popMsg']),
    goBackClick(){
      if(this.prevRoute && this.prevRoute.name == 'main'){
        this.$router.push(this.prevRoute);
      }else{
        this.$router.push({name:'main'})
      }
    },
    showBindMobile(){
      this.isShowBindMobile = Math.random();
      this.popMsg({
        msg:'请先绑定手机',
        time:1200
      });
    },
    gameMenuChange(menu){
      this.platformCode = menu.platformCode;
      this.gameMenuTitle = menu.title;
    },
    searchBtnClick(){
      this.isSearch = `${this.searchTxt}-${Math.random()}`
    },
    gotoNextPage(){
      this.isScrollerScroll = Math.random();
    }
  },
  beforeRouteEnter(to,from,next){
    next(vm => {
      vm.queryId = to.query.id;
      vm.localType = to.query.l_type;
      vm.prevRoute = from;
      if(from.name == 'play'){
        vm.isPlayGameBacked = Math.random();
      }
    });
  },
  components:{
    HomeGameMenu,
    HomeGameList,
    AmountFunds,
    Regist
  }
}
</script>
<style scoped lang="stylus">
.dzyy-container
  width 100%
  height 100%
  flex-direction column
  position relative
  .top
    width 100%
    height .55rem
    background-image url(../assets/home/top/home-t-b-bg.png)
    background-size 100% 100%
    .nav
      width auto
      height .55rem
      margin-right .36rem
      align-items flex-end
    .go-back
      width 1.28rem
      height 100%
      background-image url('../assets/geren/go-back.png')
      background-repeat no-repeat
      background-size 100% 100%
    .decorate1
      width 1.38rem
      height 40%
      background-image url('../assets/dzyy/decorate.png')
      background-repeat no-repeat
      background-size 100% 100%
      margin-bottom .12rem
      &.decorate-qp
        background-image url('../assets/dzyy/decorate-qp.png')
  .search-wrap
    height 100%
    padding-top .12rem
    margin 0 .1rem
  input
    &.search
      width 1.5rem
      height .3rem
      line-height .3rem
      padding-left .04rem
      background none
      border none
      background-image url(../assets/dzyy/search-border.png)
      background-size 100% 100%
  .search-btn
    width .4rem
    height .3rem
    background-image url(../assets/dzyy/search-btn-ico.png)
    background-size 100% 100%
  .main-content
    .favorite-wrapper
      width .6rem
      .favorit-ico
        width .44rem
      .favorite-scrib
        width .42rem
      .slid-direction
        width .35rem
        margin-left .04rem
        &.left
          transform: rotateZ(180deg);
</style>