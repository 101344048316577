<template>
  <PopWidget :isShow="isShowPopWidget" style="z-index: 4;" :isFull="1">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/recharge/recharge-record-title.png" />
    </template>
    <template v-slot:content>
      <div class="report-content">
        <div class="flex row head">
          <div>时间</div>
          <div>类型</div>
          <div>充值金额</div>
        </div>
        <div class="nodata flex" v-if="!recordList.length">
          <img class="no-data-ico" src="../../assets/tuiguang/no-data.png" />
          <div>暂无数据</div>
        </div>
        <div v-else class="row-wrapper" @scroll="scrollerScroll">
          <div class="flex row b" v-for="item of recordList">
            <div>{{item.created_at}}</div>
            <div>{{item.way_name}}</div>
            <div>{{item.amount}}</div>
          </div>
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import { mapMutations } from 'vuex';

export default{
  props:{
    show:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      recordList:[],
      dataPager:{},
      cPage:1,
      nextPageGetting:0,
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading']),
    scrollerScroll(e){
      let sTop = e.target.scrollTop;
      let scrollSpace = sTop + e.target.clientHeight
      let sub = e.target.scrollHeight - scrollSpace;
      if(sub < 5){
        this.getNextPageData();
      }
    },
    getNextPageData(){
      if(!this.dataPager)return;
      const {per_page,total} = this.dataPager;
      const totalPage = Math.ceil(total/per_page);

      if(this.cPage >= totalPage) {
        return;
      }
      if(this.nextPageGetting == 0){
        this.nextPageGetting = 1;
        this.cPage++;
        this.getDataApi(this.cPage,() => {
          this.nextPageGetting = 0;
        });
      }
    },
    getDataApi(nPage,callback){
      const cPage = nPage ? nPage : 1;
      this.showLoading()
      Request.get(`/user/recharge_record?page=${cPage}`).then(res => {
        this.closeLoading()
        //假数据
        (() => {
          let single = {
            created_at:'2023-08-6 12:08:08',
            way_name:'充值',
            amount:22.22,
          }
          for(let i = 0; i < 30;i++){
            this.recordList.push(JSON.parse(JSON.stringify(single)))
          }
        })

        if(res.data.data && res.data.data.list && res.data.data.list.data){
          this.dataPager = res.data.data.list;
          res.data.data.list.data.forEach(dataItem => {
            this.recordList.push(dataItem)
          })
          if(callback)callback();
        }
        
      }).catch(() => {
        this.closeLoading()
        if(callback)callback();
      })
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      if(!this.recordList.length){
        this.getDataApi();
      }
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style lang="stylus" scoped>
.report-content
  width 100%
  min-height 2rem
  .nodata
    flex-direction column
    justify-content center
    align-items center
    .no-data-ico
      width .3rem
      margin .68rem 0 .1rem
  .row
    width 100%
    justify-content space-around
    &.head
      background-color #161417
      font-size .14rem
    &.b
      font-size .12rem
      border-bottom 1px dotted #000000
    &>div
      width 33.33%
      line-height .25rem
  .row-wrapper
    width 100%
    height 2rem
    padding .08rem 0
    overflow auto
</style>