<template>
  <PopWidget :isShow="show">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/tuiguang/lingqu-record-title.png" />
    </template>
    <template v-slot:content>
      <div class="data-table">
        <div class="flex data-row head">
          <div class="ceil">时间</div>
          <div class="ceil">领取金额</div>
        </div>
        <div class="data-scroller">
          <div class="flex data-row" v-for="item of dataRecords">
            <div class="ceil">{{ item.created_at }}</div>
            <div class="ceil">{{ item.amount }}</div>
          </div>
          <div class="nodata flex" v-if="!dataRecords || !dataRecords.length">
            <img class="no-data-ico" src="../../assets/tuiguang/no-data.png" />
            <div>暂无数据</div>
          </div>
        </div>
      </div>
    </template>
  </PopWidget>
</template>

<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'

export default{
  props:{
    show:Number
  },
  data(){
    return {
      dataRecords:[],
    }
  },
  methods:{
    getDataRecord(){
      Request.post('/agent/apply_record').then(res => {
        this.dataRecords = res.data.data.data;
      })
    }
  },
  watch:{
    show(){
      this.getDataRecord();
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style lang="stylus" scoped>
.data-table
  width 100%
  font-size .12rem
  .data-scroller
    width 100%
    height 1.48rem
    overflow auto
    .nodata
      width 100%
      padding .53rem 0
      flex-direction column
      align-items center
      justify-content center
      .no-data-ico
        width .24rem
        margin-bottom .05rem
  .data-row
    align-items center
    height .2rem
    justify-content flex-start
    .ceil
      width 50%
    &.head
      font-size .14rem
      background-color #454545
      color #ffffff
</style>