<template>
  <RechargeRecord :show="recordShow" />

  <Confirm :show="showConfirmPayUrl" 
    style="z-index:4;" width='3.5rem' @confirm="confirmHandle">
    <template v-slot:confirm-content>
      <div class="confirm-wrapper">
        确定跳转或点击<a :href="payUrl" target="_blank">支付链接</a>？
      </div>
    </template>
    <template v-slot:confirm-btn-w v-if="payUrl">
      <a class="confimr-btn ease-btn-effe" target="_blank"
        :href="payUrl"></a>
    </template>
  </Confirm>

  <PopWidget 
    width="6rem" 
    :isFull="1" style="z-index:4;"
    :isShow="isShowVipPopWidget">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/recharge/contact-agent.png" />
    </template>
    <template v-slot:content>
      <div class="main-content copy">
        <div class="flex">
          <div class="icon-wrap1">
          </div>
          <input ref="accountVipInfo"
            readonly class="account-info1" v-model="vipWayAccount" />
        </div>
        <div>
          <img class="copy-open-wechat-btn ease-btn-effe" 
            @click="copyBtnClick"
            src="../../assets/recharge/copy-open-wechat-btn.png" />
        </div>
        <div class="flex des-wrap">
          点击按钮系统会帮助您复制代理的微信号，请您打开<br>
          微信，添加好友时长按屏幕粘贴即可，加完好友10秒<br>
          内帮您完成充值。
        </div>
      </div>
    </template>
  </PopWidget>

  <PopWidget width="6rem" :isFull="1" :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/recharge/recharge-title.png" />
        <!-- 举报有奖 闪付 暂时不上-->
        <img @click="reportingClick" class="reporting-btn ease-btn-effe"
        src="../../assets/recharge/reporting.png" v-if="promoInfo.report_bonus" />
        <div class="recharge-record-btn"
          @click="recordShowClick">
          充值记录
        </div>
    </template>
    <template v-slot:content>
      <div class="main-content flex">
        <!-- <div class="menu-title-w" @click="recordShowClick"></div> -->
        <div class="menu-wrap">
          <div class="inner-back">
            <div class="menu-wrap-scroll">
              <div class="menu-btn" @click.stop="menuChange(i,menu)"
                v-for="(menu,i) of menuList" :data-id="menu.id">
                <div v-if="menu.id == 46" class="yinshang-ico">
                  <div v-show="userInfoData.banker_rate"
                    class="zengsong-p-wrapper">
                    {{ userInfoData.banker_rate+'%' }}
                  </div>
                  <img v-show="!userInfoData.banker_rate" class="huobao-ico"
                  src="../../assets/recharge/menu-huobao-ico.png" />
                </div>
                <div v-else-if="menu.discount > 0" class="yinshang-ico">
                  <div v-show="menu.discount" class="zengsong-p-wrapper">
                    <div class="m-dis-ratio">{{menu.discount}}%</div>
                  </div>
                </div>
                <img class="huobao-ico" v-else
                  v-show="[1].includes(menu.id) || menu.is_hot == '1'"
                  src="../../assets/recharge/menu-huobao-ico.png" />
                <div class="normal-bg" v-if="i != selectI"></div>
                <div class="select-bg" v-if="i == selectI"></div>
                <div class="menu-text" :class="{focus:i == selectI}">
                  {{ menu.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currMenu.id == 'UNIONWAP'" class="m-w-page">
          <div class="flex bank-item-wrap">
            <div class="line-choicer">
              线路选择
            </div>
            <div class="bank-se-item flex" :class="{selected:i==bankSelectIndex}"
              @click="banckTabClick(i)"
              v-for="(bank,i) of currMenu.chargeList">
              {{bank.extra?bank.extra.bank_name:''}}
            </div>
          </div>
          <div class="flex bank-r-info f">
            <div class="label-bank-info">收款银行：</div>
            <div v-if="bankAccountInfo.extra">
              {{bankAccountInfo.extra.bank_name}}
            </div>
          </div>
          <div class="flex bank-r-info">
            <div class="label-bank-info">收款姓名：</div>
            <input class="back-username-wrap" type="text" readonly
              v-if="bankAccountInfo.extra" ref="bankUserNameWrap"
              v-model="bankAccountInfo.extra.bank_username" />
            <img class="copy-button ease-btn-effe" @click="bankNameCopyClick"
              src="../../assets/geren/copy-btn.png" />
          </div>
          <div class="flex bank-r-info">
            <div class="label-bank-info">收款账号：</div>
            <input class="back-username-wrap" type="text" readonly
              v-if="bankAccountInfo.extra" ref="bankAccountWrap"
              v-model="bankAccountInfo.extra.bank_account" />
            <img class="copy-button ease-btn-effe" @click="bankAccountCopyClick"
              src="../../assets/geren/copy-btn.png" />
          </div>
          <div class="flex bank-r-info">
            <div class="label-bank-info h30">充值金额：</div>
            <input v-model="rechargeAmount" type="number"
              :placeholder="`请输入最小${bankAccountInfo.extra.min}元，最大${bankAccountInfo.extra.max}元`" />
          </div>
          <div class="flex bank-r-info">
            <div class="label-bank-info h30">付款人：</div>
            <input v-model="payerName" 
              placeholder="请输入付款人姓名" />
          </div>
          <div class="bank-remark">
            {{bankAccountInfo.extra?bankAccountInfo.extra.bank_remark:''}}
          </div>
          <img class="submit-btn ease-btn-effe" 
            @click="submitBankTransfer"
            src="../../assets/recharge/company-sub-btn.png" />
        </div>
        <div v-else-if="currMenu.id == 1" class="m-w-page">
          <div class="top-ys vip"></div>
          <div class="flex vip-container">
            <div class="vip-way-item flex"
              v-for="charge of currMenu.chargeList">
              <div class="flex" style="height:.38rem">
                <div class="icon-wrap">
                </div>
                <div class="account-info">
                  <div>
                    {{charge.webchat_name}}
                  </div>
                  <input style="padding:0;height:.18rem;"
                    readonly v-model="charge.webchat_accounts" />
                </div>
              </div>
              <div>
                <img @click="vipRechargeClick(charge)" 
                  class="recharge-btn" 
                  src="../../assets/recharge/recharge-min-btn.png" />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="currMenu.id == 46" class="m-w-page">
          <div class="top-ys"></div>
          <div class="b-girl">
            <div class="h-top">
              <img class="avatar" :src="currMenu.user.avatar" />
              <div class="t-bg flex">
                <div>
                  {{ currMenu.user.nickname }}
                </div>
                <div class="flex" style="align-items:center">
                  <div v-for="method of currMenu.pay_methods"
                    class="pay-way-wap">
                    <img :src="method.icon" style="width:.16rem" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="flex p-relative">
                <div class="oficial-rz">
                  官方认证
                </div>
                <div class="way-title" v-for="(method,i) of currMenu.pay_methods"
                  v-show="i < 2">
                  {{ method.title }}
                </div>
                <div class="recharge-min-btn ease-btn-effe">
                </div>
              </div>
              <div class="flex">
                <div class="way-rate">
                  ★★★★★{{currMenu.rate}}
                </div>
                <div class="comment-cnt">
                  月评价{{currMenu.comment_cnt}}+
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="m-w-page">
          <div class="flex h-row">
            <div class="label">线路选择</div>
            <div class="filed flex p-relative">
              <div class="line" :class="{select:chIndex == chanSelect}"
                v-for="(chan,chIndex) of payChannels">
                <div class="chan-name" @click.stop="channelSelect(chIndex)">
                  {{ chan.name }}
                </div>
                <div class="pop" v-if="chan.reminder"
                  :class="{'side-r':isSideRight(chIndex),
                  show:chIndex == chanSelect && showPop}">
                  <div class="arrow" 
                    :class="{'side-r':isSideRight(chIndex)}"></div>
                  <div class="pop-inner">
                    {{chan.reminder}}
                  </div>
                </div>
              </div>
              <div class="dowload-wallet-link" @click="downloadWlletLinkClick"
                v-show="currMenu.down_link">下载钱包</div>
            </div>
          </div>
          <div class="flex h-row">
            <div class="label">充值金额</div>
            <!-- <input v-model="inputJine" v-if="amountType != 1"
              :placeholder="`最少${min}元，最多${max}元`" 
              class="jine-input" type="number" /> -->
            
            <form autocomplete="off">
              <input autocomplete="false" name="hidden" style="display:none;">
              <input v-model="inputJine" v-if="amountType != 1"
                :placeholder="`最少${min}元，最多${max}元`" 
                class="jine-input" type="number" />
            </form>

            <div class="amount-select" v-if="amountType == 1">
              {{inputJine}}
            </div>
            <div class="amount-select-des" v-if="amountType == 1">请选择以下金额</div>
            <div v-if="inputJine && amountType != 1" @click="inputJine=''"
              class="clear-input"></div>
          </div>
          <div v-show="isDigitalWallet && usdtRmbRatio" 
            style="font-size:.12rem !important">
            需转入数量
            <span style="color:#ff7700">
              {{(inputJine / usdtRmbRatio).toFixed(2)}}
            </span>
            USDT,
            当前汇率
            <a style="color:#00ff00">{{usdtRmbRatio}}</a>.
            <a href="javascript:void(0)" 
              style="color:#ff9900"
              @click="refreshUsdtRatio">
              刷新汇率
            </a>
          </div>
          <div class="quickly-jine flex"
            :class="{wallet:isDigitalWallet}">
            <div class="ease-btn-effe" v-for="(qJine,i) of quicklyJine" 
              @click="quickJineClick(qJine)">
              {{qJine}}元
            </div>
          </div>
          <img @click="rechargeBtnClick" class="recharge-btn ease-btn-effe"
            src="../../assets/recharge/recharge-btn.png" />
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import {mapMutations} from 'vuex'
import PopWidget from '../common/PopWidget.vue'
import Confirm from '../common/Confirm.vue'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import RechargeRecord from './RechargeRecord.vue'
import UserInfo from '@/common/UserInfo'

export default{
  props:{
    isShow:Number,
  },
  data(){
    return{
      showConfirmPayUrl:0,
      selectI:0,
      chanSelect:0,
      isShowPopWidget:0,
      menuList:[],
      quicklyJine:[],
      inputJine:'',
      payChannels:[],
      showPop:0,
      recordShow:0,
      isShowVipPopWidget:0,
      currMenu:{},
      vipWayAccount:'',
      bankSelectIndex:0,
      bankAccountInfo:{},
      promoInfo:{},
      rechargeAmount:'',
      payerName:'',
      userInfoData:'',
      payUrl:'',
      isDigitalWallet:0,
      usdtRmbRatio:0,
    }
  },
  mounted(){
    window.addEventListener('click',() => {
      this.showPop = 0;
    });

    UserInfo.getUserInfoData(userInfo => {
      this.userInfoData = userInfo;
    })
  },
  methods:{
    ...mapMutations([
      'popMsg',
      'showReporting',
      'showLoading',
      'closeLoading'
    ]),
    confirmHandle(){
      if(this.payUrl){
        window.open(this.payUrl);
        this.showConfirmPayUrl = 0;
      }
    },
    downloadWlletLinkClick(){
      let link = this.currMenu.down_link;
      if(link){
        let url = link;
        if(link.indexOf('http:') < 0 && link.indexOf('https:') < 0){
          let f = '';
          if(location.protocol.indexOf('//') < 0){
            f = '//';
          }
          url = location.protocol + f + link;
        }
        window.open(url)
      }
    },
    bankNameCopyClick(){
      if(location.href.indexOf('http://') > -1){
        this.popMsg('复制功能需要在安全环境使用!')
        return;
      }
      const ele = this.$refs.bankUserNameWrap;
      Tool.copy(ele)
      this.popMsg('复制成功')
    },
    bankAccountCopyClick(){
      if(location.href.indexOf('http://') > -1){
        this.popMsg('复制功能需要在安全环境使用!')
        return;
      }

      const ele = this.$refs.bankAccountWrap;
      Tool.copy(ele)
      this.popMsg('复制成功')
    },
    clearInBanckInfo(){
      this.payerName = '';
      this.rechargeAmount = '';
    },
    submitBankTransfer(){
      const {bank_username,bank_account,bank_name,min,max} = this.bankAccountInfo.extra;

      if(!this.rechargeAmount){
        this.popMsg(`请输入最小${min}元，最大${max}元`)
        return
      }
      if(1*this.rechargeAmount < 1*min || 1*max < 1*this.rechargeAmount){
        this.popMsg(`请输入最小${min}元，最大${max}元`)
        return
      }
      if(!this.payerName || !this.payerName.trim()){
        this.popMsg('请输入付款人姓名')
        return
      }

      const param = {
        bank_username,     //收款账户姓名
        bank_account,      //收款账户
        bank_name,         //收款银行
        gath_username:this.payerName,     //入款账户姓名
        gath_account:'',                  //入款账户
        gath_name:'',                     //入款银行。是微信还是支付宝
        amount:this.rechargeAmount,       //入款金额
        type:1                            //公司入款类型
      };
      this.showLoading()
      Request.post('/pay/transfer',param).then(res => {
        this.closeLoading()
        if(res.data.code == 0){
          this.clearInBanckInfo();
          this.popMsg('操作成功！')
        }
      }).catch((err) => {
        if(err.response.data && err.response.data.msg){
          this.popMsg(err.response.data.msg)
        }
        this.closeLoading()
      })
    },
    getPromoInfo(callback){
      let localPromo =  null// Tool.getPromLocalInfo();
      if(localPromo){
        this.promoInfo = localPromo;
        if(callback)callback()
        return;
      }
      Request.post('/promo/info').then(res => {
        if(res.data.data){
          this.promoInfo = res.data.data;
          Tool.setPromLocalInfo(res.data.data);
          if(callback)callback()
        }else{
          this.promoInfo = {}
        }
      })
    },
    banckTabClick(i){
      if(!this.currMenu.chargeList) return;
      this.bankSelectIndex = i;
      this.bankAccountInfo = this.currMenu.chargeList[i];
      this.clearInBanckInfo();
    },
    copyBtnClick(){
      Tool.copy(this.$refs.accountVipInfo);
      setTimeout(() => {
        Tool.openWechat()
        this.popMsg('复制成功')
      })
    },
    vipRechargeClick(charge){
      this.vipWayAccount = charge.webchat_accounts;
      this.isShowVipPopWidget = Math.random();
    },
    recordShowClick(){
      this.recordShow = Math.random()
    },
    channelSelect(chIndex,chageMenu){
      if(this.chanSelect == chIndex){
        this.showPop = this.showPop ? 0 : 1;
      }else{
        this.showPop = 1;
        this.inputJine = '';
      }
      if(chageMenu){
        this.showPop = 0;
      }
      this.chanSelect = chIndex;
      this.getQuicklyAmount();
    },
    reportingClick(){
      this.showReporting()
    },
    rechargeBtnClick(){
      if(!this.inputJine){
        this.popMsg(`请${this.amountType == 1?'选择':'输入'}充值金额`)
        return;
      }
      let iJine = 1 * this.inputJine;
      if(this.amountType != 1 && (iJine < 1 * this.min || 1 * this.max < iJine)){
        this.popMsg(`最少${this.min}元，最多${this.max}元`)
        return;
      }
      let third_id = this.payChannels[this.chanSelect].third_id
      let set_id = this.payChannels[this.chanSelect].set_id
      let way_id = this.payChannels[this.chanSelect].way_id
      let pay_code = this.payChannels[this.chanSelect].code
      
      let p = {
        amount:this.inputJine,
        way_id,
        third_id,
        pay_code,
        set_id,
      }
      this.showLoading();
      Request.post('/pay/pay',p).then(res => {
        this.closeLoading();
        let payModel = res.data.data;
        if(['url','html'].includes(payModel.mode)){
          this.payUrl = payModel.content;
          this.showConfirmPayUrl = Math.random();          
        }else if(payModel.mode == 'alisdk'){
        }
      }).catch(error => {
        if(error.response && error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg)
        }
        this.closeLoading();
      })
    },
    refreshUsdtRatio(){
      this.showLoading()
      this.getUsdtRate(() => {
        this.closeLoading()
      })
    },
    getUsdtRate(callback){
      Request.post('/pub/usdt_rate').then(res => {
        if(res.data && res.data.data){
          this.usdtRmbRatio = res.data.data.cny;
        }
        if(callback) callback()
      }).catch(() => {
        if(callback) callback()
      })
    },
    getPayWayList(callback){
      let dataHanle = () => {
        this.menuChange(0,this.menuList[0])
        callback();
      }
      //第三方充值
      let localPayWays = null
      if(process.env.NODE_ENV == 'development'){
        localPayWays = Tool.getPayWays();
      }
      if(localPayWays){
        this.menuList = localPayWays;
        dataHanle()
      }else{
        this.showLoading();
        Request.get('/pay/code').then(res => {
          this.closeLoading();
          let data = res.data.data[0].pay_ways;
          if(data && data.length){
            Tool.setPayWays(data);
            this.menuList = data;
            dataHanle()
          }else{
            this.isShowPopWidget = 0;
            this.popMsg('当前暂无可用充值通道，请联系客服')
          }
        }).catch(() => {
          this.closeLoading();
          dataHanle()
        })
      }
    },
    getBankPayList(){
      const dataAlanist = data => {
        if(data[0]){
          let yins = {
            name:'专享闪付',
            ...data[0]
          }
          this.menuList.unshift(yins);
          this.menuChange(0,this.menuList[0])
        }
      };
      //银行卡充值
      let brList = null // Tool.getBankRechargeList()
      if(brList){
        dataAlanist(brList)
      }else{
        let pa = {plat_name:'hy_play'}
        Request.get('/api/bankers',pa,'is-recharge').then(res => {
          if(res.data && res.data.length){
            Tool.setBankRechargeList(res.data);
            dataAlanist(res.data)
          }
        });
      }
    },
    getVipRecharge(callback){
      const dataAlanist = data => {
        if(data[0]){
          let yins = {
            name:'快捷充值',
            chargeList:data,
            id:data[0].id
          }
          this.menuList.unshift(yins);
          this.menuChange(0,yins)
        }
      };
      let brList = null// Tool.getKuaijieRecharge()
      if(brList && brList.length){
        dataAlanist(brList)
        callback();
      }else{
        Request.get('/pay/vipchat').then(res => {
          if(res.data.data){
            Tool.setKuaijieRecharge(res.data.data)
            dataAlanist(res.data.data)
          }
          callback();
        }).catch(() => {
          callback();
        })
      }
    },
    getCompanyRecharge(callback){
      const dataAlanist = data => {
        if(data[0]){
          let yins = {
            name:data[0].name,
            chargeList:data[0].data,
            id:data[0].code // UNIONWAP
          }
          this.menuList.unshift(yins);
          this.menuChange(0,yins)
        }
      };
      let brList = null
      if(process.env.NODE_ENV == 'development'){
        brList = Tool.getCompanyRecharge()
      }
      if(brList){
        dataAlanist(brList)
        callback();
      }else{
        Request.get('/pay/company').then(res => {
          if(res.data.data && res.data.data[0]){
            Tool.setCompanyRecharge(res.data.data)
            dataAlanist(res.data.data)
          }
          callback();
        }).catch(() => {
          callback();
        })
      }
    },
    getPayChannel(){
      this.payChannels = this.menuList[this.selectI].pay_channels;
      if(this.payChannels){
        this.channelSelect(0,'chage-menu')
        this.getQuicklyAmount();
      }
    },
    getQuicklyAmount(){
      if(!this.payChannels) return;
      let channel = this.payChannels[this.chanSelect];
      if(channel){
        let amounts = channel.fixed_amounts;
        if(amounts){
          this.quicklyJine = amounts.split('|');
          this.inputJine = '';
        }
        this.isDigitalWallet = channel.is_wallet;
      }
    },
    menuChange(i,menu){
      this.currMenu = menu;
      // this.currMenu.id = 1;
      // this.currMenu.id = 'UNIONWAP';
      this.selectI = i;
      this.getPayChannel();

      if(menu.id == 'UNIONWAP'){
        this.banckTabClick(0)
      }

      this.clearInBanckInfo();
    },
    quickJineClick(jine){
      this.inputJine = jine;
    },
    isSideRight(index){
      return (index+1) % 5 == 0;
    }
  },
  watch:{
    isShow(n){
      const _this = this;
      this.isShowPopWidget = n;
      this.getUsdtRate();

      _this.menuList = [];
      _this.getPayWayList(() => {
        _this.getCompanyRecharge(() => {
          _this.getVipRecharge(() => {
            //专享闪付 暂时不上
            //this.getBankPayList();
          });
        });
      });
      this.getPromoInfo()
    }
  },
  computed:{
    min(){
      if(this.payChannels && this.payChannels.length){
        return this.payChannels[this.chanSelect].min
      }
      return 0
    },
    max(){
      if(this.payChannels && this.payChannels.length){
        return this.payChannels[this.chanSelect].max
      }
      return 0
    },
    //1表示只能选择金额，否则可以填写金额
    amountType(){
      if(this.payChannels && this.payChannels.length){
        return this.payChannels[this.chanSelect].amount_type
      }
      return 0
    }
  },
  components:{
    PopWidget,
    RechargeRecord,
    Confirm
  }
}
</script>
<style scoped lang="stylus">
.confirm-wrapper
  a
    color yellow
.recharge-record-btn
  position absolute
  right .6rem
  top .1rem
  text-decoration underline
.confimr-btn
  width .8rem
  height .26rem
  display block
  font-size .12rem
  line-height .26rem
  border-radius .16rem
  text-align center
  background-image url(../../assets/cancel-btn.png)
  background-size 100% 100%
  &:nth-child(2)
    margin-left .1rem
    background-image url(../../assets/confirm-btn.png)
.reporting-btn
  width .84rem
  margin-top .12rem
  position absolute
.main-content
  width 100%
  height auto
  &.copy
    padding .28rem 0
  .des-wrap
    font-size .12rem
    padding .01rem
  & > .flex
    justify-content center
  .copy-open-wechat-btn
    width 1.3rem
    margin .2rem 0 .2rem 0
  .icon-wrap1
    width .38rem
    height .38rem
    background-image url(../../assets/recharge/vip-r-ico.png)
    background-size 100% 100%
  .account-info1
    width 1.48rem
    height .38rem
    margin-left .08rem
    padding-left .1rem
    line-height .38rem
    color #ffffff
    text-align left
    background-color #404040
    border-radius .06rem
  .menu-wrap
    width 1.95rem
    height 2.55rem
    overflow auto
    z-index 1
    position relative
    &::-webkit-scrollbar
      width 0
    .inner-back
      width 1.3rem
      min-height 100%
      height auto
      box-shadow 0 0 10px 0 rgba(0,0,0,.6)
      padding-bottom .3rem
      border-radius .05rem 0 0 .05rem
    .menu-wrap-scroll
      width 100%
      height auto
      padding-top .04rem
      .menu-btn
        width 1.2rem
        height .37rem
        display block
        margin 0 0 .06rem .05rem
        position relative
        .zengsong-p-wrapper
          width .24rem
          height .3rem
          background-image url(../../assets/recharge/zengsong-percent.png)
          background-repeat no-repeat
          background-size 100% 100%
          font-size .12rem
          color #fff
          display flex
          justify-content flex-end
          align-items flex-end
        .normal-bg
          width 1.2rem
          height 100%
          background-image url(../../assets/recharge/recharge-menu.png)
          background-repeat no-repeat
          background-size 100% 100%
        .yinshang-ico
          position absolute
          right .11rem
          top -.02rem
          width .24rem
          height .3rem
          .huobao-ico
            left .04rem
            top 0
        .huobao-ico
          width .18rem
          right .14rem
          top -.06rem
          position absolute
          z-index 2
        .select-bg
          width 100%
          height 100%
          background-image url(../../assets/recharge/recharge-menu1.png)
          background-repeat no-repeat
          background-size 100% 100%
        .menu-text
          width 1.2rem
          height 100%
          line-height .35rem
          text-align center
          font-size .14rem
          color #F0DFCF
          position absolute
          z-index 1
          top 0
          left 0
          &.focus
            color #573B09
  .m-w-page
    width 6.15rem      
    height 2.46rem
    padding .1rem 0 0 .1rem
    margin-left -.05rem
    background-repeat no-repeat
    background-size 100% 100%
    overflow auto
    position relative
    .bank-remark
      width 3.68rem
      margin-top .16rem
      font-size .12rem
      color #8f8f8f
    .submit-btn
      width .88rem
      position absolute
      right .2rem
      bottom .5rem
    .bank-r-info
      margin-top .04rem
      align-items flex-start
      &.f
        margin-top .1rem
      .copy-button
        display block
        width .58rem
        margin-top -.03rem
      input
        width 2.1rem
        height .3rem
        padding-left .04rem
        border-radius .05rem
        background #282627
    .line-choicer
      width .9rem
      height .43rem
      line-height .43rem
    .label-bank-info
      width .8rem
      display flex
      justify-content flex-end
      align-items center
      &.h30
        height .3rem
    .back-username-wrap
      width 2.69rem !important
      font-size .14rem !important
      height .2rem !important;
      background none !important;
      text-align left
    .bank-item-wrap
      flex-wrap wrap
    .bank-se-item
      width auto
      height .43rem
      line-height 1
      border solid 1px #855732
      border-radius .06rem
      padding 0 .08rem
      justify-content center
      align-items center
      margin 0 .1rem 0 0
      background #262324
      color #6D6B6B
      flex-shrink 0
      &.selected
        background #44382E
        color #CABEC1
    .vip-container
      margin-top .1rem
      width 4.18rem
      flex-wrap wrap
      justify-content space-between
    .vip-way-item
      width 2rem
      height .5rem
      margin-bottom .08rem
      flex-shrink 0
      padding 0 .12rem
      justify-content space-between
      align-items center
      border-radius .06rem
      background-color #343434
      .recharge-btn
        width .68rem
        height .26rem
        margin-top 0
      .icon-wrap
        width .38rem
        height .38rem
        background-image url(../../assets/recharge/vip-r-ico.png)
        background-size 100% 100%
      .account-info
        text-align left
        color #FCE2A4
        margin-left .06rem
        input
          width .65rem
          background none
    .top-ys
      width 4.2rem
      height .47rem
      background-size 100% 100%
      background-image url(../../assets/recharge/coin-s-top.png)
      &.vip
        background-image url(../../assets/recharge/vip-s-top.png)
    .b-girl
      width 4.2rem
      border-radius .05rem
      box-shadow 0 0 .05rem 0 rgba(0,0,0,.4)
      margin-top .07rem
      background-color #F4E3B7
      .oficial-rz
        width .7rem
        height .22rem
        margin .05rem .08rem .05rem .12rem
        flex-shrink 0
        line-height .22rem
        border-radius .1rem
        color #fff
        font-size .12rem
        background-image linear-gradient(#6BE824,#57CA17,#57AB19)
      .way-title
        margin .05rem .1rem .05rem 0
        border-radius .1rem
        flex-shrink 0
        color #fff
        padding 0 .07rem
        height .22rem
        line-height .22rem
        background-image linear-gradient(#E5C18A,#C59154,#986E31)
      .recharge-min-btn
        width .84rem;
        flex-shrink: 0;
        height .36rem;
        position absolute;
        top -.04rem
        right .02rem
        background-image url(../../assets/recharge/recharge-min-btn.png)
        background-size 100% 100%
      .way-rate
        margin-left .08rem
        letter-spacing .02rem
        color #B96601
      .comment-cnt
        font-weight bold
        font-size .12rem
        color #000
        margin-left .2rem
      .h-top
        width 100%
        height .38rem
        position relative
        .t-bg
          width 100%
          height .23rem
          padding-left .45rem
          color #000
          font-weight bold
          font-size .12rem
          background-color #EAC89F
          justify-content space-between
          align-items center
          .pay-way-wap
            height .17rem
            margin-right .08rem
            border-radius 50%
            overflow hidden
        .avatar
          width .35rem
          height .35rem
          border-radius 50%
          left .06rem
          top .03rem
          position absolute
    .quickly-jine
      width 3.38rem
      margin .15rem auto 0 auto
      flex-wrap wrap
      &.wallet
        margin-top .05rem
      & > div
        width .7rem
        height .35rem
        flex-shrink 0
        line-height .35rem
        text-align center
        background-image url(../../assets/recharge/quick-value.png)
        background-size 100% 100%
        color #FFF8FF
        font-size .16rem
        margin 0 .14rem .05rem 0
    .recharge-btn
      width .88rem
      margin-top .1rem
    .h-row
      margin .05rem 0
      position relative
      .label
        margin-right .04rem
        width .89rem
        padding-top .05rem
      .jine-input
        width 3rem
        height .35rem
        padding 0 .05rem
        border-radius .05rem
        font-size .16rem
        background #282627
      .amount-select
        width 1.38rem
        height .35rem
        color #ffedde
        line-height .35rem
        padding 0 .05rem
        border-radius .05rem
        font-size .16rem
        text-align left
      .amount-select-des
        line-height .35rem
        padding-left .05rem
        font-size .12rem
      .clear-input
        width .22rem
        height .22rem
        border-radius 50%
        position absolute
        right .45rem
        top .06rem
        background-image url(../../assets/recharge/input-clear-ico.png)
        background-size 100% 100%
    .filed
      width 2.7rem
      height auto
      flex-wrap wrap
      .line
        padding 0 .06rem
        width auto
        height .3rem
        margin-right .08rem
        margin-bottom .04rem
        text-align center
        line-height .3rem
        font-size .12rem
        border-radius .05rem
        box-shadow 0 0 5px 0 rgba(0,0,0,.5) inset
        background #262425
        color #6F6D6E
        flex-shrink 0
        position relative
        .chan-name
          width 100%
          height 100%
        &.select
          background #44372E
          color #d4c7d0
        .pop
          top .15rem
          left 50%
          z-index 2
          position absolute
          font-size .12rem
          line-height .16rem
          background #FFFFFF
          color #946931
          width 1.56rem
          padding .05rem 0
          border-radius .04rem
          text-align left
          box-shadow 0 0 4px 0 rgba(0,0,0,.4)
          transform-origin 50% 0
          transform translateX(-50%) scale(.0001)
          transition transform .3s,top .1s
          &.side-r
            left -15%
          &.show
            top .36rem
            transform translateX(-50%) scale(1)
          .arrow
            transform translateX(-50%)
            left 50%
            top -.15rem
            position absolute
            width 0
            height 0
            border .08rem solid transparent;
            border-bottom-color #ffffff
            &.side-r
              left 72%
          .pop-inner
            width 100%
            max-height 1.35rem
            overflow auto
            padding 0 .05rem
      .dowload-wallet-link
        color blue
        position absolute
        right -.57rem
        height .34rem
        line-height .34rem
</style>