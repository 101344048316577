<template>
  <PopWidget :isShow="show">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/tuiguang/dir-member-title.png" />
    </template>
    <template v-slot:content>
      <div class="data-table">
        <div class="flex data-row head">
          <div class="ceil">昵称</div>
          <div class="ceil">总贡献返利</div>
          <div class="ceil">最后登录时间</div>
        </div>
        <div class="data-scroller">
          <div class="flex data-row" v-for="item of dataRecords">
            <div class="ceil">{{ item.nickname }}</div>
            <div class="ceil">{{item.amount}}</div>
            <div class="ceil">{{item.loginTime}}</div>
          </div>
          <div class="nodata flex" v-if="!dataRecords || !dataRecords.length">
            <img class="no-data-ico" src="../../assets/tuiguang/no-data.png" />
            <div>暂无数据</div>
          </div>
        </div>
      </div>
    </template>
  </PopWidget>
</template>

<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import { mapMutations } from 'vuex'

export default{
  props:{
    show:Number
  },
  data(){
    return {
      dataRecords:[],
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading']),
    getDataRecord(){
      this.showLoading();
      Request.post('/agent/sum_members').then(res => {
        this.closeLoading();
        if(res.data && res.data.data){
          this.dataRecords = res.data.data;
        }
        //假数据
        (() => {
          let i = 0;
          while(i < 30){
            let data = {
              nickname:'n'+i,
              amount:(Math.random() * 2).toFixed(3),
              logiontime:new Date().getTime(),
            }
            let copied = JSON.parse(JSON.stringify(data));
            this.dataRecords.push(copied);
            i++;
          }
        })

      }).catch(() => {
        this.closeLoading();
      })
    }
  },
  watch:{
    show(){
      this.getDataRecord();
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style lang="stylus" scoped>
.data-table
  width 100%
  font-size .12rem
  .data-scroller
    width 100%
    height 1.48rem
    overflow auto
    .nodata
      width 100%
      padding .53rem 0
      flex-direction column
      align-items center
      justify-content center
      .no-data-ico
        width .24rem
        margin-bottom .05rem
  .data-row
    align-items center
    height .3rem
    justify-content flex-start
    .ceil
      width 33.3%
    &.head
      font-size .14rem
      background-color #454545
      color #ffffff
</style>