<template>
  <PopWidget :isShow="isShowPopWidget" :isFull="1" width="4.68rem">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/rank-list/rank-list-title.png" />
      <img class="r-l-title"
        src="../../assets/rank-list/rank-l-title.png" />
    </template>
    <template v-slot:content>
      <div class="profit-list-wrapper">
        <div class="p-l-row head flex">
          <div>排名</div>
          <div>昵称</div>
          <div>获利金额</div>
          <img class="r-l-border" src="../../assets/rank-list/row-border.png" />
        </div>
        <div class="p-l-wscroller" 
          :class="{'in-rank-list':userOrderInfo.order!=0}">
          <div class="p-l-row row flex" v-for="(user,i) of userList">
            <div class="num-ico flex">
              <img v-if="i == 0" src="../../assets/tuiguang/list-ico1.png" />
              <img v-else-if="i == 1" src="../../assets/tuiguang/list-ico2.png" />
              <img v-else-if="i == 2" src="../../assets/tuiguang/list-ico3.png" />
              <img v-else src="../../assets/tuiguang/list-ico4.png" />
              <div class="num-wrap" v-if="i > 2">
                {{ i + 1 }}
              </div>
            </div>
            <div>{{ user.nickname }}</div>
            <div class="u-amount flex">
              <div>
                <img src="../../assets/duihuan/jine.png" />
                {{user.win_amount}}
              </div>
            </div>
            <img class="r-l-border" src="../../assets/rank-list/row-border.png" />
          </div>
        </div>
        <div class="p-l-row row flex self-w" 
          v-if="userOrderInfo.order == 0">
          <img class="r-l-border" src="../../assets/rank-list/row-border.png" />
          <div class="num-ico">
            未上榜
          </div>
          <div class="nick-name">{{userOrderInfo.nickname}}</div>
          <div class="u-amount flex">
            <div>
              <img src="../../assets/duihuan/jine.png" />
              {{userOrderInfo.win_amount}}
            </div>
          </div>
        </div>
        <div class="p-relative des-row">
          <img class="top-de-pic" src="../../assets/rank-list/head-border.png" />
          *玩家纯盈利排行榜，每日0点重置
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import { mapMutations } from 'vuex';
import Tool from '@/common/Tool';

export default{
  props:{
    show:Number,
  },
  data(){
    return{
      isShowPopWidget:0,
      userOrderInfo:{},
      userList:[],
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      this.getApiList();
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading']),
    getApiList(){
      let bInfo = Tool.getBonusInfo();
      if(bInfo){
        this.userOrderInfo = bInfo.info;
        this.userList = bInfo.list;
        return;
      }

      this.showLoading()
      Request.get('/user/bonusRecord').then(res => {
        this.closeLoading()
        if(res.data.data){
          if(res.data.data.info){
            this.userOrderInfo = res.data.data.info;
          }
          if(res.data.data.list){
            Tool.setBonusInfo(res.data.data);
            this.userList = res.data.data.list;
          }
        }
      }).catch(() => {
        this.closeLoading()
      })
    }
  },
  components:{
    PopWidget,
  }
}
</script>

<style scoped lang="stylus">
.r-l-title
  height 75%
  margin auto
  display block
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
.profit-list-wrapper
  width: 4.42rem;
  height: 2.1rem
  padding-bottom: .02rem;
  position:relative;
  .r-l-border
    width 100%
  .p-l-wscroller
    width 100%
    height 1.3rem
    overflow auto
    &.in-rank-list
      height 1.52rem
  .des-row
    width 100%
    height .26rem
    text-align left
    padding .06rem 0 0 .04rem
    color #9A9A9A
    font-size .12rem    
    .top-de-pic
      width 100%
      position absolute
      top 0
      left 0
  .p-l-row
    width 100%
    height .32rem
    align-items center
    justify-content flex-start
    font-size .12rem
    position relative
    &.self-w
      height .22rem
      background-image url(../../assets/rank-list/no-rank-bg.png)
      background-size 100% 100%
      .num-ico
        color #FFF7B6
      .nick-name
        color #e7e7e7
      .r-l-border
        top 0
        left 0
    & > div
      width 33.3%
      text-align center
      &.u-amount
        color #DAE09C
        font-size .16rem
        justify-content center
        align-items center
        & > div
          position relative
        img
          width .16rem
          position absolute
          left -.24rem
          top .01rem
    .num-ico
      justify-content center
      align-items center
      position relative
      img
        width .18rem
      .num-wrap
        color #ffffff
        text-align center
        width 100%
        position absolute
    &.head
      background-image linear-gradient(#45392A,#3E3025,#35291D,#36271A)
      div
        font-size .14rem
    &.row
      color #A8A8A8
    .r-l-border
      width 100%
      position absolute
      bottom 0
</style>