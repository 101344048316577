<template>
  <div class="duobao-w-back" v-show="showBack"
    @touchmove.stop="" @touchstart.stop="">
    <div class="duobao-wrapper">
      <div class="menu">
        <div class="m-i-item" v-for="(menu,m_i) of menuList"
          @click="menuItemClick(m_i,menu.id)">
          <div class="pic-wrap" :class="`p-${menu.id}`">
          </div>
          <div class="m-item-bet" :class="`p-${menu.id}`">
            {{ menu.bet?menu.bet+'积分':'' }}
          </div>
        </div>
      </div>
      <img class="back" src="../../assets/duobao/xinyun-bj.png" />
      <img @click="closeClick"
        class="close-btn" src="../../assets/duobao/close-btn.png" />
      <div class="d-b-c-wrapper flex">
        <div class="d-b-history-wp">
          <img class="d-deco" src="../../assets/duobao/duobao-decorate.png" />
          <img class="history-bg" src="../../assets/duobao/history-bg.png" />
          <div class="history-title">中奖历史记录</div>
          <div class="his-item-w" ref="historyScoller"
            @touchend="restartMarquee" @mouseleave="restartMarquee"
            @touchstart="stopMarquee=1" @mouseenter="stopMarquee=1">
            <div class="h-i-w-item" v-for="mq of marqueeList">
              <div class="c-r-user">
                <span class="user">恭喜[{{mq.nickname}}]</span>在
              </div>
              <div class="bonus-des">
                {{mq.game_name}}中获得<span>{{mq.win_amount}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="record">
          <div class="r-top-w">
            <img class="tab-bg" :class="{focus:tabSwitchI==1}"
              src="../../assets/duobao/bonus-tab.png" />
            <div class="r-t-i-w flex">
              <div :class="{focus:tabSwitchI==0}" 
                @click="recordTabSwitch(0)">
                大奖记录
              </div>
              <div :class="{focus:tabSwitchI==1}" 
                @click="recordTabSwitch(1)">
                个人记录
              </div>
            </div>
          </div>

          <div class="record-table" v-show="tabSwitchI == 0">
            <div class="r-t-h flex">
              <div>时间</div>
              <div>昵称</div>
              <div>转盘类型</div>
              <div>获奖</div>
            </div>
            <div class="record-w-scroll">
              <div class="r-t-w flex" v-for="num of prizeList">
                <div>{{ num.apply_date }}</div>
                <div>{{ num.nickname }}</div>
                <div>{{ num.activity_name }}</div>
                <div>{{ num.money }}</div>
              </div>
            </div>
          </div>

          <div class="record-table" v-show="tabSwitchI == 1">
            <div class="r-t-h flex">
              <div>时间</div>
              <div>昵称</div>
              <div>转盘类型</div>
              <div>获奖</div>
            </div>
            <div class="record-w-scroll">
              <div class="r-t-w flex" v-for="num of userApplies">
                <div>{{ num.apply_date }}</div>
                <div>{{ num.nickname }}</div>
                <div>{{ num.activity_name }}</div>
                <div>{{ num.money }}</div>
              </div>
            </div>
          </div>

          <img class="desk-table"
              src="../../assets/duobao/desk-table.png" />
          <div class="bet-total flex">
            <div class="label">
              今日有效下注:
            </div>
            <div class="today-bet-total">
              {{betInfo.bet_amount ? betInfo.bet_amount:0.0000}}
            </div>
            <div class="label curr">
              当前积分:
            </div>
            <div class="today-bet-total curr">
              {{userInfo.score?userInfo.score:0}}
            </div>
            <img class="score-help ease-btn-effe" 
              @click="tourntableHelp"
              src="../../assets/duobao/help-btn.png" />
          </div>
        </div>
      </div>
    </div>
    <Turntable :show="turnShow" @tourntableHelp="tourntableHelp"
      @turnClose="turnCloseClick()" 
      @showBindMobile="() => {closeClick(),$emit('showBindMobile')}" />
  </div>
</template>
<script>
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import Turntable from './Turntable.vue'

export default {
  props:{
    show:Number,
  },
  data(){
    return {
      showBack:0,
      menuList:[],
      focusIndex:-1,
      tabSwitchI:0,
      turnShow:0,
      prizeList:[],
      userApplies:[],
      marqueeList:[],
      stopMarquee:0,
      preMarqScrollTop:0,
      mqWay:0,
      betInfo:{},
      userInfo:{},
    }
  },
  methods:{
    tourntableHelp(){
      this.$emit('tourntableHelp')
    },
    getBetInfo(){
      Request.post('/user/bet_info').then(res => {
        this.betInfo = res.data.data;
      })
    },
    restartMarquee(){
      this.stopMarquee = 0;
      this.marqueeGo();
    },
    getMarquee(){
      let list = Tool.getMarqueeList();
      if(list){
        this.marqueeList = list;
        this.restartMarquee();
        return;
      }
      Request.post('/pub/marquee').then(res => {
        this.marqueeList = res.data.data;
        Tool.setMarqueeList(res.data.data);
        this.restartMarquee();
      });
    },
    marqueeGo(){
      let ele = this.$refs.historyScoller;
      let hz = 0;
      let run = () => {
        if(hz % 3 == 0){
          if(ele.scrollTop == 0){
            this.mqWay = 0
          }
          if(this.mqWay == 0){
            ele.scrollTop+=1;
          }else if(this.mqWay == 1){
            ele.scrollTop-=1;
          }
          if(this.preMarqScrollTop == ele.scrollTop){
            this.mqWay = 1
          }
          this.preMarqScrollTop = ele.scrollTop;
        }
        hz++;
        if(!this.stopMarquee){
          requestAnimationFrame(run)
        }
      };
      run();
    },
    getApplyList(){
      if(this.userApplies.length) return;
      Request.post('/user/user_activity_apply').then(res => {
        this.userApplies = res.data.data;
      });
    },
    getApplyPrize(){
      if(this.prizeList.length) return;
      Request.post('/activity/activity_apply_prize').then(res => {
        this.prizeList = res.data.data;
      });
    },
    recordTabSwitch(index){
      this.tabSwitchI = index;
      if(index == 1){
        this.getApplyList();
      }
    },
    dataHandle(list){
      this.menuList.forEach(menu => {
        list.forEach(resMenu => {
          if(menu.id == resMenu.id){
            menu.bet = resMenu.bet;
          }
        });
      });
    },
    getData(callback){
      let luckyList = Tool.getLuckyList()
      if(luckyList){
        setTimeout(() => {
          callback(luckyList)
        })
        return;
      }
      Request.get('/activity/luckyList').then(res => {
        Tool.setLuckyList(res.data.data);
        callback(res.data.data);
      })
    },
    menuItemClick(m_i,id){
      this.focusIndex = m_i;
      this.turnShow = id;
    },
    turnCloseClick(){
      this.turnShow = 0;
      this.userInfo = Tool.getLocalUserinfo()
    },
    menuDataInit(){
      this.menuList = [
        {
          id:36,
          bet:'',
        },
        {
          id:40,
          bet:'',
        },
        {
          id:41,
          bet:'',
        }
      ];
    },
    closeClick(){
      this.showBack = 0
      this.stopMarquee = 1;
    }
  },
  watch:{
    show(n){
      if(n){
        this.showBack = n;
        this.getData(this.dataHandle);
        this.getMarquee();
        this.getApplyPrize();
        this.menuDataInit();
        this.getBetInfo();
        this.userInfo = Tool.getLocalUserinfo()
      }else{
        this.showBack = 0;
      }
    }
  },
  components:{
    Turntable
  }
}
</script>
<style scoped lang="stylus">
.duobao-w-back
  width 100%
  height 100%
  position absolute
  background-color rgba(0,0,0,.4)
  top 0
  left 0
  z-index 3
.duobao-wrapper
  position absolute
  z-index 2
  top 50%
  left 50%
  transform translateX(-50%) translateY(-52%)
  .d-b-c-wrapper
    width 79%
    height 2.06rem
    position absolute
    top .6rem
    left .56rem
    .d-b-history-wp
      position relative
      margin-top .12rem
      margin-left .06rem
      .d-deco
        width .44rem
        display block
        position absolute
        z-index 2
        bottom -.03rem
        left: -.16rem
      .history-bg
        width 1.72rem
      .history-title
        width 100%
        text-align center
        position absolute
        color #ffffff
        top .1rem
        left 0
      .his-item-w
        width 80%
        height 1.36rem
        overflow auto
        position absolute
        top .35rem
        left .17rem
        .h-i-w-item
          width 82%
          padding .05rem 0
          font-size .1rem
          margin 0 auto
          border-bottom 1px solid rgba(0,0,0,.4)
          color #9D6890
          .c-r-user
            font-size .1rem
          .bonus-des
            width 1.09rem
            span
              color red
          .user
            color #108729
    .record
      width 2.34rem
      height auto
      .record-table
        width 100%
        height auto
        margin-top .05rem
        text-align center
        border-radius .05rem
        overflow hidden
        position relative
        z-index 1
        .r-t-h
          width 100%
          height .26rem
          background-color #F7EDFD
          & > div
            width 25%
            line-height .26rem
            color #76417A
            font-size .12rem
            font-weight bold
            &:nth-child(1)
              width 27%
            &:nth-child(2)
              width 27%
            &:nth-child(3)
              width 30%
        .record-w-scroll
          width 100%
          height .9rem
          overflow auto
        .r-t-w
          font-size .12rem
          background-color #E4D8E4
          & > div
            width 25%
            height .25rem
            line-height .25rem
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
            color #4E3C57
            &:nth-child(1)
              width 27%
            &:nth-child(2)
              width 27%
              text-align left
              padding-left .04rem
            &:nth-child(4)
              width 19%
      .desk-table
        width 2.8rem
        position relative
        top -.23rem
        left -.22rem
      .bet-total
        width 2.48rem
        height .28rem
        align-items center
        background-color #521775
        position relative
        top -.32rem
        left -.16rem
        z-index 2
        border-radius .14rem
        border 1px solid #8742A8
        padding-left .1rem
        .label
          font-size .12rem
          color #E4AEED
          &.curr
            margin-left .07rem
        .today-bet-total
          margin-left .05em
          color #039506
          &.curr
            width .5rem
        .score-help
          width .27rem
          display block
          position absolute
          right -.17rem
          top 0
      .r-top-w
        width 1.42rem
        height .25rem
        position relative
        margin auto
        border-radius .16rem
        background-color #4E1A73
        box-shadow 0 0 .05rem 0 rgba(0,0,0,.8) inset
        .tab-bg
          width 50%
          height 100%
          display block
          transition margin-left .2s
          &.focus
            margin-left .7rem
        .r-t-i-w
          width 100%
          height 100%
          position absolute
          z-index 1
          top 0
          left 0
          &>div
            font-size .12rem
            line-height .25rem
            height 100%
            width 50%
            color #fff
            text-align center
            &.focus
              font-weight bold
              color #5A0703
  .back
    width 5.34rem
  .close-btn
    width .34rem
    position absolute
    right .14rem
    top .14rem
  .menu
    width .66rem
    height auto
    position absolute
    left -.17rem
    top .9rem
    img
      display block
      width 100%
    .m-i-item
      width 100%
      height .42rem
      margin-bottom .08rem
      height auto
      position relative
      .pic-wrap
        background-size 100% 100%
        background-repeat no-repeat
        &.p-36
          width .66rem
          height .45rem
          background-image url(../../assets/duobao/duobao-m-btn1.png)
        &.p-40
          width .66rem
          height .42rem
          background-image url(../../assets/duobao/duobao-m-btn2.png)
        &.p-41
          width .66rem
          height .45rem
          background-image url(../../assets/duobao/duobao-m-btn3.png)
      .m-item-bet
        font-size .12rem
        text-align center
        color #fff
        left 0
        padding-left .07rem
        bottom .02rem
        width 100%
        position absolute
        &.p-41
          padding-left .02rem
</style>