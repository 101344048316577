<template>
  <div class="report-wrap">
    <img class="decorate" src="../../assets/geren/ctitle-report.png" />
    <div class="condition-wraper flex">
      <div class="flex selector-f">
        <div class="label">时间：</div>
        <Selector defaultTxt="选择时间" style="width:.72rem"
          :dataList="dateList" @change="dateSelecChange"
          :defaultIndex="0" menuHeight=".5rem" />
      </div>
      <img class="query-btn ease-btn-effe" @click="queryBtnClick"
        src="../../assets/geren/query-btn.png" />
    </div>
    <div class="user-report-w flex" :style="{height:`${tableDivHeight}px`}">
      <div class="user-report-item" v-for="item of reportItems">
        <div class="value">{{ item[1] }}</div>
        <div class="title">{{ item[0] }}</div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import Selector from '../common/Selector.vue'
export default{
  data(){
    return{
      dateList:[
        {id:'today',name:'今天'},
        {id:'yesterday',name:'昨天'},
      ],
      tableDivHeight:0,
      dateSelectIndex:0,
      reportItems:[],
    }
  },
  mounted(){
    this.getTableHeight();
    window.addEventListener('resize',() => {
      this.getTableHeight();
    });
    this.getData();
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading']),
    queryBtnClick(){
      this.getData()
    },
    dateSelecChange(e){
      this.dateSelectIndex = e;
    },
    getTableHeight(){
      let m = innerHeight > innerWidth ? innerWidth : innerHeight
      let topPx = Tool.remToPx(1.23)
      this.tableDivHeight = m - topPx
    },
    getData(){
      let date = this.dateList[this.dateSelectIndex].id;
      let p = {date};
      this.showLoading()
      Request.post('/user/report',p).then(res => {
        this.closeLoading()
        this.reportItems = res.data.data;
      }).catch(() => {
        this.closeLoading()
      })
    },
  },
  components:{
    Selector
  }
}
</script>
<style scoped lang="stylus">
.report-wrap
  width 100%
  .decorate
    width 70%
    margin-left .5rem
.condition-wraper
  width 92%
  font-size .12rem
  margin .1rem auto 0 auto
  & /deep/ .p-selector
    display flex
    align-items center
    line-height 1
  .selector-f
    align-items center
    .label
      font-weight bold
  .query-btn
    width .6rem
    margin-left .3rem
    display block
.user-report-w
  width 92%
  overflow auto
  justify-content start
  flex-wrap wrap
  align-content start
  border-radius .12rem .12rem 0 0
  margin .1rem auto 0 auto
  padding 0 0 .2rem .27rem
  .user-report-item
    width 1.06rem
    height .72rem
    margin .12rem .11rem 0 .11rem
    background-image url(../../assets/geren/user-report-itembg.png)
    background-size 100% 100%
    & > div
      width 100%
      text-align center
      font-size .14rem
    .value
      color #ffffff
      height .46rem
      line-height .46rem
      font-size .2rem
    .title
      color #281D0E
      height .25rem
      line-height .29rem
</style>