<template>
  <div class="activity-content">
    <div class="ac-c-inner" v-if="htmlContent" v-html="htmlContent">
    </div>
  </div>
  <div class="menu-wrap">
    <div class="menu-wrap-scroll flex">
      <div :key="menu.id" class="menu-btn"
        v-for="(menu,i) of menuList" :data-id="menu.id"
        @click="menuClick(i)" 
        :class="{select:menuSelectIndex==i}">
        <div class="menu-text">
          {{ menu.title}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    mList:Array,
  },
  data(){
    return {
      htmlContent:'',
      menuList:[],
      menuSelectIndex:0
    }
  },
  methods:{
    menuClick(i){
      this.menuSelectIndex = i;
      this.htmlContent = this.menuList[this.menuSelectIndex].mobile_content;
    }
  },
  watch:{
    'mList.length'(){
      this.menuList = this.mList;
      this.menuClick(0)
    }
  }
}
</script>

<style scoped lang="stylus">
.activity-content
  width 3.99rem
  height 2.28rem
  overflow auto
  position relative
  .kuisun-jiuyuan
    background white
    width auto
    height .25rem
    padding 0 .2rem
    border-radius .05rem
    line-height .25rem
    position absolute
    right .1rem
    bottom .1rem
  .ac-c-inner
    width 100%
    padding .06rem

.menu-wrap
  width 1.24rem
  height 2.28rem
  position relative
  z-index 1
  &::-webkit-scrollbar
    width 0
  .menu-wrap-scroll
    width 1.24rem
    height 100%
    position absolute
    overflow auto
    top 0
    right 0
    flex-direction column
    align-items flex-end
    padding .12rem 0
    .menu-btn
      width 100%
      height .35rem
      flex-shrink 0
      margin-bottom .04rem
      position relative
      background-image url(../../assets/activity/act-menu-s-btn.png)
      background-size 100%  100%
      &.select
        background-image url(../../assets/activity/act-menu-se-btn.png)
      .menu-text
        width 100%
        height 100%
        color #fff
        text-shadow 0 0 4px #000000
        position absolute
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        font-size .12rem
        line-height .35rem
        z-index 1
</style>