<template>
  <div class="rank-privilege">
    <img class="decorate" src="../../assets/geren/ctitle-vip.png" />
    <div class="h-wrapper" v-html="content">
    </div>
  </div>
</template>
<script>
import Tool from '../../common/Tool.js'
import Request from '../../common/Request.js'
import { mapMutations } from 'vuex';
export default {
  data(){
    return {content:''}
  },
  methods:{
    ...mapMutations(['popMsg']),
    getUserGrateInfo(callback){
      let localGradeInfo = Tool.getGradeInfo();
      if(localGradeInfo){
        this.content = localGradeInfo.description;
        return;
      }
      Request.post(`/user/grade_info`).then(res => {
        if(res.data && res.data.data){
          Tool.setGradeInfo(res.data.data)
          this.content = res.data.data.description;
        }
      }).catch(erro => {
        Request.userOutted(this)
        // this.$router.push({name:'login'})
      });
    },
  },
  mounted(){
    this.getUserGrateInfo();
  }
}
</script>
<style lang="stylus">
.h-wrapper
  p
    width 100%
    img
      width 100%
      display block
</style>
<style scoped lang="stylus">
.rank-privilege
  width 100%
  padding-bottom .5rem
  .decorate
    width 70%
    margin-left .5rem
  .h-wrapper
    width 95%
    margin 0 auto
</style>