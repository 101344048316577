import Tool from './Tool.js'
import Request from './Request.js'

export default{
  getUserInfoData(callback,isUpdate){
    let token = Tool.getApiToken();
    if(!token){
      if(callback) callback(null);
      return;
    }
    let localUserInfo = Tool.getLocalUserinfo();
    if(localUserInfo && !isUpdate){
      if(callback) callback(localUserInfo);
      return;
    }
    Request.get(`/user/info`).then(res => {
      if(res.data && res.data.data){
        Tool.setLocalUserinfo(res.data.data);
        if(callback) callback(res.data.data);
      }
    }).catch(() => {
      if(callback) callback(null);
    });
  },
}
