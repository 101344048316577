<template>
  <div class="p-selector" @click="platformSelectorClick">
    <div class="p-s-i">
      {{ choiceIndex == -1 ||!dataList[choiceIndex]? defaultTxt : dataList[choiceIndex].name }}
    </div>
    <div class="triangle" :class="{open:selectorOpen}"></div>
    <div class="p-selector-wrap" :class="{open:selectorOpen}"
      :style="{height:selectorOpen && menuHeight? menuHeight:''}">
      <div class="p-s-r-name" :class="{selected:i == choiceIndex}"
        @click="selectorClicked(i)"
        v-for="(sItem,i) of dataList">
        {{ sItem.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default{
  props:{
    defaultIndex:Number,
    dataList:Array,
    defaultTxt:String,
    menuHeight:String,
    resetIndex:{
      type:Number, //Math.random()
      required:false,
    }
  },
  data(){
    return {
      choiceIndex:-1,
      selectorOpen:false,
      selfClick:false,
    }
  },
  mounted(){
    if(typeof this.defaultIndex != 'undefined'){
      this.choiceIndex = this.defaultIndex;
    }
    window.addEventListener('click',() =>{
      if(!this.selfClick){
        this.selectorOpen = false
      }
      this.selfClick = false
    });
  },
  methods:{
    platformSelectorClick(){
      this.selfClick = true;
      this.selectorOpen = !this.selectorOpen
    },
    selectorClicked(i){
      this.choiceIndex = i
      this.$emit('change',i);
    }
  },
  watch:{
    resetIndex(){
      this.choiceIndex = 0;
    }
  }
}
</script>
<style scoped lang="stylus">
.p-selector
  background-color transparent
  background-image url(../../assets/geren/editor-nick-bg.png)
  background-size 100% 100%
  border-radius .04rem
  color #fff1df
  padding-left .12rem
  width 1.2rem
  height .24rem
  line-height .24rem
  position relative
  text-align left
  z-index 1
  font-size .12rem
  .p-s-i
    width 93%
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
  .triangle
    position absolute
    right .03rem
    top .08rem
    width 0
    height 0
    border .06rem solid transparent
    border-top-color #d6c4ab
    &.open
      top .03rem
      transform rotate(180deg)
  .p-selector-wrap
    width 95%
    height 0
    overflow hidden
    position absolute
    top .25rem
    left .02rem
    background #3a3a3a
    box-shadow 0 5px 5px 0 rgba(0,0,0,.3)
    border-radius 0 0 5px 5px
    text-align center
    &.open
      height 1.1rem
      overflow auto
    .p-s-r-name
      text-overflow ellipsis
      overflow hidden
      white-space nowrap
      font-size .12rem
      text-align left
      padding .06rem 0 .06rem .1rem
      border-bottom 1px solid rgba(0,0,0,.2)
      &.selected
        background-color #484848
</style>