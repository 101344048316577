import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/base.styl'
import localSocket from './common/LocalWebsocket.js'

let app = createApp(App).use(store).use(router);
localSocket.configSocket(app)
app.mount('#app');
