<template>
  <div class="qiandao-wrapper" :class="{show:localShow}">
    <div class="qd-container" :class="{show:containerShow}">
      <img class="qiandao-close ease-btn-effe" @click="closeClick"
        src="../../assets/home/tiyanjin-close.png" />
      <div class="qd-content-wrap" ref="qdContentWrap">
      </div>
      <div class="ceil time">
        您还有<span>{{ tiyanjin.remain?tiyanjin.remain:0 }}</span>次机会
      </div>
      <div class="ceil remain">
        今日第
        <span>{{ tiyanjin.times?tiyanjin.times:0 }}</span>
        次赠送您
        <span>
          {{ tiyanjin.money?tiyanjin.money:'0.000' }}
        </span>
        元体验金
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import Request from '../../common/Request.js'
import LottieZipShow from '../../common/LottieZipShow.js'
export default {
  props:{
    tiyanjin:Object,
    show:Number,
  },
  data(){
    return {
      localShow:0,
      containerShow:0,
      dataInited:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    getData(){
      let getTyj = (id) => {
        Request.get(`/activity/gold?id=${id}`).then(res => {
          if(res.data.code != 0){
            this.popMsg(res.data.msg)
          }else if(res.data.data){
            this.tiyanjin = res.data.data;
          }
          this.popMsg(res.data.msg)
        })
      }
      // 
      Request.get('/activity/activity_gold_id').then(res => {
        if(res.data && res.data.data && res.data.data.id){
          getTyj(res.data.data.id)
        }
      })
    },
    closeClick(){
      this.containerShow = 0;
      setTimeout(() => {
        this.localShow = 0
      },300)
    },
    animate(){
      const animateEle = this.$refs.qdContentWrap
      LottieZipShow.showIco(
        '/lottie-web/tiyanjin_show.zip',
        'tyjGotShow',
        animateEle,
        'widthHeightRatio',
        null)
      // lottie.loadAnimation({
      //   container: animateEle,
      //   renderer: 'svg',
      //   loop: true,
      //   autoplay: true,
      //   path: '/lottie-web/tiyanjin_coin/data.json'
      // });
    }
  },
  watch:{
    show(n){
      if(n){
        this.localShow = n;
        setTimeout(() => {
          this.containerShow = n;
        });
        if(!this.dataInited){
          this.dataInited = 1
          this.animate();
        }
        this.getData();
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.qiandao-wrapper
  width 100%
  height 100%
  position absolute
  align-items center
  background-color rgba(0,0,0,.5)
  z-index 3
  top 0
  left 0
  display none
  &.show
    display flex
  .qd-container
    margin 0 auto
    transform scale(.0001)
    transition transform .3s
    position relative
    &.show
      transform scale(1)
    .ceil
      font-size .11rem
      position absolute
      z-index 1
      &.time
        top 1.62rem
        left 2.52rem
        color white
        font-size .14rem
        span
          border-radius .03rem
          border .02rem solid
          padding .01rem
          background #fff
          height .17rem
          line-height .13rem
          display inline-block
          color #DB0C57
          font-size .12rem
          transform scale(1.5)
          margin 0 .03rem
      &.remain
        top 1.28rem
        left 1.13rem
        font-size .17rem
        text-align center
        width 3.62rem
        color #ffffff
        span
          color yellow
    .qiandao-close
      z-index 4
      position absolute
      right -.08rem
      top .03rem
      width .35rem
    .qd-content-wrap
      width 4.5rem
      height 2.37rem
      padding-top .02rem
      
</style>