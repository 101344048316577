<template>
  <PopWidget :isShow="isShowPopWidget" @closed="closeHandle" :isFull="1">
    <template v-slot:title>
      <!-- 注册 -->
      <img class="l-t-title" v-if="invokSource==0" src="../assets/login/regist-tool-title.png" />
      <!-- 绑定手机 -->
      <img class="l-t-title" v-if="invokSource==1" src="../assets/login/regist-bind-title.png" />
      <!-- 重置密码 -->
      <img class="l-t-title" v-if="invokSource==2" src="../assets/login/regist-reset-pwd.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <form class="l-c-w-ir" autocomplete="off">
          <div class="l-label">手机号码：</div>
          <input autocomplete="false" name="hidden" style="display:none;">
          <input v-model="mobile" type="text" placeholder="请输入手机号" />
        </form>
        <form class="l-c-w-ir" autocomplete="off">
          <input autocomplete="false" name="hidden" style="display:none;">
          <div class="l-label">短信验证码：</div>
          <input v-model="smsCode" class="sms-code" autocomplete="false"
            type="text" placeholder="请输入验证码" />
          <img @click="getSmsCode" class="get_code_btn" v-show="!smsCodeCountingDown"
            src="../assets/login/get-code-btn.png" alt="">
          <div v-show="smsCodeCountingDown" class="sms-code-counting-down">
            {{smsCodeCountingDown}}秒后可重发
          </div>
        </form>
        <form class="l-c-w-ir" v-if="picVcode == 0 && ![1,2].includes(invokSource)" autocomplete="off">
          <input autocomplete="false" name="hidden" style="display:none;">
          <div class="l-label">
            <span style="visibility:hidden">站位</span>
            验证码：
          </div>
          <input v-model="captcha" class="captcha"
            placeholder="请输入图形验证码" autocomplete="false" />
          <div class="captcha-img-w">
            <img :src="captchImg" v-if="captchImg" @click="cptchImgClick" />
          </div>
        </form>

        <form class="l-c-w-ir" autocomplete="off">
          <input autocomplete="false" name="hidden" style="display:none;">
          <div class="l-label">输入密码：</div>
          <input v-model="password" type="password" 
            autocomplete="new-password"
            placeholder="请设置6-20位字符" />
        </form>

        <form class="l-c-w-ir" autocomplete="off">
          <input autocomplete="false" name="hidden" style="display:none;">
          <div class="l-label">确定密码：</div>
          <input v-model="confirmPwd" type="password" autocomplete="new-password"
            placeholder="请再次输入确定密码" />
        </form>
        <form class="l-c-w-ir" v-show="agentCodeEdit" autocomplete="off">
          <input autocomplete="false" name="hidden" style="display:none;">
          <div class="l-label">邀请码：</div>
          <input v-if="agentCodeEdit" 
            class="agent-code" v-model="agentCode" type="text" placeholder="请输入邀请码" />
          <div v-if="!agentCodeEdit" 
            class="agent-code-label">{{ agentCode }}</div>
        </form>
        
        <div class="l-c-w-ir">
          <img @click="registryClick"
            class="confirm-btn" src="../assets/login/confirm-btn.png" />
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import Request from '../common/Request.js'
import PopWidget from './common/PopWidget.vue'
import PopMessage from './common/PopMessage.vue'
import {mapMutations} from 'vuex'
import Tool from '@/common/Tool'

export default {
  props:{
    isShow:Number,
    invokSource:Number,// 0注册 1绑定手机 2重置密码
  },
  data(){
    return{
      agentCodeEdit:0,
      //1：未启图形验证码，0：开启图形验证码
      picVcode:1,
      captchaKey:'',
      captchImg:'',
      captcha:'',

      isShowPopWidget:0,
      mobile:'',
      smsCode:'',
      password:'',
      confirmPwd:'',
      agentCode:'',
      preSmsCode:{
        mobile:'',
        time:0
      },
      smsCodeCountingDown:0,
      codeTimeout:0,
    }
  },
  beforeRouteLeave(){
    this.smsCodeCountingDown = 0;
  },
  methods:{
    ...mapMutations([
      'popMsg',
      'showLoading',
      'closeLoading',
    ]),
    closeHandle(){
      this.$emit('registClose')
    },
    clearFildValue(){
      this.mobile = '';
      this.smsCode = '';
      this.smsCodeCountingDown = 0;
      this.captcha = '';
      this.password = '';
      this.confirmPwd = '';
    },
    //获取短信验证码
    getSmsCode(){
      let m_number = this.mobile;
      if(!this.isMobile(m_number)){
        this.popMsg('请输入正确的手机号');
        return;
      }
      let params = {
        username:m_number
      }
      let now = new Date();
      let sub = now - this.preSmsCode.time;
      if(sub < 1000 * 60 && this.preSmsCode.mobile == m_number){
        let second = Math.floor((1000 * 60 - sub) / 1000);
        this.popMsg(`正在发送验证码请${second}秒后再试 !`);
        return;
      }
      this.preSmsCode = {
        time:now,
        mobile:m_number,
      }
      this.showLoading()
      Request.post(`/pub/sms_code`,params).then(res => {
        if(res.data.code == 0){
          this.popMsg('正在发送验证码 !');
        }
        this.runCountingDown();
        this.closeLoading()
      }).catch(error => {
        if(error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg);
        }
        this.closeLoading();
      });
    },
    runCountingDown(){
      this.smsCodeCountingDown = 60;
      const run = () => {
        clearTimeout(this.codeTimeout);
        if(this.smsCodeCountingDown < 1) return;

        this.codeTimeout = setTimeout(() => {
          this.smsCodeCountingDown--;
          if(this.smsCodeCountingDown < 1){
            this.smsCodeCountingDown = 0;
          }else{
            run();
          }
        },1000)
      }
      run();
    },
    //手机格式验证
    isMobile(m_number){
      let f = /^1[2-9]\d{9}$/.test(m_number);
      return f;
    },
    //注册提交
    registryClick(){
      const _this = this;
      let flag = this.registPass();
      if(!flag) return;
      let params = {
        username:this.mobile,
        code:this.smsCode,
        password:this.password,
        password_confirm:this.confirmPwd,
        agent_code:this.agentCode,
        device_id:Tool.getDeviceId(),
        captchaKey:this.captchaKey,
        captcha:this.captcha,
      }
      this.showLoading()
      let path = '/pub/register';
      //重置密码
      if(this.invokSource == 2){
        path = '/pub/save_password';
        params = {
          username:this.mobile,
          code:this.smsCode,
          newPassword:this.password,
          confirmPassword:this.confirmPwd,
        }
      // 绑定手机
      }else if(this.invokSource == 1){
        path = '/user/bind_tel';
        params = {
          mobile:this.mobile,
          code:this.smsCode,
          password:this.password,
          password_confirm:this.confirmPwd,
          agent_code:this.agentCode,
        }
      }
      
      let failureMsg = {};
      failureMsg['0'] = '注册失败，请联系客服';
      failureMsg['1'] = '绑定手机失败，请联系客服';
      failureMsg['2'] = '重置密码失败，请联系客服';

      Request.post(path,params).then(res => {
        this.closeLoading();
        if(res.data && res.data.code == 0){
          Tool.removeLocalUserInfo();
          if(res.data.data){
            Tool.setApiToken(res.data.data);
          }
          _this.popMsg('操作成功!');
          // 绑定手机成功后重新进入页面
          if(_this.invokSource == 1){
            location.reload();
          }
          _this.isShowPopWidget = 0;
        }else{
          let sMsg = failureMsg[_this.invokSource]
          if(res.data && res.data.msg){
            sMsg = res.data.msg;
          }
          _this.popMsg(sMsg);
        }
      })
      .catch(error => {
        this.closeLoading();
        if(error.response && error.response.data && error.response.data.msg){
          _this.popMsg(error.response.data.msg);
          if(_this.picVcode == 0){
            _this.getValidCode();
          }
        }else{
          _this.popMsg(failureMsg[_this.invokSource]);
        }
      });
    },
    //注册数据验证
    registPass(){
      let f = true;
      if(!this.mobile){
        this.popMsg('手机号不能为空');
        f = false;
      }else
      if(!this.isMobile(this.mobile)){
        this.popMsg('请输入正确的手机号');
        f = false;
      }else
      if(!this.smsCode){
        this.popMsg('请输入验证码');
        f = false;
      }else if(this.picVcode == 0 && (!this.captcha || !this.captcha.trim())){
        this.popMsg('请输入图形验证码');
        f = false;
      }else if(!this.password){
        this.popMsg('请输入密码');
        f = false;
      }else
      if(!this.confirmPwd){
        this.popMsg('请再次输入密码');
        f = false;
      }else
      if(this.password.length < 4){
        this.popMsg('密码太短 !');
        f = false;
      }else
      if(this.password != this.confirmPwd){
        this.popMsg('两次密码输入不一致');
        f = false;
      }
      return f;
    },
    //获取图形验证码
    getValidCode(){
      //重置密码无需验证码
      if(this.invokSource == 2){
        return;
      }
      Request.get('/pub/captcha').then(res => {
        this.picVcode = res.data.code;
        if(res.data.code == 0){
          this.captchaKey = res.data.data.captchaKey;
          this.captchImg = res.data.data.img;
        }
      })
    },
    cptchImgClick(){
      this.getValidCode();
    },
    //获取邀请码
    getAgentCode(){
      let agentCode = localStorage.getItem('agent_code')
      if(agentCode){
        this.agentCode = agentCode;
        this.agentCodeEdit = 0
      }else{
        this.agentCodeEdit = 1
      }
    }
  },
  watch:{
    isShow(n,o){
      if(n){
        if(![1,2].includes(this.invokSource)){
          this.getValidCode();
        }
        this.getAgentCode();
        this.clearFildValue();
      }
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget,
    PopMessage
  }
}
</script>
<style scoped lang="stylus">
.l-c-w-i
  width 100%
  border-top 1px solid transparent
  .l-c-w-ir
    width 3.82rem
    display flex
    margin-top .1rem
    justify-content flex-start
    margin-left .8rem
    align-items center
    .sms-code-counting-down
      font-size .12rem
      padding 0 .05rem
    .sms-code
      width 1.44rem
    .get_code_btn
      width .52rem
      transition transform .3s
      &:active
        transform scale(.8)
    .l-label
      width 1rem
      display flex
      justify-content flex-end
      align-items center
    .confirm-btn
      width 1.12rem
      margin-left .96rem
      transition transform .3s
      &:active
        transform scale(.9)
    input
      background-color transparent
      background-image url(../assets/login/input-bg.png)
      background-size 100% 100%
      border none
      border-radius .12rem
      width 2rem
      height .25rem
      color #dddcbd
      padding-left .05rem
      &.captcha
        width 1.44rem
      &.agent-code
        width .8rem
    .agent-code-label
      width .8rem
      height .25rem
      border-radius .12rem
      color #fff
      text-align center
      line-height .25rem
      font-size .133rem
      background-color #484848
    .captcha-img-w
      width .7rem
      height .26rem
      margin-left .05rem
      background red
      img
        width 100%
        height 100%
        display block
      
</style>