<template>
  <PopWidget :isShow="isShowPopWidget" :isFull="1" style="z-index:4;" width="5rem">
    <template v-slot:title>
      <img class="l-t-title"
        src="../../assets/yuebao/sfyz-title.png" />
    </template>
    <template v-slot:content>
      <div class="yeb-io-wrap" :class="{isout:isOut}">
        <div class="row-yeb flex">
          为了您的账户安全请输入您的
        </div>
        <div class="row-yeb flex amount">
          余额宝交易密码
        </div>
        <div class="yeb-separator"></div>
        <div class="row-yeb flex">
          <div>
            交易密码
          </div>
          <form class="yeb-wrapper" autocomplete="off">
            <input autocomplete="false" name="hidden" style="display:none;">
            <input class="yeb-io-input" placeholder="请输入6位数字交易密码" 
              v-model="transactionPwd" autocomplete="new-password"
              type="password" />
            <img @click="forgotPwdClick" class="all-out-btn ease-btn-effe" 
              src="../../assets/yuebao/sfyz-forgot-pwd.png" />
          </form>
        </div>
        <img class="confirm-btn ease-btn-effe" 
          src="../../assets/confirm-btn.png" @click="confirmClick" />
      </div>
    </template>
  </PopWidget>
</template>

<script>
import { mapMutations } from 'vuex';
import PopWidget from '../common/PopWidget.vue'
export default{
  props:{
    show:Number,
    isOut:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      transactionPwd:'',
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    confirmClick(){
      if(!this.transactionPwd){
        this.popMsg(`请输入交易密码`);return;
      }
      this.$emit('password',this.transactionPwd)
      this.isShowPopWidget = 0;
      this.transactionPwd = '';
    },
    forgotPwdClick(){
      this.$emit('forgotClick')
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget,
  }
}
</script>

<style scoped lang="stylus">
.l-t-title
  display block
  margin auto
.yeb-io-wrap
  width 100%
  height 2.08rem
  padding-top .2rem
  background-image url(../../assets/yuebao/guize-bg.png)
  background-size 100% 100%
  &.isout
    padding-top .22rem
  .confirm-btn
    width 1.2rem
    margin-top .2rem
  .yeb-io-icode
    width .2rem
    margin-right .08rem
  .yeb-separator
    width 2.5rem
    height .2rem
    margin 0 auto 3px auto
    background-image url(../../assets/yuebao/yuebao-de-line.png)
    background-size 100% 100%
  .row-yeb
    color #DEB884
    font-size .18rem
    justify-content center
    align-items center
    &.amount
      margin .08rem 0 .09rem 0
      color #FFF7BA
      font-size .24rem
    .yeb-wrapper
      position relative
    .yeb-io-input
      width 2.68rem
      height .32rem
      padding-left .1rem
      margin-left .2rem
      border-radius .14rem
      background-color #34231D
    .all-out-btn
      position absolute
      right -.04rem
      top 0
      width .92rem
</style>