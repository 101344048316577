<template>
  <div class="user-info">
    <Confirm :show="quitGameConfirm"
      style="z-index:4;" width='2.7rem' @confirm="confirmFunc">
      <template v-slot:confirm-content>
        <div class="confirm-wrapper">
          确定要退出当前账号？
        </div>
      </template>
    </Confirm>
    <BindBankCard :isShow="isShowBindBank" @bindSuccess="accBindSuccess" 
      :bank="userInfo&&userInfo.bank?userInfo.bank:{}" />
    <BindAlipay :isShow="isShowBindAlipay" @bindSuccess="alipayBindSuccess"
      :aliyName="userInfo?userInfo.name:''" 
      :alipay="userInfo?userInfo.alipay:''" />
    <BindDigitalWallet :isShow="isShowBindDigitalWallet" 
      @bindSuccess="walletBindSuccess" />
    <EditNickname :nickname="userInfo.nickname" 
      :isShow="isShowNicknameEditor" @nicknameEdited="nicknameEdited" />
    <BankInfo :isShow="isShowBankInfo"
      :bank="userInfo && userInfo.bank?userInfo.bank:{}"
      :aliplay="alipayInfo"  />
    <WalletInfo :isShow="isShowWalletInfo"
      :wallets="userInfo.wallets && userInfo.wallets.length?userInfo.wallets:null" />
    <Regist :invokSource="1" :isShow="isShowBindMobile" />
    <img class="decorate" src="../../assets/geren/ctitle-info.png" />
    <div class="u-info-content flex">
      <div>
        <div class="avatar-contaienr">
          <div class="avatar flex">
            <img :src="httpsPreHandle(localAvatar ? localAvatar : userInfo.avatar)" />
          </div>
          <input type="file" class="uploader" @change="avatarChanged($event)">
        </div>
      </div>
      <div class="info-show">
        <div class="flex info-row">
          <div class="flex field-row nickname">
            {{userInfo.nickname}}
          </div>
          <div class="nickname-wrapper">
            <img @click="nickNameEditorClick"
              class="change-nickname-ico" 
              src="../../assets/geren/change-nickname.png" alt="">
          </div>
          <img @click="exchange_account"
            class="exchange-account" src="../../assets/geren/exchange-account.png" alt="">
        </div>
        <div class="flex info-row">
          <div class="f-label">
            ID
          </div>：
          <div class="field-row">{{ userInfo.uid }}</div>
        </div>
        <div class="flex info-row">
          <div class="field-row flex">
            <div class="flex f-ceil" @click="sex_choiced(0)">
              <div class="sex-checkbox" :class="{selected:sexChoice==0}"></div>
              <img class="sex-decorate" src="../../assets/geren/geren-man.png" alt="">
              <div class="sex-word">
                男
              </div>
            </div>
            <div class="flex f-ceil" @click="sex_choiced(1)">
              <div class="sex-checkbox" :class="{selected:sexChoice==1}"></div>
              <img class="sex-decorate" src="../../assets/geren/geren-women.png" alt="">
              <div class="sex-word">
                女
              </div>
            </div>
          </div>
        </div>
        <div class="flex field-s-row">
          <div class="s-label">
            手机号：
          </div>
          <div class="s-fill-wrap" :class="{v:userInfo.mobile}">
            {{userInfo.mobile?userInfo.mobile:'请绑定手机号'}}
            <img v-if="!userInfo.mobile"
              class="binding-ico" @click="mobileBindClick"
              src="../../assets/geren/binding-ico.png" />
          </div>
        </div>
        <div class="flex field-s-row">
          <div class="s-label">
            银行卡：
          </div>
          <div class="s-fill-wrap" :class="{v:userInfo.bank}" @click="bankDetailClick">
            {{userInfo.bank?userInfo.bank.bank_account:'请绑定银行卡'}}
            <img v-if="!userInfo.bank"
              class="binding-ico" @click="bindingBanckClick"
              src="../../assets/geren/binding-ico.png" />
          </div>
        </div>
        <div class="flex field-s-row">
          <div class="s-label">
            支付宝：
          </div>
          <div class="s-fill-wrap" :class="{v:userInfo.alipay}" 
            @click="alipayInfoClick">
            {{ userInfo.alipay?userInfo.alipay:'请绑定支付宝' }}
            <img v-if="!userInfo.alipay"
              class="binding-ico" @click="bindingAlipayClick"
              src="../../assets/geren/binding-ico.png" />
          </div>
        </div>
        <div class="flex field-s-row">
          <div class="s-label">
            数字钱包：
          </div>
          <div class="s-fill-wrap wallet" :class="{v:isWalletBinded}"
            @click="showWalletInfoClick">
            {{ isWalletBinded?'已绑定':'您还未绑定' }}
            <img v-if="!isWalletBinded" class="binding-ico" 
              @click="bindingDigital" src="../../assets/geren/binding-ico.png" />
          </div>
          <div class="wallet-link" @click="bindingDigital">
            绑定
          </div>
        </div>
      </div>

      <div class="flex music">
        <div class="label">音乐</div>
        <div class="switch-wrap flex p-relative" 
          :class="{'on':musicSwitch == 1}"
          @click.stop="musicTurnonOff">
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import Confirm from '../common/Confirm.vue'
import Tool from '../../common/Tool.js'
import Request from '../../common/Request.js'
import BindBankCard from './BindBankCard.vue'
import BindAlipay from './BindAlipay.vue'
import BindDigitalWallet from './BindDigitalWallet.vue'
import EditNickname from './EditNickname.vue'
import BankInfo from './BankInfo.vue'
import WalletInfo from './WalletInfo.vue'
import Regist from '../../components/Regist.vue'

export default{
  data(){
    return {
      userInfo:{},
      isWalletBinded:0,
      sexChoice:-1,
      isShowBindBank:0,
      isShowBindAlipay:0,
      isShowBindDigitalWallet:0,
      isShowNicknameEditor:0,
      isShowBankInfo:0,
      isShowBindMobile:0,
      isShowWalletInfo:0,
      localAvatar:null,
      alipayInfo:null,
      isRouteEnter:false,
      quitGameConfirm:0,
    }
  },
  mounted(){
    // window.testAlert = (msg) => {
    //   this.alert(msg);
    // }
    if(!this.isRouteEnter){
      this.showUserInfo()
    }
  },
  methods:{
    ...mapMutations(['popMsg','alert','setMusicSwitch','showLoading','closeLoading']),
    httpsPreHandle(url){
      return Tool.urlProtocalHandle(url)
    },
    mobileBindClick(){
      this.isShowBindMobile = Math.random()
    },
    bankDetailClick(){
      if(this.userInfo && this.userInfo.bank){
        this.alipayInfo = null;
        this.isShowBankInfo = Math.random()
      }
    },
    bindingDigital(){
      this.isShowBindDigitalWallet = Math.random();
    },
    bindingAlipayClick(){
      this.isShowBindAlipay = Math.random();
    },
    nickNameEditorClick(){
      this.isShowNicknameEditor = Math.random();
    },
    bindingBanckClick(){
      this.isShowBindBank = Math.random()
    },
    accBindSuccess(){
      this.showUserInfo();
    },
    alipayBindSuccess(){
      this.showUserInfo();
    },
    walletBindSuccess(){
      this.showUserInfo();
    },
    nicknameEdited(){
      this.showUserInfo();
    },
    alipayInfoClick(){
      if(this.userInfo && this.userInfo.alipay){
        this.alipayInfo = {
          alipay:this.userInfo.alipay,
          name:this.userInfo.name
        };
        this.isShowBankInfo = Math.random()
      }
    },
    avatarChanged(event){
      const _this = this;
      const file = event.target.files[0]
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(){
        _this.localAvatar = this.result;
        let p = {file:this.result}
        _this.showLoading();
        Request.post('/user/setAvatar',p).then(res => {
          _this.closeLoading();
          _this.popMsg(res.data.msg);
          _this.refreshUserInfo(() => {
            _this.userInfo = Tool.getLocalUserinfo()
          });
        }).catch(err => {
          _this.closeLoading();
          console.log(err);
        })
      }

    },
    sex_choiced(f){
      this.sexChoice = f;
      if(f == 0){
        this.userInfo.sex = '男'
      }else if(f == 1){
        this.userInfo.sex = '女'
      }
      this.showLoading();
      Request.post('/user/info',this.userInfo).then(res => {
        if(res.data.code == 0){
          let localUinfo = Tool.getLocalUserinfo();
          localUinfo.sex = this.userInfo.sex;
          Tool.setLocalUserinfo(localUinfo);

          this.popMsg(res.data.msg);
        }
        this.closeLoading()
      }).catch(() => this.closeLoading())
    },
    userInfoShow(){
      if(this.userInfo){
        if(this.userInfo.wallets && this.userInfo.wallets.length){
          this.isWalletBinded = 1;
        }
        if(this.userInfo.sex == '保密'){
          this.sexChoice = -1
        }else if(this.userInfo.sex == '男'){
          this.sexChoice = 0
        }else if(this.userInfo.sex == '女'){
          this.sexChoice = 1
        }
      }
    },
    showUserInfo(){
      this.refreshUserInfo(() => {
        this.userInfo = Tool.getLocalUserinfo()
        this.userInfoShow();
        this.closeLoading();
      });
      
    },
    musicTurnonOff(){
      let flag = this.musicSwitch == 1 ? -1 : 1;
      this.setMusicSwitch(flag);
      Tool.setMusicSwitch(flag);
    },
    refreshUserInfo(callback){
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          this.userInfo = res.data.data;
          Tool.setLocalUserinfo(res.data.data);
          callback()
        }
      });
    },
    confirmFunc(){
      Tool.quit()
      this.$router.push({name:'login'})
    },
    exchange_account(){
      this.quitGameConfirm = Math.random();
    },
    showWalletInfoClick(){
      if(this.isWalletBinded){
        this.isShowWalletInfo = Math.random()
      }
    }
  },
  computed:{
    ...mapGetters({
      musicSwitch:'getMusicSwitch',
    })
  },
  beforeRouteEnter(to,from,next){
    next(vm => {
      vm.isRouteEnter = true;
      vm.userInfo = Tool.getLocalUserinfo()
      vm.userInfoShow();
    })
  },
  components:{
    BindBankCard,
    BindAlipay,
    BindDigitalWallet,
    EditNickname,
    BankInfo,
    WalletInfo,
    Regist,
    Confirm,
  }
}
</script>
<style scoped lang="stylus">
.user-info
  width 100%
  height 100%
  padding-left .36rem
  .decorate
    width 70%
    margin-left .5rem
  .confirm-wrapper
    width 100%
    text-align center
  
  .u-info-content
    height 86%
    .avatar-contaienr
      width .81rem
      height .81rem
      position relative
      overflow hidden
    .avatar
      width 100%
      height 100%
      justify-content center
      border-radius 50%
      overflow hidden
      border 1px solid #D0B78B
      img
        width 100%
        height auto
        display block
    .uploader
      width 100%
      height 100%
      display block
      position absolute
      z-index 2
      top 0
      left 0
      opacity .001
      border-radius 50%
    .music
      right .4rem
      bottom .36rem
      position absolute
      font-size .14rem
      .label
        padding-top .03rem
      .switch-wrap
        width .62rem
        height .3rem
        line-height .2rem
        font-size .16rem
        justify-content space-between
        align-items center
        background-image url('../../assets/geren/music-off.png')
        background-size 100% 100%
        &.on
          background-image url('../../assets/geren/music-on.png')
    .info-show
      height 100%
      line-height .16rem
      color #6B6A68
      padding .09rem 0 .5rem 0
      font-size .16rem
      overflow auto
      .info-row
        padding-left .87rem
        margin-bottom .12rem
        align-items flex-start
      .field-s-row
        height .4rem
      .s-label
        width .84rem
        display flex
        color #BCAA94
        align-items flex-start
        justify-content flex-end
        padding-top .05rem
      .s-fill-wrap
        width 2.2rem
        height .28rem
        background #191919
        border-radius .12rem
        box-shadow 0 0 5px 0 rgba(0,0,0,.4) inset
        color #5B5B5B
        line-height .28rem
        padding-left .1rem
        font-weight 100
        font-size .14rem
        position relative
        &.v
          color #DDDCBD
        &.wallet
          width 1.8rem
        .binding-ico
          position absolute
          right .06rem
          top .06rem
          width .15rem
      .wallet-link
        width .55rem
        height .3rem
        line-height .3rem
        border-radius .2rem
        text-align center
        color #cec6aa
        border 1px solid
        font-size .14rem
      .f-label
        width .17rem
        text-align justify
        margin-left .05rem
        &:after
          content ''
          width 100%
          display inline-block
          overflow hidden
          height 0
      .field-row
        font-size .14rem
        &.nickname
          width 1.5rem
          height .36rem
          padding-left .07rem
          border-radius .08rem 0 0 .08rem
          align-items center
          overflow hidden
          white-space nowrap
          text-overflow ellipsis
          background-color #262425
        .f-ceil
          margin-right .2rem
          height .24rem
          align-items center
          margin-top -.04rem
        .sex-decorate
          width .2rem
          height .2rem
          margin 0 .05rem
        .sex-word
          margin 0 .04rem
        .sex-checkbox
          width .28rem
          height .26rem
          background-image url(../../assets/geren/geren-checkbox.png)
          background-size 100% 100%
          &.selected
            background-image url(../../assets/geren/geren-checkbox-choice.png)
      .nickname-wrapper
        width .32rem
        height .36rem
        padding-top .02rem
        border-radius 0 .08rem .08rem 0
        background-color #262425
      .change-nickname-ico
        margin 0 .04rem 0 0
        width .32rem
      .exchange-account
        width .94rem
        margin-left .7rem
</style>