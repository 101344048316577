import PouchDB from 'pouchdb'
import Tool from './Tool.js'

export default{
  dbName:'localDataBase',
  version:1,
  storeNameDic:{
    gameIcoAnimation:'GameIcoAnimation',
  },
  open(){
    window.indexedDB.deleteDatabase('bigDataBase')
    return new PouchDB(this.dbName);
  },
  createGameIconStore(db){
    const objectStoreName = this.storeNameDic.gameIcoAnimation;
    // 判断是否有ObjectStore
    if(!db.objectStoreNames.contains(objectStoreName)){
      db.createObjectStore(objectStoreName,
        { keyPath: "id", autoIncrement: true });
    }
  },
  async getBase64(key){
    const db = this.open();
    let doc = null;
    try{
      doc = await db.get(key);
    }catch(e){}
    if(doc){
      return doc.b_data;
    }
  },
  async setBase64(key,dataStr){
    const db = this.open();
    const doc = {_id : key,b_data: dataStr}
    db.put(doc);
  },
  async addAnimateBlob(key,blobData){
    const db = this.open();
    const dataStr = await Tool.blobToBase64(blobData);
    const doc = {_id : key,b_data: dataStr}
    db.put(doc);
  },
  async getAnimateBlob(id){
    const db = this.open();

    let doc = null;
    try{
      doc = await db.get(id);
    }catch(e){}
    
    if(doc){
      return Tool.base64ToBlob(doc.b_data);
    }
  }

}
