<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/geren/binding-digital-title.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <div class="flex row-i">
          <div class="label-w-i">
            平台类型：
          </div>
          <div class="platform-selector" @click.stop="platformSelectorClick">
            {{ platformChoiceI == -1 ? '请选择平台' : platforms[platformChoiceI].name }}
            <div class="triangle" :class="{open:selectorOpen}"></div>
            <div class="p-selector-wrap" :class="{open:selectorOpen}">
              <div class="p-s-r-name" @click="selectorClicked(i)"
                v-for="(platform,i) of platforms">
                {{ platform.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex row-i">
          <div class="label-w-i">
            钱包地址：
          </div>
          <textarea v-model="walletAddress" placeholder="请填写钱包地址" :readonly="textAreaReadonly"></textarea>
          <div @click="pastClick" class="past-btn flex">粘贴</div>
        </div>
        <div style="font-size:.12rem;color:#989898;margin:.07rem 0;">
          请输入正确的数字钱包地址（建议采用复制粘贴）。后续如有疑问请联系客服
        </div>
        <img @click="confirmClick()" class="l-confirm-btn"
          src="../../assets/login/confirm-btn.png" />
      </div>
    </template>
  </PopWidget>
</template>

<script>
import { mapMutations } from 'vuex';
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import PopWidget from '../common/PopWidget.vue'
import UserInfo from '../../common/UserInfo.js'
export default {
  props:{
    isShow:Number,
  },
  data(){
    return {
      platformChoiceI:-1,
      platforms:[],
      isShowPopWidget:0,
      selectorOpen:false,
      walletAddress:'',
      userInfo:null,
      textAreaReadonly:0,
    }
  },
  mounted(){
    window.addEventListener('click',() =>{
      this.selectorOpen = false
    });
    UserInfo.getUserInfoData(data => this.userInfo = data);
  },
  watch:{
    isShow(n,o){
      this.isShowPopWidget = n;
      this.walletAddress = '';
      this.platformChoiceI = -1;
      this.getPlatforms();
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    async pastClick(){
      this.walletAddress = await Tool.paste();
    },
    confirmClick(){
      if(this.textAreaReadonly){
        this.isShowPopWidget = 0;
        return;
      }
      if(this.platformChoiceI == -1){
        this.popMsg('请选择钱包平台');
        return;
      }
      if(!this.walletAddress){
        this.popMsg('请输入钱包地址');
        return;
      }
      let selectedPlat = this.platforms[this.platformChoiceI];
      let param = {
        third_id:selectedPlat.third_id,
        address:this.walletAddress,
      };
      Request.post('/user/wallet_bind',param).then(res => {
        if(res.data.code == 0){
          this.popMsg(res.data.msg)
          this.isShowPopWidget = 0;
          this.updateUserInfo();
        }
      }).catch(e => {
        this.popMsg(e.response.data.msg);
      })
    },
    updateUserInfo(){
      Tool.removeLocalUserInfo();
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          this.userInfo = res.data.data;
          Tool.setLocalUserinfo(res.data.data);
          this.$emit('bindSuccess')
        }
      });
    },
    selectorClicked(i){
      this.platformChoiceI = i;
      this.walletAddress = '';
      this.textAreaReadonly = 0;

      if(this.userInfo && this.userInfo.wallets && this.userInfo.wallets.length){
        let j = 0;
        while(j < this.userInfo.wallets.length){
          if(this.userInfo.wallets[j].third_id == this.platforms[i].third_id){
            this.walletAddress = this.userInfo.wallets[j].address;
            this.textAreaReadonly = 1;
          }
          j++
        }
      }
    },
    getPlatforms(){
      Request.post('/user/wallet_thirds').then(res => {
        if(res.data && res.data.data){
          this.platforms = res.data.data;
        }
      }).catch(error => {
        this.popMsg(error.response.data.msg)
      })
    },
    platformSelectorClick(){
      this.selectorOpen = !this.selectorOpen;
    }
  },
  components:{
    PopWidget
  }
}
</script>

<style scoped lang="stylus">
.l-t-title
  width 2.14rem
  margin-top -.04rem
.l-confirm-btn
  width .94rem
  margin-bottom -.16rem
  transition transform .3s
  &:active
    transform scale(.8)
.l-c-w-i
  width 100%
  border-top 1px solid transparent
  padding 0 .2rem
  .row-i
    margin-top .1rem
    .past-btn
      width .6rem
      height .25rem
      user-select none
      justify-content center
      align-items center
      border-radius .08rem
      background #776956
      font-size .12rem
      color #e7e7e5
      margin-left .02rem
    .platform-selector
      background-image url(../../assets/geren/editor-nick-bg.png)
      background-size 100% 100%
      border-radius .04rem
      color #fff1df
      padding-left .05rem
      width 1.2rem
      height .29rem
      line-height .29rem
      position relative
      text-align left
      padding-left .12rem
      z-index 1
      .triangle
        position absolute
        right .03rem
        top .08rem
        width 0
        height 0
        border .06rem solid transparent
        border-top-color #d6c4ab
        &.open
          top .03rem
          transform rotate(180deg)
      .p-selector-wrap
        width 95%
        height 0
        overflow hidden
        position absolute
        top .25rem
        left .02rem
        background #4c4c4c
        box-shadow 0 5px 5px 0 rgba(0,0,0,.3)
        border-radius 0 0 5px 5px
        transition height .15s
        text-align center
        &.open
          height 1.1rem
          overflow auto
        .p-s-r-name
          padding .03rem 0
          border-bottom 1px solid rgba(0,0,0,.2)
  .label-w-i
    font-size .16rem
  textarea
    color #fff1df
    border-radius .06rem
    width 2.7rem
    height .8rem
    border 1px solid #7c7c7c
    background transparent
</style>