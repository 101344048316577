<template>
  <div class="red-pack-wrapper flex" :class="{show:localShow}">
    <div class="r-p-wrapper" :class="{show:containerShow}">
      <img class="close-btn" 
        @click="closeClick"
        src="../../assets/red-pack/r-p-close.png" />
      <div class="cd-wrapper flex">
        <div class="title-wrap">
        </div>
        <div v-for="char of cTime" 
          :class="[`${char == ':'?'spe-bg':'time-bg'}`]" >
          {{char == ':'?'':char}}
        </div>
      </div>
    </div>

    <div class="r-w-container flex" :class="{show:rainShow}">
      <img class="close-rp-btn" 
        @click="closeRedpackClick"
        src="../../assets/red-pack/r-p-close.png" />
      <div class="rain-wrapper" @click="redpackApplayClick"
        ref="rainWrapper">
      </div>
    </div>
    
    <div class="r-w-container zj" :class="{show:zhongjiangShow}"
      style="z-index:1">
      <div class="rain-wrapper">
        <img class="close-rp-btn" 
          @click="zhongjiangAniClick"
          src="../../assets/red-pack/r-p-close.png" />
        <div class="r-w-c-inner" ref="zhongjiangWrapper">
        </div>
        <div class="zhongjiang-data-info flex">
          <div>恭喜已抢得红包</div>
          <div>{{zhongjiangAmount}}&nbsp;元</div>
          <div>红包现金直接存入您的账户</div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import lottie from 'lottie-web'
import Request from '../../common/Request.js'
import LottieZipShow from '../../common/LottieZipShow.js'
import { mapMutations } from 'vuex';

export default{
  props:{
    show:Number,
    cSecond:Number,
    cTime:String,
  },
  data(){
    return{
      localShow:0,
      rainShow:0,
      containerShow:0,
      zhongjiangShow:0,
      zhongjiangAmount:0,
      redpackIcoAnimaName:'redpack-ico-anim',
      zjiangIcoAnimaName:'zjiang-ico-anim',
      showRedRainTimeout:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    closeRedpackClick(){
      clearTimeout(this.showRedRainTimeout);
      lottie.destroy(this.redpackIcoAnimaName);
      this.rainShow = 0;
    },
    zhongjiangAnimation(){
      this.closeRedpackClick();

      const ele = this.$refs.zhongjiangWrapper;
      LottieZipShow.showIco(
        `/lottie-web/envelop-gotted.zip`,
        'envelop-gotted',
        ele,
        'widthHeightRatio',
        null
      )
      
    },
    redPackRain(){
      lottie.destroy(this.redpackIcoAnimaName)
      const ele = this.$refs.rainWrapper;
      lottie.loadAnimation({
        container: ele,
        renderer: 'svg',
        loop: true,
        name:this.redpackIcoAnimaName,
        autoplay: true,
        path: `/lottie-web/chaiHongBao/data.json`
      });

      clearTimeout(this.showRedRainTimeout);
      this.showRedRainTimeout = setTimeout(() => {
        this.closeRedpackClick();
        this.$emit('redpackClick');
      },1000 * 30);

    },
    closeClick(){
      this.containerShow = 0;
      this.localShow = 0;
      this.$emit('redpackClick');
    },
    zhongjiangAniClick(){
      lottie.destroy(this.zjiangIcoAnimaName)
      this.zhongjiangShow = 0;
      this.closeClick();
    },
    // 抢红包关闭
    redpackApplayClick(){
      this.showLoading();
      this.closeRedpackClick();
      
      Request.post('/activity/redpack_apply').then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          let amount = 0;
          if(res.data.data && res.data.data.amount){
            amount = res.data.data.amount;
            this.zhongjiangShow = 1;
            this.zhongjiangAnimation();
          }
          this.zhongjiangAmount = amount;
        }
      }).catch(error => {
        this.closeLoading();
        if(error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg);
        }
      })
    }
  },
  watch:{
    show(n){
      if(n){
        this.localShow = n;
        if(this.cSecond > 0){
          this.rainShow = 0
          this.containerShow = n;
        }else{
          this.containerShow = n;
          this.rainShow = n;
          setTimeout(() => {
            this.redPackRain();
          },100)
        }
      }
    },
    cSecond(n){
      if(n == 0){
        this.rainShow = Math.random();
        this.redPackRain();
      }else{
        this.rainShow = 0;
        this.closeRedpackClick();
      }
    }
  }
}
</script>
<style scoped lang="stylus">
.red-pack-wrapper
  width 100%
  height 100%
  position absolute
  justify-content center
  align-items center
  background-color rgba(0,0,0,.5)
  z-index 3
  top 0
  left 0
  display none
  &.show
    display flex
  .r-w-container
    width 100%
    height 100%
    justify-content center
    align-items center
    position absolute
    top 0
    left 0
    background-color rgba(0,0,0,.5)
    display none
    &.show
      display flex
    .close-rp-btn
      width .35rem
      position absolute
      right 1.88rem
      top .6rem
      z-index 1
  
  .rain-wrapper
    width 4.63rem
    height 2.73rem
    overflow hidden
    position absolute
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
    .close-rp-btn
      width .35rem
      position absolute
      right .13rem
      top .14rem
    .zhongjiang-data-info
      width 100%
      height auto
      font-size .12rem
      flex-direction column
      justify-content flex-start
      align-items center
      position absolute
      top 1.18rem
      left .05rem
      text-align: center;
      & > div
        &:first-child
          color #fff
        &:nth-child(2)
          color #ECD824
          font-size .15rem
          height .28rem
          line-height .28rem
        &:nth-child(3)
          width 1.12rem
          border-radius .1rem
          background-color #3f080078
          color #CE9C42
          padding .03rem
    .r-w-c-inner
      width 100%
      height 100%
      transform scale(1.4)
  .r-p-wrapper
    width 5.48rem
    height 2.64rem
    background-image url(../../assets/red-pack/red-pack-bg.png)
    background-size 100% 100%
    position relative
    .close-btn
      width .35rem
      position absolute
      right .13rem
      top .14rem
    .cd-wrapper
      width 3.43rem
      height 1.03rem
      justify-content center
      align-items center
      position absolute
      top 1.02rem
      left 1.68rem
      border-radius .06rem
      background-color rgba(149,36,19,.64)
      & > div
        margin 0 .02rem
      .time-bg
        width .34rem
        height .76rem
        line-height .76rem
        text-align center
        color #4F0202
        font-size .48rem
        font-weight bold
        background-image url(../../assets/red-pack/time-bg.png)
        background-size 100% 100%
      .spe-bg
        width .15rem
        height .48rem
        background-image url(../../assets/red-pack/time-sep.png)
        background-size 100% 100%
      .title-wrap
        width .35rem
        height .81rem
        background-image url(../../assets/red-pack/counting-d-title.png)
        background-size 100% 100%
</style>