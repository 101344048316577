<template>
  <PopWidget :isShow="isShowPopWidget" 
    style="z-index:5" :isFull="1">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/recharge/customer-title.png" />
    </template>
    <template v-slot:content>
      <div class="customer-content flex">

        <div class="menu-wrap">
          <div class="inner-back">
            <div class="menu-wrap-scroll">
              <div class="menu-btn" @click="menuChange(i)"
                v-for="(menu,i) of menuList">
                <div class="normal-bg" v-if="i != selectI"></div>
                <div class="select-bg" v-if="i == selectI"></div>
                <div class="menu-text" :class="{selected:i == selectI}">
                  {{ menu.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="faq-list-wrap">
          <div v-for="faq of faqList">
            <div class="title">{{ faq.title }}</div>
            <div class="content">{{ faq.content }}</div>
          </div>
        </div>

      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from './PopWidget.vue'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js';
export default{
  props:{
    show:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      selectI:0,
      menuList:[
        {
          name:'常见问题',
        },
        {
          name:'在线客服',
        }
      ],
      customer:'',
      faqList:[],
    }
  },
  methods:{
    menuChange(i){
      if(i == 1 && this.customer){
        this.$router.push({name:'customer'});
      }
    },
    getData(){
      let faqList = Tool.getFaqList();
      if(faqList){
        this.faqList = faqList;
      }else{
        Request.get('/pub/FAQ').then(res => {
          this.faqList = res.data.data;
          Tool.setFaqList(res.data.data);
        })
      }

      let customer = Tool.getCusLink();
      if(customer){
        this.customer = customer;
      }else{
        Request.get('/pub/callCustomer').then(res => {
          if(res.data.code == 0){
            this.customer = res.data.data;
            Tool.setCusLink(res.data.data);
          }
        })
      }
      
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      this.getData();
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style lang="stylus" scoped>
.customer-content
  width 100%
  .menu-wrap
    width 1.1rem
    height 2.16rem
    overflow auto
    z-index 1
    position relative
    &::-webkit-scrollbar
      width 0
    .inner-back
      width 1.1rem
      min-height 100%
      height auto
      box-shadow 0 0 10px 0 rgba(0,0,0,.6)
      padding-bottom .3rem
      border-radius .05rem 0 0 .05rem
    .menu-wrap-scroll
      width 100%
      height auto
      padding-top .12rem
      .menu-btn
        width 1rem
        height .38rem
        display block
        margin 0 0 .06rem .05rem
        position relative
        .normal-bg
          width 1rem
          height 100%
          background-image url(../../assets/recharge/recharge-menu.png)
          background-size 100% 100%
        .huobao-ico
          width .18rem
          right .14rem
          top -.06rem
          position absolute
        .select-bg
          width 100%
          height 100%
          background-image url(../../assets/recharge/recharge-menu1.png)
          background-size 100% 100%
        .menu-text
          width 1rem
          height 100%
          text-align center
          line-height .38rem
          font-size .12rem
          position absolute
          z-index 1
          top 0
          left 0
          &.selected
            color #842404
  .faq-list-wrap
    width 3.44rem
    height 2.14rem
    padding .2rem
    overflow auto
    text-align left
    .title
      font-size .14rem
      font-weight bold
      margin-bottom .1rem
      color #EDE5D8
    .content
      font-size .12rem
      margin-bottom .2rem
      color #FFFFFF
</style>