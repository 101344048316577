<template>
  <div class="geren-zhongxin flex" 
    @touchstart.stop="" @touchmove.stop="">
    <Regist :invokSource="1" :isShow="isShowBindMobile" />
    <div class="top flex">
      <div class="go-back" @click="$router.push({name:'main'})"></div>
      <div class="decorate1">        
      </div>
    </div>
    <div class="main-content flex">
      <div class="menu-wrap" :style="{height:`${content_height}px`}">
        <div class="inner-back">
          <div class="menu-wrap-scroll">
            <router-link :to="getRoutePath(menu.id)" :key="menu.id"
              class="menu-btn" v-for="menu of menuItems">
              <div class="normal-bg" v-if="menu.bg"
                :style="{backgroundImage:`url(${menu.bg})`}">
              </div>
              <div class="select-bg" v-if="menu.bgFocus"
                :style="{backgroundImage:`url(${menu.bgFocus})`}">
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="m-w-page" :class="[browserBrand]"
        :style="{height:`${content_height}px`}">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import Tool from '../common/Tool.js'
import UserInfo from '../common/UserInfo.js'
import Regist from '../components/Regist.vue'

export default {
  data(){
    return {
      menuItems:[
        {id:0,text:'个人信息'},
        {id:1,text:'等级特权'},
        {id:2,text:'VIP详情'},
        {id:3,text:'资金明细'},
        {id:4,text:'注单查询'},
        {id:5,text:'个人报表'},
      ],
      content_height:0,
      isShowBindMobile:0,
      browserBrand:'',
    }
  },
  mounted(){
    this.browserBrand = Tool.getMobileBrand();
    this.getContentHeight();
    window.addEventListener('resize',this.getContentHeight)
    window.addEventListener('orientationchange',this.getContentHeight)
    this.bgLayout();
  },
  beforeRouteUpdate(to,from){
    if(to.name != 'userInfo'){
      UserInfo.getUserInfoData(data => {
        if(data && data.is_tourist){
          this.showBindMobile();
          this.$router.push({name:'userInfo'});
        }
      })
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    bgLayout(){
      let idMapBg = {
        0:{
          bg:require('../assets/geren/gr-info.png'),
          bgFocus:require('../assets/geren/gr-info1.png')
        },
        1:{
          bg:require('../assets/geren/gr-level.png'),
          bgFocus:require('../assets/geren/gr-level1.png')
        },
        2:{
          bg:require('../assets/geren/gr-vip.png'),
          bgFocus:require('../assets/geren/gr-vip1.png')
        },
        3:{
          bg:require('../assets/geren/gr-zijin.png'),
          bgFocus:require('../assets/geren/gr-zijin1.png')
        },
        4:{
          bg:require('../assets/geren/gr-betq.png'),
          bgFocus:require('../assets/geren/gr-betq1.png')
        },
        5:{
          bg:require('../assets/geren/gr-report.png'),
          bgFocus:require('../assets/geren/gr-report1.png')
        }
      };
      this.menuItems.forEach(menu => {
        Object.assign(menu,idMapBg[menu.id])
      });
    },
    showBindMobile(){
      this.isShowBindMobile = Math.random();
      this.popMsg({
        msg:'请先绑定手机',
        time:1200
      });
    },
    getRoutePath(id){
      switch(id){
        case 0:
          return {name:'userInfo'}
        case 1:
          return {name:'userLevel'}
        case 2:
          return {name:'vipDetails'}
        case 3:
          return {name:'zijinDetails'}
        case 4:
          return {name:'zdanQuery'}
        case 5:
          return {name:'report'}
      }
      return {name:'userInfo'}
    },
    getContentHeight(){
      let m = innerHeight > innerWidth ? innerWidth : innerHeight
      let topPx = Tool.remToPx(.2)
      this.content_height = m - topPx;
    },
  },
  components:{
    Regist
  }
}
</script>
<style scoped lang="stylus">
.geren-zhongxin
  width 100%
  height 100%
  background-image url(../assets/geren/geren-back.png)
  background-repeat no-repeat
  background-size 100% 100%
  flex-direction column
  position relative
  .top
    width 100%
    height .55rem
    background-image url('../assets/geren/top-back.png')
    background-repeat no-repeat
    background-size 100% 100%
    align-items flex-end
    flex-shrink 0
    .go-back
      width 1.5rem
      height 100%
      background-image url('../assets/geren/go-back.png')
      background-repeat no-repeat
      background-size 100% 100%
    .decorate1
      width 1.38rem
      height 40%
      background-image url('../assets/geren/decorate.png')
      background-repeat no-repeat
      background-size 100% 100%
      margin-bottom .12rem
  .main-content
    width 100%
    height auto
    .menu-wrap
      width 1.45rem
      position relative
      z-index 1
      overflow auto
      &::-webkit-scrollbar
        width 0
      .inner-back
        width 1.45rem
        min-height 100%
        height auto
        // background linear-gradient(#EACD9B, #D8A96B)
        box-shadow 0 0 10px 0 rgba(0,0,0,.6)
        padding-bottom .6rem
      .menu-wrap-scroll
        width 100%
        height auto
        padding-top .12rem
        .menu-btn
          width 1.45rem
          height .44rem
          display block
          margin 0 0 .06rem 0
          position relative
          &:active
            appearance none
            -moz-appearance none
            -webkit-appearance none
          .select-bg
            display none
          &.router-link-exact-active
            .normal-bg
              display none
            .select-bg
              display block
          .normal-bg
            width 1.45rem
            height 100%
            background-repeat no-repeat
            background-size 100% 100%
          .select-bg
            width 100%
            height 100%
            background-repeat no-repeat
            background-size 100% 100%
    .m-w-page
      width 6.15rem
      height 100%
      margin-left -.05rem
      overflow auto
      &.mb2345browser,&.vivo
        width 5.8rem
</style>