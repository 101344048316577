<template>
  <PopWidget :isShow="isShowPopWidget" :isFull="1">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/yuebao/yeb-rule-des.png" />
    </template>
    <template v-slot:content>
      <div class="yuebao-wrapper">
        <img class="content-img" 
          src="../../assets/yuebao/yuebao-descrip.png" />
      </div>
    </template>
  </PopWidget>
</template>

<script>
import PopWidget from '../common/PopWidget.vue'
export default{
  props:{
    show:Number,
  },
  data(){
    return{
      isShowPopWidget:0,
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget
  }
}
</script>

<style scoped lang="stylus">
.l-t-title
  display block
  margin auto
.yuebao-wrapper
  width 100%
  height 2.18rem
  background-image url(../../assets/yuebao/guize-bg.png)
  padding .2rem 0
  overflow auto
  .content-img
    width 4.38rem
</style>