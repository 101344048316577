<template>
  <div class="bet-order-query">
    <img class="decorate" src="../../assets/geren/ctitle-bet.png" />
    <div class="flex game-item-bar">
      <div class="g-m-i-wrap" v-for="(menu,i) of gameMenues"
        @click="menuChange(i)" :data-id="menu.id">
        <div class="game-menu-item" 
          :class="{selected:mmIndex == i,
            'l-select':mmIndex == i - 1,
            'r-select':mmIndex == i + 1}">
          <div class="game-item-innner">
            {{ menu.title }}
          </div>
          <img class="dir" src="../../assets/geren/zd-query-focus-d.png" />
        </div>
      </div>
    </div>
    <div class="condition-wraper flex">
      <div class="flex">
        <div class="label">时间：</div>
        <Selector defaultTxt="选择时间" style="width:.72rem"
          :dataList="dateList" @change="dateSelecChange"
          :defaultIndex="0" menuHeight=".5rem" />
        <div class="label">平台：</div>
        <Selector defaultTxt="选择平台" :resetIndex="resetPlaSelector"
          style="width:.8rem" :dataList="platformList" :defaultIndex="0"
          @change="platSelectChange" />
        <div class="label">游戏：</div>
        <Selector defaultTxt="选择游戏" :defaultIndex="0" 
          :resetIndex="resetGameSelector" @change="gameSelectChange"
          style="width:1.4rem" :dataList="gameList" />
      </div>
      <img @click="queryClick" class="query-btn ease-btn-effe"
        src="../../assets/geren/query-btn.png" />
    </div>
    <div class="table-zjmx">
      <div class="t-row h flex">
        <div class="t-ceil">开始时间</div>
        <div class="t-ceil">结束时间</div>
        <div class="t-ceil">局号</div>
        <div class="t-ceil">游戏类别</div>
        <div class="t-ceil">有效投注</div>
        <div class="t-ceil">输赢金额</div>
      </div>
      <div class="data-t-scroller" :style="{'height':`${tableDivHeight}px`}">
        <div class="t-row b flex" v-for="item of betOrderList">
          <div class="t-ceil">
            {{ item.bet_time }}
          </div>
          <div class="t-ceil">
            {{ item.bet_end_time }}
          </div>
          <div class="t-ceil">
            {{ item.game_id }}
          </div>
          <div class="t-ceil">
            {{item.game_name}}
          </div>
          <div class="t-ceil">
            {{ item.bet_amount.toFixed(2) }}
          </div>
          <div class="t-ceil">
            {{ item.win_amount.toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <div class="foot-bar flex">
      <div>合计：</div>
      <div>
        有效投注&nbsp;&nbsp;
        <span style="color:#28ff28;">{{betStatistic.bet_amount}}</span>
        &nbsp;&nbsp;
      </div>
      <div>
        输赢金额&nbsp;&nbsp;
        <span style="color:red;">{{betStatistic.win_amount}}</span>
        &nbsp;&nbsp;
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import Selector from '../../components/common/Selector.vue'
export default {
  data(){
    return {
      tableDivHeight:0,
      gameMenues:[],
      mmIndex:0,
      selectPlatIndex:0,
      gameSelectIndex:0,
      dateIndex:0,
      dateList:[
        {id:'today',name:'今天'},
        {id:'yesterday',name:'昨天'},
      ],
      platformList:[],
      gameList:[],
      userBetFilter:{},
      resetPlaSelector:0,
      resetGameSelector:0,
      dataPage:1,

      betOrderList:[],
      betStatistic:{},
      betOrderTotal:0,
      betOrderPerpage:0

    }
  },
  mounted(){
    this.getBetFilter(() => {
      this.dataHandle();
      this.platformSelectorDataShow();
      this.gameSelectorDataShow();
    });
    this.queryData();
    this.getTableHeight();
    window.addEventListener('resize',() => {
      this.getTableHeight();
    })
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading']),
    getTableHeight(){
      let m = innerHeight > innerWidth ? innerWidth : innerHeight
      let topPx = Tool.remToPx(1.96)
      this.tableDivHeight = m - topPx
    },
    queryClick(){
      this.queryData();
    },
    dateSelecChange(e){
      this.dateIndex = e;
    },
    platSelectChange(e){
      this.selectPlatIndex = e;
      this.resetGameSelector = Math.random();
      this.gameSelectorDataShow();
    },
    gameSelectChange(e){
      this.gameSelectIndex = e;
    },
    getBetFilter(callback){
      this.userBetFilter = Tool.getUserBetFilter();
      if(this.userBetFilter){
        callback();
        return;
      }
      Request.get('/user/bet_filter').then(res => {
        Tool.setUserBetFilter(res.data.data)
        this.userBetFilter = res.data.data;
        callback();
      })
    },
    menuChange(index){
      this.mmIndex = index;
      this.resetPlaSelector = Math.random();
      this.resetGameSelector = Math.random();
      this.selectPlatIndex = 0;
      this.gameSelectIndex = 0;
      this.platformSelectorDataShow();
      this.gameSelectorDataShow();
    },
    //主菜单
    dataHandle(){
      this.gameMenues = [
        {title:'棋牌游戏',id:3,},
        {title:'捕鱼游戏',id:5},
        {title:'电子游艺',id:1},
        {title:'真人视讯',id:0},
        {title:'彩票游戏',id:2},
        {title:'体育赛事',id:4},
      ];
    },
    //平台选择器
    platformSelectorDataShow(){
      let selected = this.gameMenues[this.mmIndex];
      let gameTypeIndex = selected.id;
      //平台
      this.platformList = [
        {id:'',name:'全部平台'},
      ]
      let platDic = this.userBetFilter.plat_ids[gameTypeIndex];
      Object.keys(platDic).map(key => {
        this.platformList.push({
          id:key,
          name:platDic[key]
        })
      });
    },
    //游戏选择器
    gameSelectorDataShow(){
      let selected = this.gameMenues[this.mmIndex];
      let gameTypeIndex = selected.id;
      let gameIdName = this.userBetFilter.game_ids[gameTypeIndex];

      let platform = this.platformList[this.selectPlatIndex];
      this.gameList = [
        {id:'',name:'全部游戏'},
      ];
      if(platform){
        let gameDictList = gameIdName[platform.id];
        if(gameDictList){
          Object.keys(gameDictList).forEach(id => {
            this.gameList.push({
              id,
              name:gameDictList[id]
            })
          })
        }
      }
      
    },
    queryData(){
      let date = '';
      if(this.dateList[this.dateIndex]){
        date = this.dateList[this.dateIndex].id;
      }
      let gameMenu = this.gameMenues[this.mmIndex];
      
      let plat_id = '';
      if(this.platformList[this.selectPlatIndex]){
        plat_id = this.platformList[this.selectPlatIndex].id;
      }
      let game_id = '';
      if(this.gameList[this.gameSelectIndex]){
        game_id = this.gameList[this.gameSelectIndex].id;
      }

      let param = {
        page:this.dataPage,
        local_type:gameMenu ? gameMenu.id : '',
        date:date,
        plat_id:plat_id,
        game_id
      };
      this.showLoading()
      Request.post('/user/bet_orders',param).then(res => {
        this.closeLoading();
        if(!res.data || !res.data.data) return;

        this.betOrderList = res.data.data.data;
        //假数据
        (()=>{
          let data = {
            bet_time:'2023-05-08 14:56:22',
            bet_end_time:'2023-05-08 14:56:22',
            game_id:12,
            game_name:'测试数据',
            bet_amount:32.123,
            win_amount:23.213,
          }
          let i = 0
          while(i < 30){
            this.betOrderList.push(JSON.parse(JSON.stringify(data)));
            i++;
          }
        })
        this.betStatistic = res.data.data.statistic;
        this.betOrderTotal = res.data.data.total;
        this.betOrderPerpage = res.data.data.per_page;
      }).catch(e => {
        this.closeLoading()
      })
    },
  },
  components:{
    Selector
  }
}
</script>
<style scoped lang="stylus">
.bet-order-query
  width 100%
  .decorate
    width 70%
    margin-left .5rem
    display block
  .game-item-bar
    width 5.7rem
    height .24rem
    margin .04rem auto 0 auto
    background-image url(../../assets/geren/bet-top-bg.png)
    background-size 100% 100%
    border-radius .06rem
  .g-m-i-wrap
    &:first-child 
      .game-item-innner
        border-left none
      .game-menu-item
        border-radius .06rem 0 0 .06rem
    &:last-child 
      .game-item-innner
        border-right none
      .game-menu-item
        border-radius 0 .06rem .06rem 0
  .game-menu-item
    width .95rem
    height .24rem
    position relative
    font-size .12rem
    display flex
    justify-content center
    align-items center
    color #D5BFB2
    .dir
      width .12rem
      position absolute
      display none
      bottom -.05rem
      left 50%
      transform translateX(-50%)
    &.selected
      color #674800
      background-image url(../../assets/geren/zd-query-focus.png)
      .dir
        display block
      .game-item-innner
        border none
    &.l-select
      .game-item-innner
        border-left none
    &.r-select
      .game-item-innner
        border-right none
    .game-item-innner
      width 100%
      height 90%
      line-height .22rem
      text-align center
      border-right 1px solid #3A251A
      border-left 1px solid #6D594D
.condition-wraper
  width 92%;
  margin .07rem auto .06rem auto
  font-size .12rem
  justify-content space-between
  & /deep/ .p-selector
    display flex
    align-items center
    line-height 1
  .query-btn
    width .6rem
    display block
  .label
    font-weight bold
    margin-left .12rem
    display flex
    align-items center
    &:first-child
      margin-left 0
.table-zjmx
  width 95%
  overflow hidden
  border .01rem solid #5F5357
  border-radius .1rem .1rem 0 0
  margin 0 auto
  position relative
  .data-t-scroller
    width 100%
    min-height 1rem
    overflow auto
  .t-row
    width 100%
    font-size .12rem
    &.h
      font-size .12rem
      color #E7D1AC
      height .2rem
      background-image url(../../assets/geren/bet-query-t-bg.png)
      background-size 100% 100%
    &.b
      &:nth-child(odd)
        background-color #232222
      &:nth-child(even)
        background-color #363535
      .t-ceil
        height .25rem
        &:nth-child(6)
          padding-left .04rem
    .t-ceil
      width .92rem
      height .2rem
      text-align center
      display flex
      justify-content center
      align-items center
      overflow hidden
      border-right .01rem solid #5f5357
      &:last-child
        border-right none
      &:nth-child(1),&:nth-child(2)
        width 1.3rem
      &:nth-child(3)
        width .93rem
        padding-left .02rem
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
        display block
        line-height .25rem
      &:nth-child(4),&:nth-child(5),&:nth-child(6)
        width .7rem
.foot-bar
  width 95%
  height .2rem
  line-height .2rem
  font-size .12rem
  padding-left .2rem
  border-radius 0 0 .1rem .1rem
  margin auto
  background-image url(../../assets/geren/bet-query-b-bg.png)
  background-size 100% 100%
</style>