<template>
  <div class="wrapper" ref="wrapper" :style="{width:styleW,height:styleH}">
    <slot name="itemWrapper">
    </slot>
  </div>
</template>

<script>
export default{
  props:{
    startRun:Number,
    styleW:String,
    styleH:String,
  },
  data(){
    return{
      mqWay:0,
      preMarqScrollTop:0,
      stopMarquee:0,
    }
  },
  methods:{
    run(){
      let ele = this.$refs.wrapper;
      let hz = 0;
      let run = () => {
        if(hz % 3 == 0){
          if(ele.scrollTop == 0){
            this.mqWay = 0
          }
          if(this.mqWay == 0){
            ele.scrollTop+=1;
          }else if(this.mqWay == 1){
            ele.scrollTop-=1;
          }
          if(this.preMarqScrollTop == ele.scrollTop){
            this.mqWay = 1
          }
          this.preMarqScrollTop = ele.scrollTop;
        }
        hz++;
        if(!this.stopMarquee){
          requestAnimationFrame(run)
        }
      };
      if(ele.scrollHeight > ele.clientHeight){
        run();
      }
    },
  },
  watch:{
    startRun(n){
      if(n){
        this.stopMarquee = 0;
        this.run();
      }else{
        this.stopMarquee = 1;
      }
    }
  },
}
</script>
<style scoped lang="stylus">
.wrapper
  width auto
  overflow auto
</style>