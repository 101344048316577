<template>
  <div class="dzyy-container">
    <div class="top flex">
      <div class="nav flex">
        <div class="go-back" @click="$router.push({name:'main'})"></div>
        <div class="decorate1">        
        </div>
      </div>
      <div class="flex">
        <AmountFunds style="margin-top:.03rem" :amount="userInfo.coin"
          @touristShow="showBindMobile" :isRefresAmount="isPlayGameBacked"/>
        <form class="search-wrap flex" autocomplete="off">
          <input autocomplete="false" name="hidden" 
            type="text" style="display:none;">
          <input placeholder="请输入游戏名"
            v-model="searchTxt" class="search" type="text" />
          <div class="search-btn" @click="searchBtnClick"></div>
        </form>
      </div>
    </div>

    <div class="main-content">
      <div class="flex remove-wrap">        
        <img class="f-rm-btn" v-show="!isEditing" @click="isEditing=1"
          src="../assets/home/favorite-ed.png" />
        <img class="f-rm-btn" v-show="isEditing" @click="removeFavorite"
          src="../assets/home/favorite-remove.png" />
      </div>
      <HomeGameList invoke="favorite" :isEditing="isEditing" 
        @favSelectChange="favSelectChange" :isSearch="isSearch"
        :removeLocalFavorite="removeLocalFavorite"
        :platformCode="platformCode" />
    </div>

    <Regist :invokSource="1" :isShow="isShowBindMobile" />

  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Tool from '../common/Tool.js'
import Request from '../common/Request.js'
import HomeGameMenu from '../components/HomeGameMenu.vue'
import HomeGameList from '../components/HomeGameList.vue'
import AmountFunds from '../components/gameList/AmountFunds.vue'
import Regist from '../components/Regist.vue'

export default {
  data(){
    return {
      platformCode:'',
      queryId:'',
      userInfo:{},
      searchTxt:'',
      isSearch:'',
      isEditing:0,
      removeLocalFavorite:0,
      isShowBindMobile:0,
      favRemoveGameIdList:[],
      isPlayGameBacked:0,
    }
  },
  mounted(){
    this.userInfo = Tool.getLocalUserinfo();
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    showBindMobile(){
      this.isShowBindMobile = Math.random();
      this.popMsg({
        msg:'请先绑定手机',
        time:1200
      });
    },
    removeFavorite(){
      if(this.favRemoveGameIdList.length){
        const idStr = this.favRemoveGameIdList.join(','),
          p = {game_id:idStr};
        this.showLoading()
        Request.post('/index/collection_del',p).then(res => {
          this.closeLoading();
          if(res.data.code == 0){
            this.favRemoveGameIdList = [];
            this.removeLocalFavorite = Math.random();
            this.popMsg('移除成功!')
          }
          this.isEditing = 0;
        });
      }else{
        this.isEditing = 0
      }
    },
    favSelectChange(gameIdList){
      this.favRemoveGameIdList = gameIdList;
    },
    gameMenuChange(code){
      this.platformCode = code;
    },
    searchBtnClick(){
      this.isSearch = `${this.searchTxt}-${Math.random()}`
    }
  },
  beforeRouteEnter(to,from,next){
    next(vm => {
      vm.queryId = to.query.id;
      if(from.name == 'play'){
        vm.isPlayGameBacked = Math.random();
      }
    });
  },
  components:{
    HomeGameMenu,
    HomeGameList,
    AmountFunds,
    Regist
  }
}
</script>
<style scoped lang="stylus">
.dzyy-container
  width 100%
  height 100%
  flex-direction column
  position relative
  .top
    width 100%
    height .55rem
    background-image url(../assets/home/top/home-t-b-bg.png)
    background-size 100% 100%
    .nav
      width auto
      height .55rem
      margin-right .36rem
      align-items flex-end
    .go-back
      width 1.21rem
      height 100%
      background-image url('../assets/geren/go-back.png')
      background-repeat no-repeat
      background-size 100% 100%
    .decorate1
      width 1.38rem
      height 40%
      background-image url('../assets/home/favorite-title.png')
      background-repeat no-repeat
      background-size 100% 100%
      margin-bottom .12rem
  .search-wrap
    height 100%
    padding-top .12rem
    margin 0 .1rem
  input
    &.search
      width 1.5rem
      height .3rem
      padding-left .11rem
      background none
      border none
      background-image url(../assets/dzyy/search-border.png)
      background-size 100% 100%
  .search-btn
    width .36rem
    height .3rem
    background-image url(../assets/dzyy/search-btn-ico.png)
    background-size 100% 100%
  .main-content
    .remove-wrap
      width 100%
      justify-content flex-end
      padding .1rem .4rem 0 0
      .f-rm-btn
        width .6rem
        height .32rem
</style>