<template>
  <PopWidget :isFull="1" :isShow="isShowPopWidget"
    style="z-index:4">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/duihuan/duihuan-record-title.png" />
    </template>
    <template v-slot:content>
      <div class="main-content">
        <div class="d-row head flex">
          <div>申请时间</div>
          <div>兑换金额</div>
          <div>实到金额</div>
          <div>状态</div>
        </div>
        <div class="d-row-wrapper" @scroll="wrapperScroll">
          <div class="d-row flex" v-for="recoord of recordList">
            <div>{{recoord.created_at}}</div>
            <div>{{recoord.amount}}</div>
            <div>{{recoord.real_amount}}</div>
            <div>{{recoord.agree_name}}</div>
          </div>
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import {mapMutations} from 'vuex'

export default{
  props:{
    show:Number,
  },
  data(){
    return{
      pagerInfo:{},
      recordList:[],
      nextPageGetting:0,
      isShowPopWidget:0,
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading','popMsg']),
    wrapperScroll(e){
      let sTop = e.target.scrollTop;
      let scrollSpace = sTop + e.target.clientHeight
      let sub = e.target.scrollHeight - scrollSpace;
      if(sub < 5){
        this.getNextPageData();
      }
    },
    getNextPageData(){
      if(!this.pagerInfo)return;
      const {per_page,total,current_page} = this.pagerInfo;
      const totalPage = Math.ceil(total/per_page);
      const cPage = current_page * 1;
      if(cPage >= totalPage) return;
      if(this.nextPageGetting == 0){
        this.nextPageGetting = 1;
        this.getRecordList(cPage + 1);
      }
    },
    getRecordList(pPage){
      const page = pPage ? pPage : 1;
      this.showLoading();
      Request.get(`/user/withdraw_record?page=${page}`).then(res => {
        this.nextPageGetting = 0
        this.closeLoading();
        if(res.data && res.data.data && res.data.data.list){
          this.pagerInfo = res.data.data.list;
          if(this.pagerInfo.data && this.pagerInfo.data.length){
            this.pagerInfo.data.forEach(item => this.recordList.push(item));
          }
        }
      })
    }
  },
  watch:{
    show(n){
      if(n){
        this.isShowPopWidget = n;
        this.recordList = [];
        this.getRecordList();
      }
    }
  },
  components:{
    PopWidget,
  }
}
</script>

<style scoped lang="stylus">
.main-content
  width 100%
  padding-bottom .1rem
  .d-row-wrapper
    width 100%
    height 1.88rem
    font-size .12rem
    overflow auto
    .d-row
      border-bottom 2px dotted #BEAE94
      & > div
        &:nth-child(4)
          color red
  .d-row
    width 100%
    & > div
      width 24%
      height .34rem
      line-height .34rem
      &:first-child
        width 30%
    &.head
      background-color #434343

</style>