<template>
  <div class="duobao-w-back" v-show="showBack">
    <div class="duobao-wrapper" :class="{show:showFore}">
      <div class="menu">
        <div class="m-i-item" v-for="menu of menuList"
          @click="menuItemClick(menu.id)">
          <div class="pic-wrap" 
            :class="`p-${menu.id} ${focusId==menu.id?'focus':''}`">
          </div>
          <div class="m-item-bet" :class="`p-${menu.id}`">
            {{ menu.bet?menu.bet+'积分':'' }}
          </div>
        </div>
      </div>
      <img class="back" src="../../assets/duobao/xinyun-table-bj.png" />
      <img @click="closeClick" class="close-btn"
        src="../../assets/duobao/close-btn.png" />
      <div class="db-title-wrap" :class="`t${focusId}`">
      </div>
      <div class="d-b-c-wrapper flex">
        <div class="turntable-border">
          <div class="turn-contaienr" @transitionend="turnEnded" ref="turnContainer"
            :style="{transform:`translateX(-50%) rotateZ(${turnDeg}deg)`,
              transition:`transform ${turnTime}s cubic-bezier(0,.6,.66,.73)`}">
            <canvas :width="canvasWidth" :height="canvasWidth"
              ref="circleCanvas"></canvas>
            <div class="item-container">
              <div class="prize-item flex" v-for="(pItem,p_i) of prizeList"
                :style="{transform:`translateX(-50%) rotateZ(${getTurnItem(p_i)}deg)`}"
                :data-id="pItem.id">
                <img class="ico-coin" src="../../assets/duobao/prize-item-de1.png" />
                <div class="prize-name">
                  {{ pItem.prize_name }}
                </div>
                <img class="de-coin" src="../../assets/duobao/prize-item-de.png" />
              </div>
            </div>
          </div>
          <div class="turn-contaienr">
            <img class="duobao-focus" :class="{show:duobaoFocusShow}"
              src="../../assets/duobao/duobao-focus.png" />
            <div class="get-bonus-wrap" :class="{getting:isGettingBonus}"
              @click="getBonusClick">
            </div>
          </div>
        </div>
        <div class="bet-total flex">
          <div class="label curr">
            当前积分:
          </div>
          <div class="today-bet-total curr">
            {{userInfo.score?userInfo.score:0}}
          </div>
          <img class="score-help ease-btn-effe" 
            @click="tourntableHelp"
            src="../../assets/duobao/help-btn.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'

export default {
  props:{
    show:Number,
  },
  data(){
    return {
      showBack:0,
      showFore:0,
      menuList:[],
      focusId:-1,
      prizeList:[],
      turnDeg:0,
      turnTime:6,
      duobaoFocusShow:false,
      actLucky:{},
      turnTableIndex:-1,
      userInfo:{},
      isGettingBonus:false,
      canvasWidth:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    tourntableHelp(){
      if(this.isGettingBonus)return;
      this.$emit('tourntableHelp')
    },
    turnEnded(){
      this.updateUserinfo();
      this.isGettingBonus = false;
      this.duobaoFocusShow = true;
      this.popMsg(`恭喜中奖${this.actLucky.money?this.actLucky.money:0}元`);
    },
    updateUserinfo(){
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          Tool.setLocalUserinfo(res.data.data);
          this.userInfo = res.data.data;
        }
      });
    },
    reset(){
      this.turnTime = 0
      this.turnDeg = 0
      this.turnTableIndex = -1
      this.duobaoFocusShow = false
    },
    getBonusClick(){
      const _this = this;
      if(!_this.userInfo || !_this.userInfo.mobile){
        _this.popMsg({
          msg:'请先绑定手机',
          time:1200
        })
        _this.$emit('showBindMobile')
        return;
      }
      if(_this.isGettingBonus) return;
      let turnToTarget = (id) => {
        _this.duobaoFocusShow = false;
        _this.turnTime = 6;
        const baseAn = 360 * 4;
        const singleAn = 360 / _this.prizeList.length;
        let preAn = 0;
        if(_this.turnTableIndex > -1){
          preAn = singleAn * _this.turnTableIndex;
        }
        _this.prizeList.forEach((item,i) => {
          if(item.id == id){
            _this.turnTableIndex = i;
          }
        });
        if(_this.turnTableIndex > -1){
          _this.turnDeg += baseAn + (360 - _this.turnTableIndex * singleAn) + preAn;
        }
      };
      _this.isGettingBonus = true;
      _this.updateUserinfo();
      Request.post(`/activity/lucky`,{id:this.focusId}).then(res => {
        if(res.data.code == 0){
          this.actLucky = res.data.data;
          turnToTarget(this.actLucky.id);
        }else{
          _this.isGettingBonus = false;
          _this.popMsg(res.data.msg)
        }
      }).catch(error => {
        _this.isGettingBonus = false;
        if(error.response && error.response.data && error.response.data.msg){
          _this.popMsg(error.response.data.msg);
        }
      })
    },
    getTurnItem(p_i){
      const total = this.prizeList.length,
        angleItem = 360 / total;
      return p_i * angleItem;
    },
    initCanvas(){
      if(this.$refs.turnContainer){
        this.canvasWidth = this.$refs.turnContainer.clientWidth;
      }
      setTimeout(() => {
        this.draw();
      })
    },
    draw(){
      let total = this.prizeList.length;
      const canvas = this.$refs.circleCanvas;
      const ctx = canvas.getContext('2d');
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const radius = centerX;
      const color1 = '#8A5D39',color2 = '#7B552C';
      const colors = [];
      this.prizeList.forEach((n,i) => {
        if((i+1)%2 == 0){
          colors.push(color1)
        }else{
          colors.push(color2)
        }
      })
      function drawSector(startAngle, endAngle, color) {
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
      }
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      for (let i = 0; i < total; i++) {
        const angleItem = 360 / total;
        const half = angleItem * Math.PI / 180 / 2
        let startAngle = (i * angleItem * Math.PI) / 180;
        let endAngle = ((i + 1) * angleItem * Math.PI) / 180;
        startAngle -= half;
        endAngle -= half;
        drawSector(startAngle, endAngle, colors[i]);
      }

      ctx.beginPath()
      let radius1 = canvas.width * 0.2722772277227723;
      ctx.strokeStyle = '#6F462A';
      ctx.lineWidth = canvas.width * 0.1188118811881188;
      ctx.arc(centerX,centerY,radius1,0,2*Math.PI);
		  ctx.stroke()

    },
    getPrizeList(){
      let id = this.focusId;
      let localData = Tool.getActPrizeList(id);
      if(localData){
        this.prizeList = localData;
        setTimeout(() => {
          this.initCanvas();
        })
        return;
      }
      Request.post('/activity/prizelist',{id}).then(res => {
        this.prizeList = res.data.data;
        Tool.setActPrizeList(id,res.data.data);
        this.initCanvas();
      })
    },
    dataHandle(list){
      this.menuList.forEach(menu => {
        list.forEach(resMenu => {
          if(menu.id == resMenu.id){
            menu.bet = resMenu.bet;
          }
        });
      });
    },
    getData(callback){
      let luckyList = Tool.getLuckyList()
      if(luckyList){
        setTimeout(() => {
          callback(luckyList)
        })
        return;
      }
      Request.get('/activity/luckyList').then(res => {
        Tool.setLuckyList(res.data.data);
        callback(res.data.data);
      })
    },
    menuItemClick(id){
      if(this.isGettingBonus)return;

      this.focusId = id;
      this.reset();
      this.getPrizeList();
    },
    menuDataInit(){
      this.menuList = [
        {
          id:36,
          bet:'',
        },
        {
          id:40,
          bet:'',
        },
        {
          id:41,
          bet:'',
        }
      ];
    },
    closeClick(){
      if(this.isGettingBonus)return;
      this.showFore = 0;
      this.showBack = 0;
      this.$emit('turnClose')
    }
  },
  watch:{
    show(n){
      this.reset();
      this.focusId = n
      if(n){
        this.showBack = n;
        this.showFore = n;
        this.getData(this.dataHandle)
        this.menuDataInit()
        this.getPrizeList();
        this.userInfo = Tool.getLocalUserinfo()
      }else{
        this.showFore = 0;
        this.showBack = 0;
      }
    }
  }
}
</script>
<style scoped lang="stylus">
.duobao-w-back
  width 100%
  height 100%
  position absolute
  background-color rgba(0,0,0,.4)
  top 0
  left 0
  z-index 3
.duobao-wrapper
  position absolute
  z-index 2
  top 50%
  left 50%
  transform translateX(-50%) translateY(-52%) scale(.001)
  &.show
    transform translateX(-50%) translateY(-52%) scale(1)
  .d-b-c-wrapper
    width 79%
    height 2.58rem
    position absolute
    top .19rem
    left .56rem
    .bet-total
      width 1.79rem
      height .27rem
      align-items center
      background-color #521775
      position absolute
      bottom .23rem
      right -.16rem
      z-index 1
      .label
        font-size .12rem
        color #E4AEED
        &.curr
          margin-left .61rem
      .today-bet-total
        margin-left .05em
        color #039506
        &.curr
          width .52rem
      .score-help
        width .34rem
        display block
        position absolute
        right -.17rem
        top -.05rem
    .turntable-border
      width 2.45rem
      height 2.57rem
      z-index 2
      position absolute
      top 0
      left 50%
      transform translateX(-50%)
      background-image url(../../assets/duobao/turntable-border.png)
      background-size 100% 100%
      .turn-contaienr
        width 2.02rem
        height 2.02rem
        position absolute
        bottom .21rem
        left 50%
        transform translateX(-50%)
        z-index 2
        .duobao-focus
          display none
          position absolute
          width .72rem
          left 50%
          top 0
          transform translateX(-50%)
          &.show
            display block
      canvas
        top 0
        left 0
        position absolute
      .item-container
        width 2.02rem
        height 2.02rem
        top 0
        left 0
        position absolute
        .prize-item
          transform-origin 50% 100%
          height 1rem
          color #F4EADD
          position absolute
          top 0
          left 50%
          flex-direction column
          justify-content flex-start
          align-items center
          padding-top .08rem
          &:nth-child(odd)
            .ico-coin
              width .27rem
              height .22rem
          &:nth-child(even)
            .de-coin
              width .1rem
              margin-top .03rem
          .ico-coin
            width .36rem
          .prize-name
            text-align center
            transform scale(.8)
            font-size .12rem
            margin .06rem 0 .06rem 0
          .de-coin
            width .08rem
            margin-top .04rem
      .get-bonus-wrap
        position absolute
        width .47rem
        height .47rem
        background-image url(../../assets/duobao/get-bonus-ico.png)
        background-size 100% 100%
        left 50%
        top 50%
        transform translateX(-50%) translateY(-50%)
        &.getting
          background-image url(../../assets/duobao/get-bonus-ico1.png)
  .back
    width 5.34rem
  .close-btn
    width .34rem
    position absolute
    right .14rem
    top .14rem
  .db-title-wrap
    width 3.34rem
    height .7rem
    position absolute
    top -.23rem
    left 50%
    transform translateX(-50%)
    background-size 100% 100%
    background-repeat no-repeat
    &.t36
      background-image url(../../assets/duobao/duobao-t1.png)
    &.t40
      background-image url(../../assets/duobao/duobao-t2.png)
    &.t41
      background-image url(../../assets/duobao/duobao-t3.png)
  .menu
    width .66rem
    height auto
    position absolute
    left -.17rem
    top .9rem
    img
      display block
      width 100%
    .m-i-item
      width 100%
      height .42rem
      margin-bottom .08rem
      height auto
      position relative
      .pic-wrap
        background-size 100% 100%
        background-repeat no-repeat
        &.p-36
          width .66rem
          height .45rem
          background-image url(../../assets/duobao/duobao-m-btn1.png)
          &.focus
            background-image url(../../assets/duobao/duobao-m-btn11.png)
        &.p-40
          width .66rem
          height .42rem
          background-image url(../../assets/duobao/duobao-m-btn2.png)
          &.focus
            background-image url(../../assets/duobao/duobao-m-btn21.png)
        &.p-41
          width .66rem
          height .45rem
          background-image url(../../assets/duobao/duobao-m-btn3.png)
          &.focus
            background-image url(../../assets/duobao/duobao-m-btn31.png)
      .m-item-bet
        font-size .12rem
        text-align center
        color #fff
        left 0
        padding-left .07rem
        bottom .02rem
        width 100%
        position absolute
        &.p-41
          padding-left .02rem
</style>