<template>
  <div class="ljb-container flex" @touchmove.stop="" @touchstart.stop=""
    :class="{show:localShow}">

    <div class="poi-wrapper">
      <img class="ling-jbi-close ease-btn-effe" @click="closeClick"
        src="../../assets/qiandao/qiandao-close.png" />
      <div class="ljb-wrapper" ref="ljbWrapper"></div>
      <div class="share-descrip">
        <div class="amount">
          {{promoInfo.share_benefit}}
        </div>
        <div class="btn-share-w p-relative">
          <input v-model="shareContent" readonly ref="promoUrlInput" />
          <div class="btn-s-w-inner flex">            
            <img class="btn-share ease-btn-effe" @click="shareWechat(0)"
              src="../../assets/home/share-btn-friendly.png" />
            <img class="btn-share ease-btn-effe" @click="shareWechat(1)"
              src="../../assets/home/share-btn-pyq.png" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Tool from '../../common/Tool.js'
import Request from '../../common/Request.js'
import LottieZipShow from '../../common/LottieZipShow.js'
import { mapMutations } from 'vuex'

export default {
  props:{
    show:Number,
  },
  data(){
    return{
      promoInfo:{},
      animationName:'ljb-animation',
      localShow:0,
      shareContent:'',
    }
  },
  methods:{
    ...mapMutations(['popMsg','alert','IsupdateUserCoin']),
    closeClick(){
      this.localShow = 0;
    },
    // t 0 好友 1 朋友圈
    shareWechat(t){
      let input = this.$refs.promoUrlInput;
      Tool.copy(input);
      this.alert('复制成功，如微信未能启动，请手动打开微信。')
      setTimeout(() => {
        Tool.openWechat()
        this.getPromoShare();
      },200)
    },
    getPromoShare(){
      Request.get('/promo/share').then(res => {
        if(res.data.code == 0){
          Tool.removeLocalUserInfo();
          this.IsupdateUserCoin();
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getPromoInfo(callback){
      let localPromo = Tool.getPromLocalInfo();
      if(localPromo){
        this.promoInfo = localPromo;
        if(callback)callback()
        return;
      }
      Request.post('/promo/info').then(res => {
        if(res.data.data){
          this.promoInfo = res.data.data;
          Tool.setPromLocalInfo(res.data.data);
          if(callback)callback()
        }else{
          this.promoInfo = {}
        }
      })
    },
    genAnimation(){
      const ele = this.$refs.ljbWrapper;
      LottieZipShow.showIco(
        '/lottie-web/share-get-money.zip',
        this.animationName,
        ele,1,null);
    },
    destroyAnimate(){
      lottie.destroy(this.animationName)
    }
  },
  watch:{
    show(n){
      if(n){
        this.genAnimation();
        this.getPromoInfo(() => {
          let {promo_share_title,promo_share_text,promo_url} = this.promoInfo;
          if(promo_share_title){
            this.shareContent = 
              `${promo_share_title}${promo_share_text}${promo_url}`;
          }
        });
      }else{
        this.destroyAnimate();
      }
      this.localShow = n;
    }
  }
}
</script>

<style scoped lang="stylus">
.ljb-container
  width 100%
  height 100%
  position absolute
  left 0
  top 0
  z-index 3
  background-color rgba(0,0,0,.25)
  display none
  &.show
    display flex
  .poi-wrapper
    width 5.22rem
    height 2.65rem
    position absolute
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
    .ling-jbi-close
      width .46rem
      position absolute
      right -.14rem
      top .18rem
      z-index 2
  .ljb-wrapper
    width 5.22rem
    height 2.57rem
  .share-descrip
    width 3.4rem
    height 2.02rem
    text-align center
    position absolute
    top .42rem
    left 1.58rem
    font-size .18rem
    color #9A4E2E
    span
      color #DF0008
    .amount
      width 1.2rem
      margin .12rem auto 0 1.8rem;
      font-size .2rem
      color #fffdb5
    .btn-share-w
      width 100%
      height .32rem
      margin-top 1.13rem
      text-align left
      input
        width .05rem
        background none
        margin-left .26rem
        border none
      .btn-s-w-inner
        width 91%
        height .32rem
        justify-content space-between
        padding 0 .2rem
        position absolute
        left .04rem
        top 0
    .btn-share
      width 1rem
      height .32rem
</style>